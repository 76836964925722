import { createAction } from '@reduxjs/toolkit';
import { Currency } from 'app/models/currency.model';
import { MenuState } from 'app/reducers/menu.reducer';
import {
  ComboGroup, DisplayedGroup,
  DisplayedProduct, DisplayedSize, DisplayedSizeScale, GecoPosMenu,
  MenuSection,
  MenuSyncOfPOS, MenuSyncOfSale,
  ModifierGroup,
  OverridePosition
} from 'app/models/menu.model';
export const MENU_SET_CURRENCY_FROM_ENUM = '[MENU] SET_CURRENCY_FROM_ENUM';
export const MENU_SET_GET_MENUS_PENDING = '[MENU] SET_GET_MENUS_PENDING';
export const MENU_SET_FULL_MENU = '[MENU] SET_SET_FULL_MENU';
export const MENU_UPDATE_STATE = '[MENU] MENU_INIT_FULL_MENU';
export const MENU_SET_MENU_OVERRIDE_POSITIONS = '[MENU] SET_MENU_OVERRIDE_POSITIONS';
export const MENU_SET_OVERRIDE_POSITIONS = '[MENU] SET_OVERRIDE_POSITIONS';
export const MENU_SET_SEARCH_QUERY = '[MENU] MENU_SET_SEARCH_QUERY';
export const MENU_SET_MENU_SYNC_OF_POS = '[MENU] MENU_SET_MENU_SYNC_OF_POS';
export const MENU_SET_MENU_SYNC_OF_SALES = '[MENU] MENU_SET_MENU_SYNC_OF_SALES';
export const MENU_SET_MENU_SECTIONS = '[MENU] MENU_SET_MENU_SECTIONS';
export const MENU_SET_DICTIONARY_GROUPS = '[MENU] SET_DICTIONARY_GROUPS';
export const MENU_SET_DICTIONARY_GROUPS_AND_OVERRIDE_POSITIONS = '[MENU] SET_DICTIONARY_GROUPS_AND_OVERRIDE_POSITIONS';
export const MENU_SET_DICTIONARY_PRODUCTS_AND_OVERRIDE_POSITIONS = '[MENU] SET_DICTIONARY_PRODUCTS_AND_OVERRIDE_POSITIONS';
export const MENU_SET_DICTIONARY_SIZES_AND_OVERRIDE_POSITIONS = '[MENU] SET_DICTIONARY_SIZES_AND_OVERRIDE_POSITIONS';
export const MENU_SET_DICTIONARY_MODIFIER_GROUPS_AND_OVERRIDE_POSITIONS = '[MENU] SET_DICTIONARY_MODIFIER_GROUPS_AND_OVERRIDE_POSITIONS';
export const MENU_SET_DICTIONARY_COMBO_GROUPS_AND_OVERRIDE_POSITIONS = '[MENU] SET_DICTIONARY_COMBO_GROUPS_AND_OVERRIDE_POSITIONS';
export const MENU_UPLOADING_STATE_SET_LOADING = '[MENU] UPLOADING_STATE_SET_LOADING';
export const MENU_UPLOADING_STATE_SET_ERROR = '[MENU] UPLOADING_STATE_SET_ERROR';
export const MENU_UPLOADING_STATE_CLEAR_ERRORS = '[MENU] UPLOADING_STATE_CLEAR_ERRORS';
export const MENU_SET_SIZE = '[MENU] SET_SIZE';
export const MENU_SET_SIZE_SCALES = '[MENU] SET SIZE SCALES';

export const setCurrencyFromEnum = createAction<Currency>(MENU_SET_CURRENCY_FROM_ENUM);
export const setMenusPending = createAction<boolean>(MENU_SET_GET_MENUS_PENDING);
export const setFullMenuInfo = createAction<[GecoPosMenu, MenuSection[], OverridePosition[]]>(MENU_SET_FULL_MENU);
export const updateMenuState = createAction<MenuState>(MENU_UPDATE_STATE);
export const setOverridePositions = createAction<OverridePosition[]>(MENU_SET_OVERRIDE_POSITIONS);
export const setMenuOverridePositions = createAction<[OverridePosition[], Map<string, DisplayedGroup>, Map<string, DisplayedProduct>, Map<string, DisplayedSize>, Map<string, ModifierGroup>, Map<string, ComboGroup>]>(MENU_SET_MENU_OVERRIDE_POSITIONS);
export const setSearchQuery = createAction<string>(MENU_SET_SEARCH_QUERY);
export const setMenuSyncOfPOS = createAction<MenuSyncOfPOS>(MENU_SET_MENU_SYNC_OF_POS);
export const setMenuSyncOfSales = createAction<MenuSyncOfSale[]>(MENU_SET_MENU_SYNC_OF_SALES);
export const setMenuSections = createAction<MenuSection[]>(MENU_SET_MENU_SECTIONS);
export const setDictionaryGroups = createAction<Map<string, DisplayedGroup>>(MENU_SET_DICTIONARY_GROUPS);
export const setDictionaryGroupsAndOverridePositions = createAction<[OverridePosition[], Map<string, DisplayedGroup>]>(MENU_SET_DICTIONARY_GROUPS_AND_OVERRIDE_POSITIONS);
export const setDictionaryProductsAndOverridePositions = createAction<[OverridePosition[], Map<string, DisplayedProduct>]>(MENU_SET_DICTIONARY_PRODUCTS_AND_OVERRIDE_POSITIONS);
export const setDictionarySizesAndOverridePositions = createAction<[OverridePosition[], Map<string, DisplayedSize>]>(MENU_SET_DICTIONARY_SIZES_AND_OVERRIDE_POSITIONS);
export const setDictionaryModifierGroupsAndOverridePositions = createAction<[OverridePosition[], Map<string, ModifierGroup>]>(MENU_SET_DICTIONARY_MODIFIER_GROUPS_AND_OVERRIDE_POSITIONS);
export const setDictionaryComboGroupsAndOverridePositions = createAction<[OverridePosition[], Map<string, ComboGroup>]>(MENU_SET_DICTIONARY_COMBO_GROUPS_AND_OVERRIDE_POSITIONS);
export const setLoadingUploadingState = createAction<[string, boolean]>(MENU_UPLOADING_STATE_SET_LOADING);
export const setErrorUploadingState = createAction<[string, string]>(MENU_UPLOADING_STATE_SET_ERROR);
export const clearErrorsUploadingState = createAction(MENU_UPLOADING_STATE_CLEAR_ERRORS);
export const setSize = createAction<DisplayedSize>(MENU_SET_SIZE)
export const setSizeScales = createAction<DisplayedSizeScale[]>(MENU_SET_SIZE_SCALES)
