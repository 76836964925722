import { createSelector } from '@reduxjs/toolkit';
import * as fromContext from 'app/reducers/context.reducer';
import { AuthState, TokenInfo } from 'app/reducers/context.reducer';
import { ADMINISTRATOR, MANAGER, SUPPORT, USER } from "app/config";
import { createFeatureSelector } from "@ngrx/store";

export interface RoleMembership {
  isUser: boolean;
  isSupport: boolean;
  isAdministrator: boolean;
  isManager: boolean;
}

const selectSelf = createFeatureSelector<AuthState>(fromContext.AuthFeatureKey);

export const selectTokenInfo = createSelector(
  selectSelf,
  (state): TokenInfo | null => {
    return state.tokenInfo;
  }
);

export const selectIsAuthenticated = createSelector(
  selectSelf,
  (state): boolean => {
    return !!(state.tokenInfo && state.tokenInfo.tokenExpires > new Date());
  }
);

export const selectLanguage = createSelector(
  selectSelf,
  (state) => {
    return state.language;
  }
);

export const selectLoginData = createSelector(
  selectSelf,
  (state) => {
    return state.loginData;
  }
);

export const selectRole = createSelector(
  selectLoginData,
  (loginData): RoleMembership => {

    const isAdministrator = !!(loginData && loginData.roles.find(r => r === ADMINISTRATOR));
    const isSupport = isAdministrator || !!(loginData && loginData.roles.find(r => r === SUPPORT))
    const isManager = isAdministrator || isSupport || !!(loginData && loginData.roles.find(r => r === MANAGER))
    const isUser = isAdministrator || isSupport || isManager || !!(loginData && loginData.roles.find(r => r === USER))

    return {
      isUser,
      isManager,
      isSupport,
      isAdministrator
    };
  }
)

export const selectIfManager = createSelector(
  selectRole,
  (roles) => {
    return roles.isAdministrator || roles.isManager || roles.isSupport
  }
)

export const selectIfSupport = createSelector(
  selectRole,
  (roles) => {
    return roles.isAdministrator || roles.isSupport
  }
)
