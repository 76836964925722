import { APIService } from 'app/services/api.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DishesService } from 'app/services/dishes.service';
import { GecoPosCreateOrUpdateOrganizationRequest } from 'app/models/gecoPosCreateOrUpdateOrganizationRequest';
import { PosOrganizationModel } from "app/reducers/pos.reducer";

@UntilDestroy()
@Component({
  selector: 'app-geco-pos-organization-modal',
  templateUrl: './geco-pos-organization-modal.component.html',
  styleUrls: ['./geco-pos-organization-modal.component.scss']
})
export class GecoPosOrganizationModalComponent implements OnInit {
  gecoPosOrganizationForm: UntypedFormGroup;
  updateOrganizationRequest: GecoPosCreateOrUpdateOrganizationRequest;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public item: GecoPosOrganizationWrapper | null,
    public api: APIService,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private dishesService: DishesService
  ) {
  }

  ngOnInit(): void {
    const defaultApiKey = 'r6t23TUmo4jByVc65UXrjRZvvz2Qbupu';
    const defaultOrderCreateWebhookUrl = 'https://api-hub.me.ge-co.io/api/external-systems/gecopossales/orders/create';
    const defaultOrderStatusUpdateWebhookUrl = 'https://api-hub.me.ge-co.io/api/external-systems/gecopossales/orders/status/update';
    const stopListUpdateWebhookUrl = 'https://api-hub.me.ge-co.io/api/external-systems/gecopossales/stoplists/update';
    this.updateOrganizationRequest = {
      id: this.item.organization.id,
      name: this.item.organization.name,
      apiKey: defaultApiKey,
      orderCreateWebhookUrl: defaultOrderCreateWebhookUrl,
      orderStatusUpdateWebhookUrl: defaultOrderStatusUpdateWebhookUrl,
      stopListUpdateWebhookUrl: stopListUpdateWebhookUrl,
      importFromPos: false,
    };

    this.gecoPosOrganizationForm = this.formBuilder.group({
      name: new UntypedFormControl(this.item.organization ? this.item.organization.name : '', Validators.required),
      apiKey: new UntypedFormControl(this.item.organization
          ? this.item.organization.apiKey ? this.item.organization.apiKey : defaultApiKey
          : defaultApiKey,
        [Validators.required]),
      orderCreateWebhookUrl: new UntypedFormControl(this.item.organization
        ? this.item.organization.orderCreateWebhookUrl
          ? this.item.organization.orderCreateWebhookUrl
          : defaultOrderCreateWebhookUrl
        : defaultOrderCreateWebhookUrl),
      orderStatusUpdateWebhookUrl: new UntypedFormControl(this.item.organization
        ? this.item.organization.orderStatusUpdateWebhookUrl
          ? this.item.organization.orderStatusUpdateWebhookUrl
          : defaultOrderStatusUpdateWebhookUrl
        : defaultOrderStatusUpdateWebhookUrl),
      stopListUpdateWebhookUrl: new UntypedFormControl(this.item.organization
        ? this.item.organization.stopListUpdateWebhookUrl
          ? this.item.organization.stopListUpdateWebhookUrl
          : stopListUpdateWebhookUrl
        : stopListUpdateWebhookUrl),
      importFromPos: new UntypedFormControl(this.item.organization
        ? this.item.organization.importFromPos
          ? this.item.organization.importFromPos
          : false
        : false),
    });

    this.gecoPosOrganizationForm.valueChanges.subscribe(val => {
      const org = val as GecoPosCreateOrUpdateOrganizationRequest;
      this.updateOrganizationRequest = {
        id: '',
        name: org.name,
        apiKey: org.apiKey,
        orderCreateWebhookUrl: org.orderCreateWebhookUrl,
        orderStatusUpdateWebhookUrl: org.orderStatusUpdateWebhookUrl,
        stopListUpdateWebhookUrl: org.stopListUpdateWebhookUrl,
        importFromPos: org.importFromPos,
      };
    });
  }

  add(): void {
    const createOrganizationRequest: GecoPosCreateOrUpdateOrganizationRequest = {
      ...this.updateOrganizationRequest,
    };
    createOrganizationRequest.id = this.item.organization.id;
    this.dishesService.createOrganization(createOrganizationRequest).subscribe((res) => {
      if (!res.success) {
        this.exit();
      }
    });
    this.exit();
  }

  update(): void {
    const updateOrganizationRequest: GecoPosCreateOrUpdateOrganizationRequest = {
      ...this.updateOrganizationRequest,
    };
    updateOrganizationRequest.id = this.item.organization.id;
    this.dishesService.updateOrganization(updateOrganizationRequest).subscribe((res) => {
      if (res.success) {
        this.exit();
      }
    });

  }

  exit(): void {
    this.dialogRef.close();
  }
}

export interface GecoPosOrganizationWrapper {
  organization: PosOrganizationModel,
  isNew: boolean,
}