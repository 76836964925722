import { APP_INITIALIZER, ErrorHandler, Inject, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'environments/environment';
import { APIService } from './services/api.service';


// http
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from 'app/services/http/token-interceptor.service';
import { RpcErrorInterceptorService } from 'app/services/http/rpc-error-interceptor.service';


// import { PushNotificationsModule } from 'ng-push-ivy'; TODO https://angular.io/api/service-worker/SwPush
import { SignalrService } from './services/signalr.service';
import { AppRoutingModule } from './app-routing.module';
import { RouterHelper } from './services/route-helper.service';

import { NgxsModule } from '@ngxs/store';

import { OrdersState } from './state-management/states/orders.states';
import { FiltersState } from './state-management/states/filters.states';
import { OrganizationsState } from './state-management/states/organizations.states';

// App components
import { AppComponent, ErrorSnackBarComponent } from 'app/app.component';
import { InternalComponent } from './pages/internal/internal.component';
import { ExternalSystemPipe } from './shared/pipes/external-system.pipe';

import { OrderListComponent } from './components/order/order-list/order-list.component';
import { OrderMonitoringComponent } from './components/order/order-monitoring/order-monitoring.component';
import { OrderComponent } from './components/order/order/order.component';
import { OrderFilterComponent } from './components/order/shared/order-filter/order-filter.component';

// Material modules
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import * as Sentry from '@sentry/angular';
// Localisation
import { Router } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { OrderDetailsComponent } from './components/order/shared/order-details/order-details.component';
import {
  OrderStatusHistoryComponent
} from './components/order/shared/order-status-history/order-status-history.component';
import { OrderSystemLogsComponent } from './components/order/shared/order-system-logs/order-system-logs.component';
import { OrderListItemComponent } from './components/order/shared/order-list-item/order-list-item.component';
import { OrderStatusComponent } from './components/order/shared/order-status/order-status.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  OrderMonitoringItemComponent
} from './components/order/shared/order-monitoring-item/order-monitoring-item.component';

import { MenuInputDirective } from './shared/directives/menu-input.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LivetrackingComponent } from './components/livetracking/livetracking.component';
import { GmapComponent } from './components/livetracking/gmap/gmap.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DishesService } from './services/dishes.service';
import { SortArrayPipe } from './shared/pipes/sort-array.pipe';
import {
  EditUpdateScheduleComponent
} from './components/update-schedule/edit-update-schedule/edit-update-schedule.component';
import { TimeZoneService } from './services/time-zone.service';
import {
  OrderListDetailsComponent,
  SankComponent
} from './components/order/order-list/order-list-details/order-list-details.component';
import {
  OrderMonitoringDetailsComponent
} from './components/order/order-monitoring/order-monitoring-details/order-monitoring-details.component';
import { LivetrackingApiService } from './services/livetracking-api.service';
import { CurrencyPipe } from './shared/pipes/currency.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SafeUrlPipe } from './shared/pipes/safe-url';
import { OrganizationPagesComponent } from './components/organization-pages/organization-pages.component';
import { OrderRedirectComponent } from './components/order-redirect/order-redirect.component';
import { OrderListRedirectComponent } from './components/order-list-redirect/order-list-redirect.component';
import { AddressOrganizationPipe } from './shared/pipes/addressOrganization.pipe';
import { AdminGuard } from './services/guards/admin.guard';
import { ManagerGuard } from './services/guards/manager.guard';
import { WeekPipe } from 'app/shared/pipes/week.pipe';
import { TagComponent } from './components/tag/tag.component';
import {
  GecoPosOrganizationModalComponent
} from 'app/components/geco-pos-organizations/geco-pos-organization-modal/geco-pos-organization-modal.component';
import { Store, StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { MasterOrderEffects } from 'app/reducers/master-order.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GecoPosOrganizationsComponent } from 'app/components/geco-pos-organizations/geco-pos-organizations.component';
import { appInitializer } from '../common';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { PosEffects } from 'app/reducers/pos.effects';
import { MenuService } from 'app/services/menu.service';
import {PreloaderModule} from "app/components/preloader/preloaderModule";
import { ProductApiService } from 'app/services/product-api.service';
import { MenuConverter } from 'app/services/menu.converter';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorSnackBarComponent,

        // Order components
        OrderListComponent,
        OrderFilterComponent,
        OrderMonitoringComponent,
        OrderComponent,
        OrderListDetailsComponent,
        SankComponent,

        EditUpdateScheduleComponent,
        InternalComponent,
        OrderDetailsComponent,
        OrderStatusHistoryComponent,
        OrderSystemLogsComponent,
        OrderStatusComponent,
        OrderListItemComponent,
        OrderMonitoringItemComponent,

        GecoPosOrganizationsComponent,

        MenuInputDirective,
        SafeUrlPipe,

    LivetrackingComponent,
    GmapComponent,
    OrderMonitoringDetailsComponent,
    GecoPosOrganizationModalComponent,
    NotFoundComponent,
    OrganizationPagesComponent,
    OrderRedirectComponent,
    OrderListRedirectComponent,
    TagComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PreloaderModule,

        HttpClientModule,
        AppRoutingModule,

        NgxsModule.forRoot([
            OrdersState,
            FiltersState,
            OrganizationsState
        ], {
            developmentMode: !environment.production
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            name: 'NGSX APP',
            disabled: environment.production
        }),

        // Push Browser notification
        // PushNotificationsModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMomentDateModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSidenavModule,
        MatMenuModule,
        MatTreeModule,
        MatGridListModule,
        MatListModule,
        MatDividerModule,
        MatCardModule,
        MatProgressBarModule,
        MatButtonToggleModule,
        MatDialogModule,
        DragDropModule,

        // App modules

        SharedModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ClipboardModule,
        StoreModule.forRoot(reducers, {
            metaReducers
        }),
        StoreDevtoolsModule.instrument({
            name: 'NGRX HUB APP',
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),
        EffectsModule.forRoot([
            MasterOrderEffects,
            PosEffects,
        ]),
    ],
    providers: [
        APIService,
        LivetrackingApiService,
        ProductApiService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RpcErrorInterceptorService,
            multi: true
        },
        SignalrService,
        RouterHelper,
        ExternalSystemPipe,
        AdminGuard,
        ManagerGuard,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        DishesService,
        MenuService,
        MenuConverter,
        TimeZoneService,
        SortArrayPipe,
        CurrencyPipe,
        AddressOrganizationPipe,
        WeekPipe,
        DatePipe,
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: appInitializer,
            deps: [
                [new Inject(Store)],
                TranslateService,
                DateAdapter,
            ]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS
        },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
