import { createAction } from '@reduxjs/toolkit';
import { MasterOrder } from 'app/models/masterOrder.model';
import {OrderFilter} from "app/models/orderFilter.model";

export const MASTER_ORDER_LOAD = '[MasterOrder] MASTER_ORDER_LOAD';
export const MASTER_ORDER_LOAD_SUCCESS = '[MasterOrder] MASTER_ORDER_LOAD_SUCCESS';
export const MASTER_ORDER_LOAD_ERROR = '[MasterOrder] MASTER_ORDER_LOAD_ERROR';
export const MASTER_ORDER_SET_CURRENT_ORDER = '[MasterOrder] MASTER_ORDER_SET_CURRENT_ORDER';
export const MASTER_ORDER_UPDATE_ORDER = '[MasterOrder] MASTER_ORDER_UPDATE_ORDER';
export const MASTER_ORDER_UPDATE_ORDER_FILTER = '[MasterOrder] UPDATE_ORDER_FILTER';

export const loadMasterOrders = createAction<void>(MASTER_ORDER_LOAD);
export const loadMasterOrdersSuccess = createAction<MasterOrder[]>(MASTER_ORDER_LOAD_SUCCESS);
export const loadMasterOrdersFailure = createAction<void>(MASTER_ORDER_LOAD_ERROR);
export const setCurrentOrder = createAction<MasterOrder>(MASTER_ORDER_SET_CURRENT_ORDER);
export const updateOrder = createAction<MasterOrder>(MASTER_ORDER_UPDATE_ORDER);
export const updateOrderFilter = createAction<OrderFilter>(MASTER_ORDER_UPDATE_ORDER_FILTER);
