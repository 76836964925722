import { NgModule } from '@angular/core';
import {PreloaderComponent} from "app/components/preloader/preloader.component";
import {CommonModule} from "@angular/common";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
  declarations: [PreloaderComponent],
  exports: [PreloaderComponent],
  imports: [CommonModule, MatProgressBarModule]
})
export class PreloaderModule {}
