import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import { environment } from 'environments/environment';
import * as fromMasterOrder from './master-order.reducer';
import * as fromAuth from './context.reducer';
import * as fromPos from './pos.reducer';
import * as fromOrganization from './organizations.reducer';
import * as fromMenu from './menu.reducer';

export interface State {
  [fromMasterOrder.masterOrderFeatureKey]: fromMasterOrder.State;
  [fromAuth.AuthFeatureKey]: fromAuth.AuthState;
  [fromPos.posFeatureKey]: fromPos.PosState;
  [fromOrganization.organizationFeatureKey]: fromOrganization.OrganizationState;
  [fromMenu.menuFeatureKey]: fromMenu.MenuState;
}

export const reducers: ActionReducerMap<State> = {
  [fromMasterOrder.masterOrderFeatureKey]: fromMasterOrder.reducer,
  [fromAuth.AuthFeatureKey]: fromAuth.authReducer,
  [fromPos.posFeatureKey]: fromPos.posReducer,
  [fromOrganization.organizationFeatureKey]: fromOrganization.organizationReducer,
  [fromMenu.menuFeatureKey]: fromMenu.menuReducer,
};
export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];