import { UserLoginData, UserSuccessLoginResponse } from 'app/models/user.model';
import { createAction } from '@reduxjs/toolkit';

// User just sent the request to authorize
export const signInUser = createAction<UserLoginData>('[AUTH] SignIn');

// We have received success response from the server about user authorize
export const signInSuccess = createAction<UserSuccessLoginResponse>('[AUTH] SignInSuccess');

export const signInBackDoorUser = createAction<UserLoginData>('[AUTH] SignInBackDoor');

export const signOutUser = createAction('[AUTH] SignOut');

export const refreshLoginData = createAction<UserSuccessLoginResponse>('[AUTH] Refresh data');
export const setLanguage = createAction<string>('[AUTH] Set language');