export const getDefaultOrderListFilter = (relativeDate?: Date): DefaultDateFilter => {
  const currentDate = relativeDate ?? new Date();
  const fromDate = new Date(currentDate.toDateString());
  const toDate = new Date(currentDate.toDateString());
  fromDate.setHours(6);
  fromDate.setMinutes(0);
  toDate.setHours(6);
  toDate.setMinutes(0);
  if (currentDate.getHours() >= 6) {
    // Today-tomorrow after 6:00
    toDate.setDate(fromDate.getDate() + 1);
    toDate.setHours(6);
    toDate.setMinutes(0);
  }
  if (currentDate.getHours() < 6) {
    // Yesterday-today до 6:00
    fromDate.setDate(currentDate.getDate() - 1);
  }

  return { fromDate, toDate, skip: 0, take: 500, orderNumber: '' };
};

export interface DefaultDateFilter {
  fromDate: Date;
  toDate: Date;
  skip: number;
  take: number;
  orderNumber: string;
}
