import { Organization } from 'app/models/organization.model';
import { createAction } from '@reduxjs/toolkit';
import { PipeConfiguration } from "app/models/pipe.model";
import { PipeRevisionStatus } from "app/models/organizations.models";
import { MenuStorage } from "app/models/menuStorage.model";

export const InitOrganizations = createAction('[ORGANIZATION] Init organizations');
export const SetMasterOrganization = createAction<string | null>('[ORGANIZATION] Set SetMasterOrganization');

export const updateCreateOrganization = createAction<Organization>('[ORGANIZATION] update and create organization');
export const setOrganization = createAction<string>('[ORGANIZATION] set organization');

export const clearOrganization = createAction('[ORGANIZATION] Clear organizations and branches');

export const switchDisplayDeletedPipesAndConfigurations = createAction('[ORGANIZATION] Switch display deleted configurations');

export const setPipes = createAction<string[]>('[Orders] Set pipes');

export const getExternalSystemsInfo = createAction('[ORGANIZATION] Get external systems info');

export class LoadRevisionForPipeGroups {
  static readonly type = '[ORGANIZATION] LoadRevisionForPipeGroup';

  constructor(public pipes: PipeConfiguration[]) {
  }
}

export class PipeRevisionChanged {
  static readonly type = '[ORGANIZATION] PipeRevisionChanged';

  constructor(public status: PipeRevisionStatus) {
  }
}

export class MenuStatusChanged {
  static readonly type = '[ORGANIZATION] MenuStatusChanged';

  constructor(public pipeId: string,public status: MenuStorage) {
  }
}

export class CreateOrganization {
  static readonly type = '[ORGANIZATION] Create organization';

  constructor(public organization: Organization) {
  }
}

export class UpdateOrganization {
  static readonly type = '[ORGANIZATION] Update organization';

  constructor(public organization: Organization) {
  }
}

export class SetOrganization {
  static readonly type = '[ORGANIZATION] Set organization';

  constructor(public organizationId: string) {
  }
}

export class ClearOrganization {
  static readonly type = '[ORGANIZATION] Clear organizations and brances';

  constructor() {
  }
}

export class SwitchDisplayDeletedPipesAndConfigurations {
  static readonly type = '[ORGANIZATION] Switch display deleted configurations';

  constructor() {
  }
}

export class SetPipes {
  static readonly type = '[Orders] Set pipes';

  constructor(public organizationIds: string[]) {
  }
}

export class GetExternalSystemsInfo {
  static readonly type = '[ORGANIZATION] Get external systems info';

  constructor() {
  }
}
