import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek} from 'app/models/organization.model';

@Pipe({
  name: 'weekPipe'
})
export class WeekPipe implements PipeTransform {
  transform(value: DayOfWeek, ...args: unknown[]): string {
    switch (value){
      case 0: return 'SUNDAY';
      case 1: return 'MONDAY';
      case 2: return 'TUESDAY';
      case 3: return 'WEDNESDAY';
      case 4: return 'THURSDAY';
      case 5: return 'FRIDAYS';
      case 6: return 'SATURDAY';
      default: return '';
    }
  }
}
