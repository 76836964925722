 <div *ngIf="currentOrganization; else noOrganization">
    <router-outlet></router-outlet>
  </div>
  <ng-template #noOrganization>
    <div class="not-found" *ngIf="activatedRoute.params | async as activatedRouteParams">
      {{"ORDERS.ORGANIZATION_NOT_ACCESS" | translate}} <br>
      {{"ORDERS.ID" | translate}} {{activatedRouteParams.organizationId}} <br>
      <span style="font-size: 14px;">{{"ORDERS.CONTACT_SUPPORT" | translate}}</span>
    </div>
  </ng-template>

