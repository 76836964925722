import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { APIService } from 'app/services/api.service';
import { MenuStorage } from 'app/models/menuStorage.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IBaseResponse } from 'app/models/baseResponse.model';
import { utcToLocalFormat } from "app/shared/pipes/utc-to-local.pipe";
import { AppHelper } from "app/helpers/appHelper";

@UntilDestroy()
@Component({
  selector: 'app-menu-history-modal',
  templateUrl: './menu-history-modal.component.html',
  styleUrls: ['./menu-history-modal.component.scss']
})
export class MenuHistoryModalComponent implements OnInit {
  menuHistory: MenuStorage[] = [];
  dataSource = new MatTableDataSource(this.menuHistory);
  columnsToDisplay = ['revision', 'uploadedAt', 'published', 'uploadMessage', 'uploadResult', 'actions'];
  dateFormat: string = utcToLocalFormat;

  constructor(
    private api: APIService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<MenuHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.api.getMenuStorageList(this.data.pipeConfigurationId)
      .then(({result, error}) => {
        if (!error) {
          this.menuHistory = [];
          this.menuHistory = [...result];
          this.dataSource = new MatTableDataSource(this.menuHistory);
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }

  getColumnHeader(columnHeaderValue: string): string {
    switch (columnHeaderValue) {
      case 'revision':
        return this.translate.instant('MENU_HISTORY.REVISION');
      case 'uploadedAt':
        return this.translate.instant('MENU_HISTORY.UPLOADED_AT');
      case 'published':
        return this.translate.instant('MENU_HISTORY.PUBLISHED_AT');
      case 'uploadMessage':
        return this.translate.instant('MENU_HISTORY.UPLOAD_MESSAGE');
      case 'uploadResult':
        return this.translate.instant('MENU_HISTORY.UPLOAD_RESULT');
      case 'actions':
        return this.translate.instant('MENU_HISTORY.ACTIONS');
      default:
        return '';
    }
  }

  downloadSourceMenu(menuId: string): void {
    this.downloadCurrentMenuForPipe(menuId, true);
  }

  downloadTargetMenu(menuId: string): void {
    this.downloadCurrentMenuForPipe(menuId, false);
  }

  downloadDerivative(objectId: string, uploadedAt: Date): void {
    const name = `derivative_${uploadedAt.toISOString}`;
    this.api.getBucketById(objectId, name)
      .subscribe({
        next: (res: IBaseResponse<string>) => {
          if (res) {
            AppHelper.openList(res.data);
          }
        },
        error: (e) => {
          console.log(e);
          alert('Can\'t download file');
        }
      });
  }

  private downloadCurrentMenuForPipe(menuId: string, isSource: boolean): void {
    this.api.getMenuUrlById(menuId, isSource)
      .subscribe({
        next: (res: IBaseResponse<string>) => {
          if (res) {
            AppHelper.openList(res.data);
          }
        },
        error: (e) => {
          console.log(e);
          alert('Can\'t download file');
        }
      });
  }
}
