import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(value: string, join?: any): string {
    if (!join) {
      return value;
    }
    return value + ', ' + join;
  }
}
