import { Pipe, PipeTransform } from '@angular/core';
import { IExternalSystem } from 'app/models/externalSystem.model';

@Pipe({
  name: 'externalSystem'
})
export class ExternalSystemPipe implements PipeTransform {

  transform(value: number, externalSystems: IExternalSystem[], field?: string): string {
    externalSystems = externalSystems && externalSystems.length > 0 ? externalSystems : [];
    const findedExternalSystem = externalSystems.find(item => item.key === value);

    let result = 'unknown';

    if (!findedExternalSystem) {
      return result;
    }

    if (field === 'name') {
      result = findedExternalSystem.params?.name;
    } else if (field === 'type') {
      result = findedExternalSystem.params?.configurationType ?? 'unknown';
    } else if (field === 'externalIdComment') {
      result = findedExternalSystem.params?.externalIdComment ?? '';
    } else {
      result = findedExternalSystem.params?.name;
    }
    return result;
  }
}
