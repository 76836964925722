export interface IRpcResponse<T> {
    id: number;
    jsonrpc: string;
    result: T;
    error?: IRpcError;
}

export interface IRpcError {
    code: ExceptionCode;
    message: string;
    data: any;
}

export enum ExceptionCode
{
    Empty = 0,
    WrongGuid = 1,
    NotFoundObjects = 2,
    AccessDenied = 3,
    WrongEnum = 4,
    NonValid = 5,
    WrongSettings = 1001,
}
