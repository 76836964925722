export enum Currency {
    AED = 784,
    RUB = 643,
    QAR = 634,
    SAR = 682,
    JOD = 400,
    KES = 404,
    EGP = 818,
    OMR = 512,
}
