import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArr'
})
export class EnumToArrPipe implements PipeTransform {
    transform(eNum: object, asString: boolean = false): string[] {
        const stringIsNumber = !asString?
            val => isNaN(Number(val)) === false
            : vale => true;
        ;
        return Object.keys(eNum)
            .filter(stringIsNumber)
            .map(key => eNum[key]);
    }

}
