import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationConfigurationType'
})
export class NotificationConfigurationTypePipe implements PipeTransform {
  types = {1: 'Email', 2: 'Telegram', 3: 'WebHook', 4: 'Pyrus'};

  transform(value: number): string {
    const key = value;
    return this.types.hasOwnProperty(key) ? this.types[key] : 'unknown';
  }

}
