<section *ngIf="order && order.details">
  <h3 class="details-header">{{ 'ORDERS.DELIVERING' | translate }}</h3>
  <div *ngIf="deliveringBy.length" class="deliveringBy">{{ this.deliveringBy }}</div>
  <div class="details-content">
    <div class="details-info">
      <div class="details-key">{{ 'ORDERS.CUSTOMER' | translate }}</div>
      <div class="details-value">{{order.details.clientInfo?.name}}</div>
      <div class="details-value">{{order.details?.clientInfo?.phone}}</div>
      <div class="details-value">{{order.details?.clientInfo?.email}}</div>
      <div class="details-key">
        <span>{{ 'ORDERS.DELIVERY_TYPE' | translate }}</span>
      </div>
      <div class="details-value">
        <span
          *ngIf="order.details && order.details.deliveryInfo && order.details.deliveryInfo.isTakeAway">{{ 'ORDERS.PICKUP' | translate }}</span>
        <span
          *ngIf="order.details && order.details.deliveryInfo && !order.details.deliveryInfo.isTakeAway">{{ 'ORDERS.DELIVERY' | translate }}</span>
      </div>
      <div class="details-key"
           *ngIf="order.details && order.details.deliveryInfo && !order.details.deliveryInfo.isTakeAway">
        <span>{{ 'ORDERS.DELIVERY_ADDRESS' | translate }}</span>
      </div>
      <div class="details-value"
           *ngIf="order.details && order.details.deliveryInfo && !order.details.deliveryInfo.isTakeAway">
        <span *ngIf="order.details.deliveryInfo.country">{{order.details.deliveryInfo.country}}, </span>
        <span *ngIf="order.details.deliveryInfo.area">{{order.details.deliveryInfo.area}}, </span>
        <span *ngIf="order.details.deliveryInfo.city">{{order.details.deliveryInfo.city}}, </span>
        <span *ngIf="order.details.deliveryInfo.address">{{order.details.deliveryInfo.address}}</span>
      </div>
      <div
        *ngIf="order.details.deliveryInfo && order.details.deliveryInfo.latitude && order.details.deliveryInfo.longitude">
        <a
          href="{{mapUrl + order.details.deliveryInfo.latitude + '+' + order.details.deliveryInfo.longitude}}"
          target="_blank"
        >{{'ORDERS.ON_MAP' | translate}}
        </a>
      </div>
      <div *ngIf="order?.metadata?.orderInfoDto?.courierInfo">
        <div class="details-key">{{'ORDERS.COURIER' | translate}}</div>
        <span
          *ngIf="order?.metadata?.orderInfoDto?.courierInfo?.courierId"> ID: {{order?.metadata?.orderInfoDto?.courierInfo?.courierId}}
          ,</span>
        <span *ngIf="order?.metadata?.orderInfoDto?.courierInfo?.courierName"> {{'ORDERS.NAME' | translate}}
          : {{order?.metadata?.orderInfoDto?.courierInfo?.courierName}},</span>
      </div>
      <div *ngIf="this.calculateViewInfo && this.calculateViewInfo.length > 0">
        <div class="details-key">{{'ORDERS.CALCULATION_OF_DELIVERY' | translate}}</div>
        <div class="details-values">
          <table mat-table [dataSource]="this.calculateViewInfo">
            <ng-container
              *ngFor="let column of columnsToDisplay"
              matColumnDef="{{ column }}">
              <th mat-header-cell class="cell" *matHeaderCellDef>{{getColumnHeader(column)}}</th>
              <td mat-cell class="cell" *matCellDef="let element">
                <div [ngSwitch]="column">
                  <ng-template ngSwitchCase="viewCourier">
                    {{ element[column] }}
                  </ng-template>
                  <ng-template ngSwitchCase="viewPriceETA">
                    {{ element[column] }}
                  </ng-template>
                  <ng-template ngSwitchCase="viewTravelETA">
                    {{ element[column] }}
                  </ng-template>
                </div>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="this.columnsToDisplay"></tr>
            <tr
              mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
          </table>
        </div>
      </div>

    </div>
    <!-- <p>Courier </p> -->
  </div>
</section>
