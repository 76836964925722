import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'joinArray'
})
export class JoinArrayPipe implements PipeTransform {
    transform(array: string[] ): string {
        if (!Array.isArray(array)){
            return array;
        }
        return array.join(', ');
    }
}
