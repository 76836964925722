import { MasterOrderState } from 'app/models/masterOrder.model';

export enum OrderStatus {
  // Aggregate states
  NEW = 0x0001,
  ACCEPTED = 0x0002,
  COOKING = 0x0004,
  READY_TO_SHIP = 0x0008,
  SHIPPED = 0x00010,
  DELIVERED = 0x0020,
  CLOSED = 0x0040,
  CANCELLED = 0x0080,

  // Filter stage
  FILTER_STATE = 0x00FF,

  // Error flag
  CONVERSION_ERROR = 0x0100,
  IGNORED = 0x0200,
  GIVEN_UP = 0x0400,

  // Source sync state
  SOURCE_NOT_SYNCED = 0x0800,
  SOURCE_SYNCED = 0x1000,
  SOURCE_SHOULD_BE_UPDATED = 0x2000,
  SOURCE_SYNC_ERROR = 0x4000,

  // Target sync state
  TARGET_NOT_SYNCED = 0x8000,
  TARGET_SYNCED = 0x10000,
  TARGET_SHOULD_BE_UPDATED = 0x20000,
  TARGET_SYNC_ERROR = 0x40000,

  // HasProblem. THERE MUST BE A CORRESPONDENCE WITH THE LOGIC OF CALCULATING ERRONEOUS ORDERS IN THE SERVER APPLICATION
  ANY_ERROR = CONVERSION_ERROR | GIVEN_UP | SOURCE_SYNC_ERROR | TARGET_SYNC_ERROR
}

export enum OrderState {
  // Aggregate states
  NEW = 1,
  ACCEPTED = 2,
  COOKING = 3,
  READY_TO_SHIP = 4,
  SHIPPED = 5,
  DELIVERED = 6,
  CLOSED = 7,
  CANCELLED = 8,
}

export interface OrderStatusInfo {
  isNewOrder: boolean;
  state: OrderStatus;
  masterState: MasterOrderState;
  stateName: string;
  isFinalState: boolean;
  nextState: OrderStatus | null;
  nextMasterState: MasterOrderState | null;
  nextStateName?: string;
  isError: boolean;
  isWarning: boolean;
}
