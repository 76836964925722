<div class="form-wrapper">
    <form name="form" [formGroup]="gecoPosOrganizationForm" class="form">

        <mat-form-field>
            <mat-label>{{ "ORGANIZATION.NAME" | translate }}</mat-label>
            <input matInput name="name" formControlName="name">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ "GECO_POS.API_KEY" | translate }}</mat-label>
            <input matInput type="text" name="apiKey" formControlName="apiKey">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ "GECO_POS.ORDER_CREATE_WEBHOOK_URL" | translate }}</mat-label>
            <input matInput type="url" name="orderCreateWebhookUrl" formControlName="orderCreateWebhookUrl">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ "GECO_POS.ORDER_STATUS_UPDATE_WEBHOOK_URL" | translate }}</mat-label>
            <input matInput type="url" name="orderStatusUpdateWebhookUrl" formControlName="orderStatusUpdateWebhookUrl">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ "GECO_POS.STOP_LIST_WEBHOOK_URL" | translate }}</mat-label>
            <input matInput type="url" name="stopListUpdateWebhookUrl" formControlName="stopListUpdateWebhookUrl">
        </mat-form-field>

        <mat-checkbox matInput formControlName="importFromPos" class="example-margin">
            <label>{{ "GECO_POS.IMPORT_FROM_POS" | translate }}</label>
        </mat-checkbox>

    </form>

    <div class="buttons">
        <button mat-button color="primary" *ngIf="item.isNew; else edit" (click)="add()">
            {{ "COMMON.ADD" | translate }}
        </button>
        <ng-template #edit>
            <button mat-button color="primary"
                    [disabled]="!this.gecoPosOrganizationForm.valid"
                    (click)="update()">
                {{ "COMMON.SAVE" | translate }}
            </button>
        </ng-template>
        <button mat-button color="warn" (click)="exit()">
            {{ "COMMON.CANCEL" | translate }}
        </button>
    </div>
</div>