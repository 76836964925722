import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PosActions from './pos.actions';
import { map, switchAll, switchMap, tap } from 'rxjs';
import { DishesService } from "app/services/dishes.service";

@Injectable()
export class PosEffects {

  fetchOrganizations = createEffect(() => {
    return this.actions$.pipe(
      ofType(PosActions.loadInfo),
      switchMap(async (action) => {
        const data = await this.dishesService.getMenuOrganizationList();
        if (data.success) {
          return PosActions.setInfo(data.data)
        }
        return PosActions.posRequestError(data.errors[0].message)
      })
    );
  });

  constructor(
    private actions$: Actions,
    private dishesService: DishesService,
  ) {
  }
}
