<div class="delivery">
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="isWaiting"></mat-progress-bar>
    <button class="mat-flat-button mat-button-base mat-primary" style="margin: 30px;"
            (click)="clickDelivery()"
            *ngIf="!isCalculated">{{'ORDERS.CALCULATE_DELIVERY' | translate}}</button>
    <div *ngIf="isCalculated">
        <h3 class="delivery-header"
            mat-header> {{deliveryCalculates?.length}} {{'ORDERS.SERVICES_AVALIABLE' | translate}}</h3>

        <div class="delivery-items">

            <div class="delivery-item combo" *ngFor="let calculate of deliveryCalculates">

                <div class="delivery-item-header">

                    <div class="delivery-item-logo-wrapper">
                        <img *ngIf="getImage(calculate).result" src="../../../..{{getImage(calculate).logo}}" alt="" class="delivery-item-logo">
                        <div *ngIf="getImage(calculate).result">{{getImage(calculate).logo}}</div>
                        <div>{{calculate.travelMethod}}</div>
                        <div *ngIf="calculate.calculationError">{{'ORDERS.CALCULATION_ERROR' | translate }}</div>
                        <div *ngIf="calculate.isAvailableCalculation && !calculate.calculationAttemptMade">{{'ORDERS.CALCULATION_IN_PROGRESS' | translate }}</div>
                    </div>
                    <mat-spinner [diameter]="30" *ngIf="calculate.isAvailableCalculation && !calculate.calculationAttemptMade"></mat-spinner>
                    <div *ngIf="calculate.fastest || calculate.cheapest" [ngClass]="{'delivery-item-bests': calculate.fastest && calculate.cheapest}">
                        <div *ngIf="calculate.fastest" class="delivery-item-bests-time">{{'ORDERS.BEST_TIME' | translate}}</div>
                        <div *ngIf="calculate.cheapest" class="delivery-item-bests-price">{{'ORDERS.BEST_PRICE' | translate}}</div>
                        <div *ngIf="calculate.fastest && calculate.cheapest" class="delivery-item-bests-combo">{{'ORDERS.COMBO' | translate}}</div>
                    </div>

                </div>

                <div class="delivery-item-footer">

                    <div *ngIf="calculate.calculationAttemptMade && calculate.isAvailableCalculation" class="delivery-item-description">

                        <div *ngIf="calculate.priceETA || calculate.priceETA === 0" class="delivery-item-price">
                            <div class="delivery-item-price-number">{{calculate.priceETA}}</div>
                            <div class="delivery-item-price-currency">{{organization.currency}}</div>
                        </div>

                        <div *ngIf="calculate.deliveryTime" class="delivery-item-time">

                            <div class="delivery-item-till">
                                {{'ORDERS.TILL' | translate}} {{calculate.deliveryTime |  date:'HH:mm'}}
                            </div>
                            <div class="delivery-item-about">
                                {{'ORDERS.ABOUT' | translate}}
                                {{calculate.travelETA}}
                                {{'ORDERS.MINUTES' | translate}}
                            </div>

                        </div>

                    </div>

                    <button mat-flat-button class="delivery-item-button" (click)="sendCourierOrder(calculate.courierType, calculate.travelMethod)">{{'ORDERS.SELECT' | translate}}</button>

                </div>

            </div>

        </div>
    </div>
</div>
