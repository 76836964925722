export enum ConfigurationType {
  POS = 'POS',
  Sales = 'Sales',
  Delivery = 'Delivery',
  Analytic = 'Analytic',
  Acquiring = 'Acquiring'
}

export type ExternalSystemId = number;

export interface IExternalSystem {
  externalSystemType: string;
  key: ExternalSystemId;
  params: IExternalSystemParams;
}

export const IIKO_ID: ExternalSystemId = 4;
export const IIKO_CODE = "IIKO";

export const DELIVERYHERO_ID: ExternalSystemId = 8;
export const DELIVERYHERO_CODE = "DELIVERYHERO";

export const KITOPI_ID: ExternalSystemId = 12;
export const KITOPI_CODE = "KITOPI";

export const GECOPOS_ID: ExternalSystemId = 36;
export const GECOPOS_CODE = "GECOPOS";

export const GECOPOSSALES_ID: ExternalSystemId = 38;
export const GECOPOSSALES_CODE = "GECOPOSSALES";

export const KITOPIV3_ID: ExternalSystemId = 46;
export const KITOPIV3_CODE = "KITOPIV3";

export interface IExternalSystemParams {
  logo: string;
  name: string;
  configurationType: ConfigurationType;
  isSupportMenuExport: boolean;
  isSupportStopListExport: boolean;
  isSupportDownloadNomenclature: boolean;
  isSupportDownloadStopList: boolean;
  isSupportMenuImport: boolean;
  isSupportDemandMenuImport: boolean;
  isSupportStopListImport: boolean;
  isAvailableCalculation: boolean;
  isSupportAcquiring: boolean;
  externalIdComment: string;
  isSupportSwitchStoreStatus: boolean;
}
