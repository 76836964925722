import { Component, Input } from '@angular/core';
import { OrderStatus } from 'app/models/orderStatus.model';
import { OrderTransitionalHistory } from 'app/models/orderTransitionalHistory.model';

@Component({
  selector: 'app-order-status-history',
  templateUrl: './order-status-history.component.html',
  styleUrls: ['./order-status-history.component.scss']
})
export class OrderStatusHistoryComponent {

  @Input() statusHistory: OrderTransitionalHistory[];

  /* eslint-disable no-bitwise */
  public convertStatus(value: number): string {
    switch (value & OrderStatus.FILTER_STATE) {
      case OrderStatus.NEW:
        return 'STATUS.NEW';
      case OrderStatus.ACCEPTED:
        return 'STATUS.ACCEPTED';
      case OrderStatus.COOKING:
        return 'STATUS.COOKING';
      case OrderStatus.READY_TO_SHIP:
        return 'STATUS.READY_TO_SHIP';
      case OrderStatus.SHIPPED:
        return 'STATUS.SHIPPED';
      case OrderStatus.DELIVERED:
        return 'STATUS.DELIVERED';
      case OrderStatus.CLOSED:
        return 'STATUS.CLOSED';
      case OrderStatus.CANCELLED:
        return 'STATUS.CANCELLED';
      case OrderStatus.ANY_ERROR:
        return 'STATUS.ERRORED';
    }
    return 'Unknown';
  }

}
