import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isPaid'
})
export class IsPaid implements PipeTransform {
  transform(value: boolean): string {
    if (value) {
      return 'ORDERS.PAID';
    } else {
      return 'ORDERS.NOT_PAID';
    }
  }
}
