import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalSystemPipe } from './pipes/external-system.pipe';
import { BuildNzNodeTreePipe } from './pipes/build-nz-node-tree.pipe';
import { OpenOrdersPipe } from './pipes/open-orders.pipe';
import { ProductsWithPricePipe } from './pipes/products-with-price.pipe';
import { ExternalSystemTypeFilterPipe } from './pipes/external-system-type-filter.pipe';
import { PipeComponent } from './components/pipe/pipe.component';
import { PipeUserComponent } from './components/pipe-user/pipe-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SystemTagComponent } from './components/system-tag/system-tag.component';
import { UtcToLocalPipe } from './pipes/utc-to-local.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { JoinArrayPipe } from './pipes/join-array.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { TakePipe } from './pipes/take.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CalculateDeliveringComponent } from '../components/order/shared/calculate-delivering/calculate-delivering.component';
import { IsPaid } from './pipes/is-paid.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CurrencyPipe } from './pipes/currency.pipe';
import { EnumToArrPipe } from './pipes/enum-to-arr.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimerComponent } from './components/timer/timer.component';
import { MatButtonModule } from '@angular/material/button';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ImageTooltipComponent } from './components/image-tooltip/image-tooltip.component';
import { ImageTooltipDirective } from './components/image-tooltip/image-tooltip.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { NotificationConfigurationTypePipe } from './pipes/notification-configuration-type.pipe';
import { SelectOrganizationsModalComponent } from './components/select-organizations-modal/select-organizations-modal.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddressOrganizationPipe } from './pipes/addressOrganization.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { SortArrayPipe } from 'app/shared/pipes/sort-array.pipe';

import { JsonSchemaFormModule } from '@ajsf/core';
import { MaterialDesignFrameworkModule } from '@ajsf/material';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrderPaymentComponent } from 'app/shared/components/order-payment/order-payment.component';
import { OrderDeliveringComponent } from 'app/shared/components/order-delivering/order-delivering.component';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BaseModalComponent } from 'app/shared/base-modal/base-modal.component';
import { MasterOrderStatePipe } from 'app/shared/pipes/master-order-state.pipe';
import { HelpButtonComponent } from './components/buttons/help-button/help-button.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const pipes = [
  ExternalSystemPipe,
  NotificationConfigurationTypePipe,
  BuildNzNodeTreePipe,
  ProductsWithPricePipe,
  OpenOrdersPipe,
  ExternalSystemTypeFilterPipe,
  UtcToLocalPipe,
  JoinPipe,
  JoinArrayPipe,
  MomentPipe,
  PluckPipe,
  TakePipe,
  IsPaid,
  CurrencyPipe,
  EnumToArrPipe,
  SortArrayPipe,
  MasterOrderStatePipe
];

const components = [
  PipeComponent,
  PipeUserComponent,
  SystemTagComponent,
  CalculateDeliveringComponent,
  TimerComponent,
  AddressOrganizationPipe,
  OrderPaymentComponent,
  OrderDeliveringComponent
];

const matModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatButtonToggleModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatTreeModule,
  MaterialDesignFrameworkModule, // без этого модуля вёрстка не будет material
];

@NgModule({
  declarations: [
    ...pipes,
    ...components,
    BaseModalComponent,
    TimerComponent,
    SnackbarComponent,
    ImageTooltipComponent,
    ImageTooltipDirective,
    NotificationModalComponent,
    SelectOrganizationsModalComponent,
    HelpButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,

    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false
    }),
    ReactiveFormsModule,
    FlexLayoutModule,

    // для JSON форм
    JsonSchemaFormModule,

    ...matModules
  ],
  providers: [
    SortArrayPipe
  ],
  exports: [
    ...pipes,
    ...components,
    DragDropModule,
    ImageTooltipDirective,
    matModules,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    HelpButtonComponent,
  ]
})
export class SharedModule {
}
