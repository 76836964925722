import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

if ( environment.production ){
    enableProdMode();
}

Sentry.init({
    dsn: 'https://d0d6c91d7faf4510accda28b2953414a@bugs.open-s.info/7',
    environment: environment.env,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ['https://localhost', 'open-hub.io', 'https://hub.staging.open-s.info']
        })
    ]
});

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
