<div class="pipe" [class.pipe-edit]="!pipe.isActive">
    
    
    <div 
      [class.pipe-left]="true" 
      [class.DoesNotApplyToTheCurrentOrganization]="!isCurrentOrganization(pipe.sourceConfiguration)"
      #tooltip="matTooltip"
      [matTooltip]="getTooltipText(pipe.sourceConfiguration)"
      matTooltipClass="tooltip"
      matTooltipPosition="above"
      (click)="pipeClick(pipe.sourceConfiguration)"
    >
        <div class="pipe-label">{{pipe.sourceConfiguration.externalSystemType | externalSystem: this.externalSystems: 'type'}}</div>
        <div class="pipe-name">{{pipe.sourceConfiguration.externalSystemType |  externalSystem: this.externalSystems:'name'}}</div>
    </div>
    <div class="pipe-toggle-container">
        <div class="pipe-toggle">
            <mat-slide-toggle [checked]="pipe.isActive" (toggleChange)="togglePipe()"></mat-slide-toggle>
        </div>
    </div>
    <div
      [class.pipe-right]="true"
      [class.DoesNotApplyToTheCurrentOrganization]="!isCurrentOrganization(pipe.targetConfiguration)"
      #tooltip="matTooltip"
      [matTooltip]="getTooltipText(pipe.targetConfiguration)"
      matTooltipClass="tooltip"
      matTooltipPosition="above"
      (click)="pipeClick(pipe.targetConfiguration)"
    >
        <div class="pipe-label">{{pipe.targetConfiguration.externalSystemType | externalSystem: this.externalSystems:'type'}}</div>
        <div class="pipe-name">{{pipe.targetConfiguration.externalSystemType |  externalSystem: this.externalSystems:'name'}}</div>
    </div>
    <div class="pipe-actions">
        <div *ngIf="allowLoadMenu" class="loadMenu">
            <div *ngIf="isLoadedMenu">{{'MENU.SENTMENU' | translate}}</div>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button *ngIf="allowLoadMenu && !isLoadedMenu" (click)="uploadMenu()" mat-menu-item>
                <mat-icon class="menu-icon">restaurant</mat-icon>
                <span>{{ 'MENU.UPLOAD' | translate}}</span>
            </button>

            <button *ngIf="allowDownloadMenu" (click)="openMenuHistoryModal()" mat-menu-item>
                <mat-icon class="menu-icon">dehaze</mat-icon>
                <span>{{ 'MENU_HISTORY.HISTORY' | translate}}</span>
            </button>

            <button *ngIf="allowLoadStopList" (click)="openStopListHistoryModal()" mat-menu-item>
              <mat-icon class="menu-icon">dehaze</mat-icon>
              <span>{{ 'STOP_LIST.HISTORY' | translate}}</span>
            </button>

            <button *ngIf="allowLoadMenu" (click)="handleUpdateScheduleClick('menu')" mat-menu-item>
                <mat-icon class="menu-icon" [style.color]="updateScheduleForMenuIsActive ? '#4896db' : ''">schedule</mat-icon>
                <span>{{ 'UPDATE_SCHEDULE.EDIT_UPDATE_SHEDULE_MENU' | translate}}</span>
            </button>

            <button *ngIf="allowLoadStopList" (click)="handleUpdateScheduleClick('stop-list')" mat-menu-item>
                <mat-icon class="menu-icon" [style.color]="updateScheduleForStopListIsActive ? '#4896db' : ''">schedule</mat-icon>
                <span>{{ 'UPDATE_SCHEDULE.EDIT_UPDATE_SHEDULE_STOP_LIST' | translate}}</span>
            </button>

            <mat-divider></mat-divider>
            <button (click)="copyId()" mat-menu-item>
              <mat-icon class="menu-icon">file_copy</mat-icon>
              <span>{{ 'COMMON.COPY_ID' | translate}}</span>
            </button>
            <button (click)="move()" mat-menu-item>
                <mat-icon class="menu-icon">forward</mat-icon>
                <span>{{ 'COMMON.MOVE' | translate}}</span>
            </button>
            <button *ngIf="!pipe.isDeleted" (click)="remove()" mat-menu-item>
                <mat-icon class="menu-icon" style="color: #db4848">delete_outline</mat-icon>
                <span>{{ 'COMMON.DELETE' | translate}}</span>
            </button>
            <button *ngIf="pipe.isDeleted" (click)="restore()" mat-menu-item>
                <mat-icon class="menu-icon">restore</mat-icon>
                <span>{{ 'COMMON.RESTORE' | translate}}</span>
            </button>
        </mat-menu>
    </div>
</div>
