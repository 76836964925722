import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TrackingOrganization } from 'app/models/trackingOrganization.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LivetrackingApiService {
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  errors$: Subject<any>;

  constructor(private http: HttpClient) {
    this.errors$ = new Subject();
  }

  public getTrackingOrganizations(userId: string): Observable<TrackingOrganization[]> {
    const url = environment.livetrackingBaseUrl + '/api/organization/';
    return this.http.get<TrackingOrganization[]>(url + userId);
  }

  public createTrackingOrganization(request: TrackingOrganization): Observable<TrackingOrganization> {
    const url = environment.livetrackingBaseUrl + '/api/organization/';
    return this.http.post<TrackingOrganization>(url, request);
  }

  public updateTrackingOrganization(request: TrackingOrganization): Observable<TrackingOrganization> {
    const url = environment.livetrackingBaseUrl + '/api/organization/';
    return this.http.put<TrackingOrganization>(url, request);
  }

  public getTrackingOrganizationById(organizationId: string, userId: string): Observable<TrackingOrganization> {
    const url = environment.livetrackingBaseUrl + '/api/organization/' + userId + '/' + organizationId;
    return this.http.get<TrackingOrganization>(url);
  }
}
