import { Pipe, PipeTransform } from '@angular/core';
import { MasterOrderState } from 'app/models/masterOrder.model';

@Pipe({
  name: 'masterOrderState'
})
export class MasterOrderStatePipe implements PipeTransform {
  transform(value: MasterOrderState, ...args: unknown[]): string {
    return MasterOrderState[value];
  }
}
