import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetPipes } from 'app/state-management/actions/organizations.actions';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { map, Observable, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentOrganizationPipesResolverService implements Resolve<string[]> {

  constructor(
    public store: Store,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<string[]> {
    return this.store.select(OrganizationsState.getSelectedOrganizationTreeIds)
      .pipe(
        take(1),
        switchMap((ids => {
          return this.store.dispatch(new SetPipes(ids)).pipe(map(() => {
            return ids;
          }));
        }))
      );
  }
}
