<div class="menu-history-modal">
  <h4>{{ 'STOP_LIST.HISTORY' | translate }}</h4>
  
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows>

    <ng-container
        *ngFor="let column of columnsToDisplay"
        matColumnDef="{{ column }}">
        <th mat-header-cell *matHeaderCellDef>{{ getColumnHeader(column) }}</th>
        <td  class="cell" mat-cell *matCellDef="let element">
            <div [ngSwitch]="column">

              <ng-template ngSwitchCase="revision">
                <b>{{ element.revision }}</b>
              </ng-template>

              <ng-template ngSwitchCase="uploadedAt">
                {{element?.uploadedAt | utcToLocal: this.dateFormat}}
              </ng-template>

              <ng-template ngSwitchCase="published">
                {{element?.published | utcToLocal: this.dateFormat}}
              </ng-template>

              <ng-template ngSwitchCase="uploadMessage">
                {{element?.uploadMessage}}
              </ng-template>

              <ng-template ngSwitchCase="uploadResult">
                {{element?.uploadResult}}
              </ng-template>

              <ng-template ngSwitchCase="actions">
                  <div class="icons-cell">
                    <mat-icon class="link-icon" *ngIf="element?.sourceId !== null" (click)="downloadSourceStopList(element.id)"
                        matTooltip="{{ 'STOP_LIST.DOWNLOAD_SOURCE' | translate}}"
                        matTooltipPosition="above">cloud_download
                    </mat-icon>
                    <mat-icon class="link-icon" *ngIf="element?.targetId !== null" (click)="downloadTargetStopList(element.id)"
                        matTooltip="{{ 'STOP_LIST.DOWNLOAD_TARGET' | translate}}"
                        matTooltipPosition="above">cloud_download
                    </mat-icon>
                  </div>
              </ng-template>

              <ng-template ngSwitchDefault>
                  {{ element[column] }}
              </ng-template>

            </div>

        </td>
    </ng-container>

    <tr
        mat-header-row
        *matHeaderRowDef="columnsToDisplay; sticky: true">
    </tr>
    <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay">
    </tr>
</table>
</div>