import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Store as ngrxStore } from '@ngrx/store';
import { DetailedOrderInQueue, OrderInQueue } from 'app/models/orderInQueue.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { Observable, take, withLatestFrom } from 'rxjs';
import { IExternalSystem } from '../../../../models/externalSystem.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-order-list-item',
  templateUrl: './order-list-item.component.html',
  styleUrls: ['./order-list-item.component.scss'],
})
export class OrderListItemComponent implements OnInit {

  @Input() order: OrderInQueue;
  @Input() currentOrder: DetailedOrderInQueue;
  @Input() className = 'card';
  @Input() selected = false;

  locale: string;
  isActive = false;
  externalSystems: IExternalSystem[];

  @Select(OrganizationsState.getSelectedOrganizationId) getSelectedOrganizationId$: Observable<string>;

  constructor(
    public store: Store,
    private ngrxStore: ngrxStore,
    public translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    translateService.onLangChange.subscribe((locale: LangChangeEvent) => {
      this.locale = locale.lang;
    });
  }

  ngOnInit(): void {
    this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);
  }

  orderClick(order: OrderInQueue): void {
    this.activatedRoute.queryParams
      .pipe(
        withLatestFrom(this.getSelectedOrganizationId$),
        take(1))
      .subscribe(([qParams, organizationId]) => {
        this.router.navigate(['/app', organizationId, 'order', 'list', order.id], {
          queryParams: qParams,
          replaceUrl: true
        }).catch();
      });
  }
}
