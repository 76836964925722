import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { DetailedOrderInQueue, OrderInQueue } from 'app/models/orderInQueue.model';
import { Organization } from 'app/models/organization.model';
import { PipeConfiguration } from 'app/models/pipe.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { Observable, withLatestFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersState } from 'app/state-management/states/orders.states';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from 'app/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleMembership } from "app/reducers/context.selectors";

@UntilDestroy()
@Component({
  selector: 'app-order-list-details',
  templateUrl: './order-list-details.component.html',
  styleUrls: ['./order-list-details.component.scss']
})
export class OrderListDetailsComponent implements OnInit {

  @Input() orders: OrderInQueue[];
  @Input() organization: Organization;
  @Input() roleMembership: RoleMembership;
  @Input() showDeliveryDetails: boolean;

  currentOrderOrganization: Organization;
  orderId: string = null;

  @Select(OrdersState.currentOrder) currentOrder$: Observable<DetailedOrderInQueue>;
  @Select(OrganizationsState.getBranches) organizations$: Observable<any[]>;
  @Select(OrganizationsState.pipes) pipes$: Observable<PipeConfiguration[]>;

  constructor(
    public store: Store,
    private snackBar: MatSnackBar,
    private router: Router,
    private clipboard: Clipboard,
    private snackbarService: SnackbarService,
    public translate: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.currentOrder$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.organizations$)
      )
      .subscribe(([currentOrder, organizations]) => {
        if (currentOrder) {
          this.orderId = currentOrder.id;
          this.currentOrderOrganization = organizations.find(org => org.id === currentOrder.organizationId);
        }
      });

  }

  copyLink(): void {
    const protocol = window.location.protocol;
    const host = window.location.host;
    if (this.orderId && this.currentOrderOrganization) {
      this.clipboard.copy(`${protocol}//${host}/app/${this.currentOrderOrganization.id}/order/details/${this.orderId}`);
      this.snackbarService.success(this.translate.instant('COMMON.LINK_COPIED_SUCCESSFULLY'));
    } else {
      this.snackbarService.error(this.translate.instant('ORDERS.ORDER_NOT_FOUND'));
    }
  }

  openSnackBar(): void {
    this.snackBar.openFromComponent(SankComponent, {
      duration: 3000,
    });
  }

}

@Component({
  selector: 'app-snack',
  template: 'Copied!'
})
export class SankComponent {
}
