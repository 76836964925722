import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Organization } from 'app/models/organization.model';
import { Store } from "@ngrx/store";
import { selectMenuOrganization } from 'app/reducers/menu.selectors';
import { PosOrganizationModel } from 'app/reducers/pos.reducer';
import * as MenuActions from 'app/reducers/menu.actions';
import { MenuService } from 'app/services/menu.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MenuRouteResolverService implements Resolve<any> {
  @Select(OrganizationsState.getSelectedOrganization) selectOrganization$: Observable<Organization>;
  menuOrganization$: Observable<PosOrganizationModel>;

  constructor(
    public store: Store,
    private menuService: MenuService,
  ) {
    this.menuOrganization$ = this.store.select(selectMenuOrganization);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): void {
    this.selectOrganization$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(selectedOrganization => {
        if (selectedOrganization) {
          this.store.dispatch(MenuActions.setCurrencyFromEnum(selectedOrganization.currency));
        }
      });

    this.menuOrganization$
      .pipe(
        untilDestroyed(this)
      ).subscribe((menuOrganization) => {
      if (menuOrganization) {
        this.menuService.loadMenuSyncOfPos(menuOrganization.id).subscribe();
        this.menuService.loadMenuSyncOfSales(menuOrganization.id).subscribe();
      }
    });
  }
}
