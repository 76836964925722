import { Pipe, PipeTransform } from '@angular/core';
import { Organization } from 'app/models/organization.model';

@Pipe({
  name: 'addressOrganizationPipe'
})
export class AddressOrganizationPipe implements PipeTransform {
  transform(value: Organization, ...args: unknown[]): string {
    let result = '';
    if (value?.country) {
      result += `Country: ${value?.country}`;
    }
    if (value?.area) {
      result += `, Area: ${value?.area}`;
    }
    if (value?.city) {
      result += `, City: ${value?.city}`;
    }
    if (value?.street) {
      result += `, Street: ${value?.street}`;
    }
    if (value?.house) {
      result += `, House: ${value?.house}`;
    }
    if (value?.entrance) {
      result += `, Entrance: ${value?.entrance}`;
    }
    if (value?.intercom) {
      result += `, Intercom: ${value?.intercom}`;
    }
    return result;
  }
}
