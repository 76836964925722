import { OrderStatusService } from 'app/services/order-status.service';
import {
  ClientInfoResponse,
  DeliveryInfoResponse,
  DiscountResponse,
  MasterOrderResponse,
  ModifierGroupResponse,
  ModifierResponse,
  OrderInfoDtoItemResponse,
  OrderInfoDtoResponse,
  OrderInQueueResponse,
  PaymentResponse,
  ReflectedOrderResponse,
  ClientInfo,
  DeliveryInfo,
  MasterOrder,
  ModifierGroupItem,
  OrderDiscount,
  OrderInfoDto,
  OrderInfoDtoItem,
  OrderInfoDtoItemModifierGroup,
  OrderInQueue,
  OrderPayment,
  ReflectedOrder, GroupsResponse, OrderInfoDtoItemGroup
} from 'app/models/masterOrder.model';
import { Organization } from 'app/models/organization.model';
import { ConfigurationType, GECOPOS_ID, GECOPOSSALES_ID, IExternalSystem } from 'app/models/externalSystem.model';

export const convertMasterOrder = (
  order: MasterOrderResponse,
  organizations: Organization[],
  externalSystems: IExternalSystem[]
): MasterOrder | null => {
  const reflectedOrders = order.reflectedOrders.map((reflectedOrder) => {
    return convertReflectedOrderResponse(reflectedOrder, externalSystems);
  });

  const orderInQueues = order.orderInQueues.map((orderInQueue) => {
    return convertOrderInQueueResponse(orderInQueue, reflectedOrders);
  });

  // Если и берём gecoPos, то в самую последнюю очередь
  const salesOrders = orderInQueues.filter((order) =>
    order.reflectedSourceOrder.externalSystem.params.configurationType ===
    ConfigurationType.Sales
  )
    .sort((a) =>
      a.reflectedSourceOrder.externalSystem.key === GECOPOSSALES_ID ? -1 : 1
    );

  // Don't show the order without sale system
  if (salesOrders.length === 0) {
    return null;
  }

  const finalPosOrderInQueue: OrderInQueue = salesOrders.length
    ? salesOrders[0]
    : orderInQueues[0];

  if (!finalPosOrderInQueue) {
    return null;
  }

  const posOrder = finalPosOrderInQueue.reflectedTargetOrder;

  const sourceOrder: ReflectedOrder =
    salesOrders.length > 0
      ? salesOrders[0].reflectedSourceOrder
      : orderInQueues[0].reflectedSourceOrder;

  const courierOrder = orderInQueues.find(
    (order) =>
      order.reflectedSourceOrder.externalSystem.params.configurationType ===
      ConfigurationType.Delivery
  );


  reflectedOrders.sort((a) => {
    switch (a.externalSystem.params.configurationType) {
      case ConfigurationType.Sales:
        return -1;
      case ConfigurationType.POS:
        return 0;
      default: return 1
    }
  })

  return {
    id: order.id,
    organizationId: order.organizationId,
    organization: organizations.find(
      (value) => value.id === order.organizationId
    ),
    reflectedOrders: reflectedOrders,
    orderInQueues: orderInQueues,
    finalPosOrderInQueue: finalPosOrderInQueue,
    posOrder,
    sourceOrder: sourceOrder,
    courierOrder: courierOrder ? courierOrder.reflectedTargetOrder : undefined,
    createdAt: new Date(order.createdAt + 'Z'),
    orderState: order.orderState,
    metadata: order.metadata
  };
};

export const convertReflectedOrderResponse = (
  order: ReflectedOrderResponse,
  externalSystems: IExternalSystem[]
): ReflectedOrder => {
  return {
    id: order.id,
    status: order.orderState,
    externalSystem: externalSystems.find(
      (externalSystem) => externalSystem.key === order.externalSystemType
    ),
    externalSystemType: order.externalSystemType,
    updatedAt: new Date(order.updatedAt + 'Z'),
    createdAt: new Date(order.orderCreationAt + 'Z'),
    sourceHumanOrderId: order.metadata.sourceHumanOrderId
      ? order.metadata.sourceHumanOrderId
      : '',
    orderInfoDto: order.metadata.orderInfoDto
      ? convertOrderInfoDtoResponse(order.metadata.orderInfoDto)
      : {
        orderNumber: order.externalOrderId,
        sum: 0,
        payments: [],
        comment: '',
        promoCode: '',
        discounts: [],
        items: [],
        isGoOrder: false,
        deliveryInfo: null,
        courierInfo: null,
        clientInfo: null
      },
  };
};

export const convertOrderInfoDtoResponse = (
  order: OrderInfoDtoResponse
): OrderInfoDto => {
  return {
    items: order.items ? order.items.map(convertOrderInfoDtoItemResponse) : [],
    comment: order.comment ? order.comment : '',
    discounts: order.discounts
      ? order.discounts.map(convertDiscountsResponse)
      : [],
    promoCode: order.promoCode ? order.promoCode : '',
    sum: order.sum ? order.sum : 0,
    orderNumber: order.orderNumber ? order.orderNumber : '',
    payments: order.payments
      ? order.payments.map(convertOrderPaymentResponse)
      : [],
    isGoOrder: order.isGoOrder,
    deliveryInfo: convertDeliveryInfoResponse(order.deliveryInfo),
    courierInfo: order.courierInfo ? {
      courierId: order.courierInfo.courierId,
      courierName: order.courierInfo.courierName
    } : null,
    clientInfo: convertClientInfoResponse(order.clientInfo)
  };
};

export const convertOrderInfoDtoItemResponse = (
  item: OrderInfoDtoItemResponse
): OrderInfoDtoItem => {
  return {
    name: item.name,
    sizeName: item.sizeName,
    quantity: item.quantity,
    price: item.price,
    groups: item.groups ? item.groups.map(convertGroupsResponse) : [],
    modifierGroups: item.modifierGroups
      ? item.modifierGroups.map(convertModifierGroupResponse)
      : [],
  };
};

export const convertGroupsResponse = (
  item: GroupsResponse
): OrderInfoDtoItemGroup => {
  return {
    name: item.name,
    items: item.items.map(convertOrderInfoDtoItemResponse)
  };
};
export const convertModifierGroupResponse = (
  item: ModifierGroupResponse
): OrderInfoDtoItemModifierGroup => {
  return {
    items: item.modifiers.map(convertModifierGroupItemResponse),
  };
};

export const convertModifierGroupItemResponse = (
  item: ModifierResponse
): ModifierGroupItem => {
  return {
    name: item.name,
  };
};

export const convertDiscountsResponse = (
  item: DiscountResponse
): OrderDiscount => {
  return {
    name: item.name ? item.name : '',
    type: item.type ? item.type : '',
    discountValue: item.discountValue,
  };
};

export const convertOrderPaymentResponse = (
  item: PaymentResponse
): OrderPayment => {
  return {
    type: item.type,
    isPaid: item.isPaid,
  };
};

export const convertOrderInQueueResponse = (
  orderInQueue: OrderInQueueResponse,
  reflectedOrders: ReflectedOrder[]
): OrderInQueue => {
  return {
    id: orderInQueue.id,
    reflectedSourceOrder: reflectedOrders.find(
      (order) => order.id === orderInQueue.reflectedSourceOrderId
    ),
    reflectedTargetOrder: reflectedOrders.find(
      (order) => order.id === orderInQueue.reflectedTargetOrderId
    ),
    status: orderInQueue.status,
    statusInfo: OrderStatusService.getStatusInfo(orderInQueue.status),
  };
};

export const convertDeliveryInfoResponse = (deliveryInfo: DeliveryInfoResponse | undefined): DeliveryInfo | null => {
  return deliveryInfo ? {
    address: deliveryInfo.address,
    comment: deliveryInfo.comment,
    postCode: deliveryInfo.postCode,
    latitude: deliveryInfo.latitude,
    longitude: deliveryInfo.longitude,
    area: deliveryInfo.area,
    city: deliveryInfo.city,
    country: deliveryInfo.country,
    addressType: deliveryInfo.addressType,
    doorphone: deliveryInfo.doorphone,
    flat: deliveryInfo.flat,
    entrance: deliveryInfo.entrance,
    floor: deliveryInfo.floor,
    houseNumber: deliveryInfo.houseNumber,
    isTakeAway: deliveryInfo.isTakeAway,
    street: deliveryInfo.street
  } : null;
}

export const convertClientInfoResponse = (clientInfo: ClientInfoResponse | undefined): ClientInfo | null => {
  return clientInfo && (clientInfo.name || clientInfo.comment || clientInfo.email || clientInfo.phone) ? {
    name: clientInfo.name && clientInfo.name.length ? clientInfo.name : null,
    comment: clientInfo.comment && clientInfo.comment.length ? clientInfo.comment : null,
    email: clientInfo.email && clientInfo.email.length ? clientInfo.email : null,
    phone: clientInfo.phone && clientInfo.phone.length ? clientInfo.phone : null,
  } : null
}
