import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SnackbarMessage, SnackbarMessageTemplate, SnackbarMessageType } from 'app/models/snackbar.model';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  message$: Subject<SnackbarMessage>;

  constructor() {
    this.message$ = new Subject<SnackbarMessage>();
  }

  public show(text: string, type: SnackbarMessageType, template?: SnackbarMessageTemplate, opts?): void {
    if (!template) {
      template = 'default';
    }
    this.message$.next({text, type, template, opts});
  }

  public success(text: string, template?: SnackbarMessageTemplate, opts?): void {
    if (!template) {
      template = 'default';
    }
    this.message$.next({text, type: 'success', template, opts});
  }

  public info(text: string, template?: SnackbarMessageTemplate, opts?): void {
    if (!template) {
      template = 'default';
    }
    this.message$.next({text, type: 'info', template, opts});
  }

  public error(text: string, template?: SnackbarMessageTemplate, opts?): void {
    if (!template) {
      template = 'default';
    }
    this.message$.next({text, type: 'error', template, opts});
  }
}
