import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngxs/store';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class RouterHelper {
    params: Observable<any>;

    private paramsSource: BehaviorSubject<any>;

    constructor(private store: Store) {
        this.paramsSource = new BehaviorSubject({});
        this.params = this.paramsSource.asObservable();
    }

    setParams(params: Params): void {
        this.paramsSource.next(params);
    }

    /**
     * Add selected organization ID as prefix to URL
     */
    public routerPrefix(origanalUrl: string): string {
        const organizationId = this.store.selectSnapshot<string>(
            OrganizationsState.getSelectedOrganizationId
        );
        if (organizationId) {
            return `/organization/${organizationId}${origanalUrl}`;
        }
        return origanalUrl;
    }
}
