import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent {

  constructor(public dialogRef: MatDialogRef<any>) {
  }

  exit<T>(result?: T & unknown): void {
    this.dialogRef.close(result);
  }

}
