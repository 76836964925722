import { Component, Input } from '@angular/core';
import { OrderPayment } from 'app/models/masterOrder.model';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss']
})
export class OrderPaymentComponent {

  @Input() payments: OrderPayment[];

  public paymentMapper(payment: string): string {
    if (/cash/gi.test(payment)) {
      return 'PAYMENT.CASH';
    } else if (/card/gi.test(payment)) {
      return 'PAYMENT.CARD';
    } else {
      return payment;
    }
  }
}
