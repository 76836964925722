import { Organization } from "app/models/organization.model";
import { createReducer } from '@reduxjs/toolkit';
import * as actions from 'app/state-management/actions/organizations.actions';

export const organizationFeatureKey = 'organization';

export interface OrganizationState {
  organizationId: string;
  organization: Organization;
  pipes: string[];
  selectedOrganizationId: string | null;
  displayDeletedPipesAndConfigurations: boolean;
}

export const initialState: OrganizationState = {
  organizationId: null,
  organization: null,
  pipes: [],
  selectedOrganizationId: null,
  displayDeletedPipesAndConfigurations: false,
};
export const organizationReducer = createReducer<OrganizationState>(initialState, (builder) => {
  builder.addCase(actions.updateCreateOrganization, (state , action) => {
    return {
        ...state,
      organization: action.payload,
      organizationId: action.payload.id
      };
    });

  builder.addCase(actions.clearOrganization, (state) => {
      return {
        ...state,
        selectedOrganizationId: null,
        organization: null
      };
    });

  builder.addCase(actions.switchDisplayDeletedPipesAndConfigurations, (state,action) => {
      return {
        ...state,
        displayDeletedPipesAndConfigurations: null
      };
    });

  builder.addCase(actions.setPipes, (state,action) => {
      return {
        ...state,
        pipes: action.payload
      };
    });

  builder.addCase(actions.getExternalSystemsInfo, (state ) => {
      return {
        ...state,
      };
    });

  builder.addCase(actions.setOrganization, (state , action) => {
    return {
      ...state,
      organizationId: action.payload
    };
  });
});