<div class="pipe" *ngIf="isAggregator">
    <div class="pipe-user">
        <div class="pipe-user-label" [class.pipe-not-active]="!pipeIsActive">
            <div class="pipe-user-labal-header">
                <span>{{this.sourceConfiguration.externalSystemType | externalSystem: this.externalSystems:'name'}}</span>
                <div
                    [matTooltip]="validationMessage"
                    matTooltipPosition="below">
                    <span class="btn-red" *ngIf="status === 'invalid'"></span>
                    <span class="btn-green" *ngIf="status === 'online'"></span>
                    <span class="btn-yellow" *ngIf="status === 'offline'"></span>
                    <span class="btn-grey" *ngIf="status === 'undefined'"></span>
                </div>
            </div>
            <div class="pipe-user-label-comment">{{ pipeTitle }}</div>
            <div class="pipe-user-label-comment">{{ pipeComment }}</div>
        </div>

        <div class="pipe-user-title">
            {{'MENU_HISTORY.REVISION' | translate}}
        </div>

        <div class="pipe-user-created">
            <span>{{ this.revision }}</span>
        </div>

        <div class="pipe-user-title">
          {{'MENU_HISTORY.REVISION_UPDATED_AT' | translate}}
        </div>

        <div class="pipe-user-created" [class.old-revision]="IsOldRevision()">
          <span>{{ this.revision | date:'yyyy-MM-dd HH:mm:ss'}}</span>
        </div>

        <div class="pipe-user-title">
            {{'PIPES.POS_LAST_CHANGES' | translate}}
        </div>

        <div class="pipe-user-created">
            <span>{{ getPosDate() }}</span>
        </div>

        <div class="pipe-user-title">
            {{'MENU_HISTORY.UPLOADED_AT' | translate}}
        </div>

        <div class="pipe-user-created">
            <span>{{ this.uploadedAt | utcToLocal: this.dateFormat }}</span>
        </div>

        <div class="pipe-user-title">
            {{'MENU_HISTORY.PUBLISHED_AT' | translate}}
        </div>

        <div class="pipe-user-created">
            <span>{{ this.publishedAt | utcToLocal: this.dateFormat }}</span>
        </div>

        <div class="pipe-user-toggle">
            <div *ngIf="allowLoadMenu">
                <div *ngIf="updateScheduleMenuIsActive">
                    {{'PIPES.AUTO_SYNC_MENU_ENABLE' | translate}} {{ getPeriod(updateScheduleMenuPeriod) }}
                </div>
            </div>
            <div *ngIf="allowLoadStopList">
                <div *ngIf="updateScheduleStopListIsActive">
                    {{'PIPES.AUTO_SYNC_STOP_LIST_ENABLE' | translate}} {{ getPeriod(updateScheduleStopListPeriod) }}
                </div>
            </div>
        </div>

        <div class="pipe-user-publish" [ngClass]="{'hidden': !allowLoadMenu}">
            <button mat-button [disabled]="isLoadedMenu" (click)="uploadMenu()">{{'PIPES.PUBLISH_MENU' | translate}}</button>
            <mat-spinner *ngIf="isLoadedMenu" [diameter]="30"></mat-spinner>
        </div>
        <div class="pipe-user-turn-store">
            <div class="pipe-user-publish switchOnOff" *ngIf="supportSwitchStatus()">
                <button mat-button (click)="switchOn()">{{'PIPES.TURN_ON_STORE' | translate}}</button>
            </div>
            <div class="pipe-user-publish switchOnOff" *ngIf="supportSwitchStatus()">
                <button mat-button (click)="switchOff()">{{'PIPES.TURN_OFF_STORE' | translate}}</button>
            </div>
        </div>
    </div>
</div>