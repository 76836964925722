import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MappedPipeConfiguration } from 'app/models/pipe.model';
import { Store } from '@ngxs/store';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { APIService } from 'app/services/api.service';
import { ConfigurationType, IExternalSystem } from 'app/models/externalSystem.model';
import { Configuration } from 'app/models/configuration.model';
import { UpdatingObjectType } from 'app/models/updateSchedule.model';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuStorage } from 'app/models/menuStorage.model';
import { TranslateService } from '@ngx-translate/core';
import { getHoursDesc, getHumanizedPastFull, getMinutesDesc } from 'app/helpers/momentHelper';
import { SnackbarService } from 'app/services/snackbar.service';
import { utcToLocalFormat } from 'app/shared/pipes/utc-to-local.pipe';
import { PipeConfigurationDetailed } from "app/models/organizations.models";
import { PipeGroup } from "app/models/pipeGroup.model";

@UntilDestroy()
@Component({
  selector: 'app-pipe-user',
  templateUrl: './pipe-user.component.html',
  styleUrls: ['./pipe-user.component.scss']
})
export class PipeUserComponent implements OnInit {

  @Input() pipe: PipeConfigurationDetailed;
  @Input() mappedPipe: MappedPipeConfiguration;
  @Input() pipeGroup: PipeGroup;
  @Output() updatePipe: EventEmitter<any> = new EventEmitter();

  revision: string;

  isLoadedMenu: boolean;
  updateScheduleMenuIsActive: boolean;
  updateScheduleMenuPeriod: number;
  updateScheduleStopListIsActive: boolean;
  updateScheduleStopListPeriod: number;
  externalSystems: IExternalSystem[];
  sourceSystem: IExternalSystem;
  targetSystem: IExternalSystem;
  latestSuncMenu: MenuStorage;
  latestSuncMenuForPos: MenuStorage = {} as MenuStorage;
  latestSuncMenuForSales: MenuStorage = {} as MenuStorage;
  isAggregator: boolean;
  allowLoadMenu: boolean;
  allowDownloadMenu: boolean;
  allowLoadStopList: boolean;
  currentDate: Date = new Date();
  status: string;
  validationMessage: string;
  revisionPosConfiguration: string = '';
  dateFormat: string = utcToLocalFormat;
  pipeTitle: string;
  pipeComment: string;
  pipeIsDeleted: boolean;
  pipeIsActive: boolean;
  sourceConfiguration: Configuration;
  targetConfiguration: Configuration;
  hasOnePipe: boolean;

  uploadedAt: Date;
  publishedAt: Date;

  constructor(
    private api: APIService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    private store: Store) {
    this.isLoadedMenu = false;
  }

  ngOnInit(): void {
    this.hasOnePipe = !!this.pipe;
    this.refreshIndicators();
    this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);

    if (this.pipe) {
      if (this.pipe.menuStatus) {
        this.revision = this.pipe.menuStatus.revision;
        this.revisionPosConfiguration = this.pipe.menuStatus.revision;
        this.uploadedAt = this.pipe.menuStatus.uploadedAt;
        this.publishedAt = this.pipe.menuStatus.published;
      }
      this.latestSuncMenu = this.pipe.menuStatus;
      this.sourceSystem = this.externalSystems.find(s => s.key == this.pipe.sourceConfiguration.externalSystemType);
      this.targetSystem = this.externalSystems.find(s => s.key == this.pipe.targetConfiguration.externalSystemType);
      this.sourceConfiguration = this.pipe.sourceConfiguration;
      this.targetConfiguration = this.pipe.sourceConfiguration;
      this.pipeComment = this.pipe.comment;
      this.pipeTitle = this.pipe.title;
      this.pipeIsActive = this.pipe.isActive;
      this.pipeIsDeleted = this.pipe.isDeleted;
    } else {
      if (this.mappedPipe.salesPipe.menuStatus) {
        this.revision = this.mappedPipe.posPipe.menuStatus.revision;
        this.revisionPosConfiguration = this.mappedPipe.salesPipe.menuStatus.revision;
      }
      if (this.mappedPipe.posPipe.menuStatus) {
        this.uploadedAt = this.mappedPipe.posPipe.menuStatus.uploadedAt;
        this.publishedAt = this.mappedPipe.posPipe.menuStatus.published;
      }
      this.latestSuncMenuForPos = this.mappedPipe.posPipe.menuStatus;
      this.latestSuncMenuForSales = this.mappedPipe.salesPipe.menuStatus;
      this.sourceConfiguration = this.mappedPipe.posPipe.sourceConfiguration;
      this.targetConfiguration = this.mappedPipe.salesPipe.targetConfiguration;
      this.sourceSystem = this.externalSystems.find(s => s.key == this.sourceConfiguration.externalSystemType);
      this.targetSystem = this.externalSystems.find(s => s.key == this.targetConfiguration.externalSystemType);
      this.pipeComment = `${this.sourceSystem.externalSystemType} => GetPOS => ${this.targetSystem.externalSystemType}`;
      this.pipeTitle = `${this.sourceSystem.externalSystemType} => GetPOS => ${this.targetSystem.externalSystemType}`;
      this.pipeIsActive = this.mappedPipe.posPipe.isActive && this.mappedPipe.salesPipe.isActive;
      this.pipeIsDeleted = this.mappedPipe.salesPipe.isDeleted || this.mappedPipe.salesPipe.isDeleted;
    }

    this.isAggregator = this.sourceSystem?.params.configurationType === ConfigurationType.Sales;
    this.allowLoadMenu = this.pipeIsActive && !this.pipeIsDeleted
      && this.sourceSystem?.params.configurationType === ConfigurationType.Sales
      && this.targetSystem?.params.isSupportMenuExport;
    this.allowDownloadMenu = this.pipeIsActive && !this.pipeIsDeleted &&
      this.sourceSystem?.params.configurationType === ConfigurationType.Sales
      && this.targetSystem?.params.configurationType === ConfigurationType.POS;

    this.allowLoadStopList = this.pipeIsActive && !this.pipeIsDeleted &&
      (this.sourceSystem?.params.isSupportStopListImport
        && this.targetSystem?.params.isSupportStopListExport);
    this.calculateStatus();

  }

  calculateStatus() {
    // all ok by default
    this.status = 'online';
    this.validationMessage = 'online';

    const pipe = this.hasOnePipe ?
      this.pipe :
      this.mappedPipe.posPipe;

    if (pipe.sourceConfiguration.metadata.validationResult) {
      if (pipe.sourceConfiguration.metadata.validationResult.isValid) {
        // if support live status
        switch (pipe.sourceConfiguration.metadata.liveStatus) {
          case 1:
            this.status = 'online';
            this.validationMessage = 'online';
            break;
          case 2:
            this.status = 'offline';
            this.validationMessage = 'offline';
            return;
        }
      } else {
        this.status = 'invalid';
        this.validationMessage = pipe.sourceConfiguration.metadata.validationResult.messages.join('\n');
        return;
      }
    }

    if (pipe.targetConfiguration.metadata.validationResult) {
      if (pipe.targetConfiguration.metadata.validationResult.isValid) {
        // if support live status
        switch (pipe.targetConfiguration.metadata.liveStatus) {
          case 1:
            this.status = 'online';
            this.validationMessage = 'online';
            break;
          case 2:
            this.status = 'offline';
            this.validationMessage = 'offline';
            break;
        }
      } else {
        this.status = 'invalid';
        this.validationMessage = pipe.targetConfiguration.metadata.validationResult.messages.join('\n');
      }
    }
  }

  uploadMenu(): void {
    const confirmMessage = this.translate.instant('PIPES.UPLOAD_MENU_CONFIRM');
    const successMessage = this.translate.instant('PIPES.UPLOAD_MENU_SUCCESS');
    const errorMessage = this.translate.instant('PIPES.UPLOAD_MENU_ERROR');
    if (!this.isLoadedMenu && confirm(confirmMessage)) {
      this.isLoadedMenu = true;

      if (this.hasOnePipe) {
        this.api.uploadMenu(this.pipe.id)
          .subscribe({
            next: result => {
              if (result.error == null) {
                this.updatePipe.emit(this.pipe.id);
                this.snackbar.success(successMessage);
              } else {
                this.snackbar.error(errorMessage);
              }
            },
            error: error => {
              this.snackbar.error(errorMessage);
            },
            complete: () => {
              this.isLoadedMenu = false;
            }
          });
      } else {
        this.api.uploadMenu(this.mappedPipe.salesPipe.id)
          .subscribe({
            next: result => {
              if (result.error == null) {
                this.updatePipe.emit(this.mappedPipe.salesPipe.id);

                this.api.uploadMenu(this.mappedPipe.posPipe.id)
                  .subscribe({
                    next: result => {
                      if (result.error == null) {
                        this.updatePipe.emit(this.mappedPipe.posPipe.id);
                        this.snackbar.success(successMessage);
                      } else {
                        this.snackbar.error(errorMessage);
                      }
                    },
                    error: error => {
                      this.snackbar.error(errorMessage);
                    },
                    complete: () => {
                      this.isLoadedMenu = false;
                    }
                  });
              } else {
                this.snackbar.error(errorMessage);
              }
            },
            error: error => {
              this.snackbar.error(errorMessage);
            },
            complete: () => {
              this.isLoadedMenu = false;
            }
          });
      }
    }
  }

  supportSwitchStatus(): boolean {
    return this.pipeIsActive && !this.pipeIsDeleted && this.sourceSystem.params.isSupportSwitchStoreStatus;
  }

  switchOn(): void {
    const message = this.translate.instant('CONFIGURATION.SWITCH_ON_STORE_CONFIRM');

    const aggregatorConfigurationId = this.hasOnePipe ?
      this.pipe.sourceConfiguration.id :
      this.mappedPipe.posPipe.sourceConfiguration.id;

    if (confirm(message)) {
      this.switchStatusConfiguration(aggregatorConfigurationId, true, '');
    }
  }

  switchOff(): void {
    const message = this.translate.instant('CONFIGURATION.SWITCH_OFF_STORE_CONFIRM');

    const aggregatorConfigurationId = this.hasOnePipe ?
      this.pipe.sourceConfiguration.id :
      this.mappedPipe.posPipe.sourceConfiguration.id;

    if (confirm(message)) {
      this.switchStatusConfiguration(aggregatorConfigurationId, false, 'Manually switch off');
    }
  }

  switchStatusConfiguration(configurationId: string, switchOn: boolean, reason: string) {
    this.api.switchStatusConfiguration(configurationId, switchOn, reason)
      .pipe()
      .subscribe({
        next: (result) => {
          if (result) {
            if (result.hasOwnProperty('error')) {
              const error = result.error;
              this.snackbar.error(error.message);
            } else {
              this.snackbar.success(this.translate.instant(switchOn ? 'CONFIGURATION.STORE_TURNED_ON' : 'CONFIGURATION.STORE_TURNED_OFF'));
            }
          }
        },
        error: (error) => {
          this.snackbar.error(JSON.stringify(error));
        }
      });
  }

  getPosDate(): string {
    const posDate = new Date(parseInt(this.revisionPosConfiguration));
    const huminizePeriod = getHumanizedPastFull(posDate, this.currentDate, this.translate.currentLang);

    return this.translate.instant('PIPES.WAS_UPDATE_PERIOD', {period: huminizePeriod});
  }

  getPeriod(period: number): string {
    let result = '';
    if (period >= 60) {
      const hours = Math.floor(period / 60);
      const minutes = period % 60;
      const huminizePeriod = `${hours} ${getHoursDesc(hours, this.translate.currentLang)} ${minutes} ${getMinutesDesc(minutes, this.translate.currentLang)}`;
      result = this.translate.instant('PIPES.UPDATE_PERIOD_EVERY', {period: huminizePeriod});
    } else {
      const minutes = period;
      const huminizePeriod = `${minutes} ${getMinutesDesc(minutes, this.translate.currentLang)}`;
      result = this.translate.instant('PIPES.UPDATE_PERIOD_EVERY', {period: huminizePeriod});
    }

    return result;
  }

  IsOldRevision() {
    const latestSuncMenu = this.hasOnePipe ?
      this.latestSuncMenu :
      this.latestSuncMenuForSales;
    return latestSuncMenu?.revision && latestSuncMenu.revision !== this.revisionPosConfiguration;
  }

  private refreshIndicators = () => {
    let pipe = this.hasOnePipe ?
      this.pipe :
      this.mappedPipe.posPipe;

    const updateScheduleMenu = pipe
      .updateSchedules?.find(us => us.updatingObjectType === UpdatingObjectType.Menu && us.isActive && !us.isDeleted);
    this.updateScheduleMenuIsActive = !!updateScheduleMenu;
    this.updateScheduleMenuPeriod = updateScheduleMenu?.intervalMinutes;
    const updateScheduleStopList = pipe
      .updateSchedules?.find(us => us.updatingObjectType === UpdatingObjectType.StopLists && us.isActive && !us.isDeleted);
    this.updateScheduleStopListIsActive = !!updateScheduleStopList;
    this.updateScheduleStopListPeriod = updateScheduleStopList?.intervalMinutes;
  };
}
