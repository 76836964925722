import { DisplayedGroup, Group, ProductInGroup } from 'app/models/menu.model';

export const menuHelper = class MenuHelperClass {
  static sortProductInGroupByOrder(p1: ProductInGroup, p2: ProductInGroup): number {
    if (p1.order > p2.order) {
      return 1;
    }
    if (p1.order < p2.order) {
      return -1;
    }
    return 0;
  }

  static sortProductInGroupById(p1: ProductInGroup, p2: ProductInGroup): number {
    if (p1.externalProductId > p2.externalProductId) {
      return 1;
    }
    if (p1.externalProductId < p2.externalProductId) {
      return -1;
    }
    return 0;
  }

  static sortProductByOrderAndId(productsInGroup: ProductInGroup[]): ProductInGroup[] {
    const result = [...productsInGroup]
      .sort(menuHelper.sortProductInGroupById)
      .sort(menuHelper.sortProductInGroupByOrder);
    return result;
  }

  static sortGroupByOrder(g1: DisplayedGroup, g2: DisplayedGroup): number {
    return g1.order - g2.order;
  }

  static sortGroupById(g1: Group, g2: Group): number {
    if (g1.externalId > g2.externalId) {
      return 1;
    }
    if (g1.externalId < g2.externalId) {
      return -1;
    }
    return 0;
  }

  static sortGroupsById(groups: IterableIterator<Group>): Group[] {
    return [...groups].sort(menuHelper.sortGroupById);
  }

  static sortGroupsByOrderAndId(groups: IterableIterator<DisplayedGroup>): DisplayedGroup[] {
    return [...groups]
      .sort(menuHelper.sortGroupById)
      .sort(menuHelper.sortGroupByOrder);
  }

  static equalProductInGroups(productsInGroup1: ProductInGroup[], productsInGroup2: ProductInGroup[]): boolean {
    return [...productsInGroup1].sort(menuHelper.sortProductInGroupByOrder).map(p => p.externalProductId).toString()
      === [...productsInGroup2].sort(menuHelper.sortProductInGroupByOrder).map(p => p.externalProductId).toString();
  }

  static equalGroups(group1: Group[], group2: Group[]): boolean {
    return group1.map(p => p.externalId).toString()
      === group2.map(p => p.externalId).toString();
  }

  static CheckAssortmentAndChangeGroup(group: DisplayedGroup, originalGroup: Group){
    let assortmentHasChanged = false;

    let productsInGroup = group.groupStructure.productsInGroup;
    let originalProductsInGroup = originalGroup.groupStructure.productsInGroup;

    let index = 0;
    let lastNativeIndex = 0;
    let lastForeignIndex = 0;
    let nativeProductsInGroup = [...originalProductsInGroup].filter(op => productsInGroup.some(p => op.externalProductId == p.externalProductId));

    for (let productInGroup of productsInGroup) {
      if (nativeProductsInGroup.some(op => op.externalProductId == productInGroup.externalProductId)){
        lastNativeIndex = ++index;
      }
      else {
        lastForeignIndex = ++index;
        assortmentHasChanged = true;
      }
    }

    let hasReOrderingProductInGroup =
      !assortmentHasChanged && !menuHelper.equalProductInGroups(productsInGroup, nativeProductsInGroup)
      || assortmentHasChanged && (lastForeignIndex < lastNativeIndex)
      || assortmentHasChanged && (lastForeignIndex > lastNativeIndex) && !menuHelper.equalProductInGroups(productsInGroup.slice(0, lastNativeIndex), nativeProductsInGroup)
    ;

    group.assortmentHasChanged = assortmentHasChanged;
    group.hasReOrderingProductInGroup = hasReOrderingProductInGroup;
  }
}

