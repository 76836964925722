import { TranslateService } from '@ngx-translate/core';
import { from, Observable, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';
import { Store } from '@ngrx/store';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { selectLanguage } from "app/reducers/context.selectors";

const initLanguage = (translateService: TranslateService, dateAdapter: MomentDateAdapter, lang: string): Promise<void> => {
  // язык подгрузим по-любому
  if (lang != 'ru') {
    lang = 'en';
  }
  translateService.use(lang);
  let promise = import(
    // locales
    // https://github.com/angular/angular/issues/20487
    /* webpackInclude: /(en|ru)\.mjs$/ */
    `/node_modules/@angular/common/locales/${lang}`
    );
  return promise.then((module) => {
    registerLocaleData(module.default);
    switch (lang) {
      case 'ru':
        dateAdapter.setLocale('ru-RU');
        break;
      // case 'en':
      default:
        dateAdapter.setLocale('en-GB');
        break;
    }
  });
};
export const appInitializer = (
  store: Store,
  translateService: TranslateService,
  dateAdapter: MomentDateAdapter
) => {
  store.select(selectLanguage).pipe().subscribe(lang => {
    initLanguage(translateService, dateAdapter, lang).then(() => {
    });
  });

  return (): Observable<string> => {
    return store.select(selectLanguage).pipe(
      take(1),
      switchMap(language => {
        return from(initLanguage(translateService, dateAdapter, language)).pipe(
          map(
            () => {
              return language;
            }
          )
        );
      })
    );
  };
};
