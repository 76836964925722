import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Select, Store as ngsxStore } from '@ngxs/store';
import { Organization } from 'app/models/organization.model';
import { GetExternalSystemsInfo, InitOrganizations } from 'app/state-management/actions/organizations.actions';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { combineLatest, map, Observable, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsResolverService implements Resolve<Observable<Organization[] | null>> {

  @Select(OrganizationsState.getBranches) organizations$: Observable<Organization[]>;
  @Select(OrganizationsState.getSelectedOrganizationId) selectedOrganizationId$: Observable<string>;

  constructor(
    private ngsxStore: ngsxStore,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Organization[] | null> {
    const x1 = this.ngsxStore.dispatch(new GetExternalSystemsInfo());

    // Инициализируем список организаций
    const x2 = this.organizations$
      .pipe(
        take(1),
        map(organizations => organizations.filter(organization => organization.isActive)),
        switchMap(organizations => {
          if (!organizations.length) {
            return this.ngsxStore.dispatch(InitOrganizations());
          } else {
            return of(organizations);
          }
        })
      );

    return combineLatest([x1, x2])
      .pipe(switchMap(() => x2));
  }
}
