import { createReducer } from '@reduxjs/toolkit';
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import * as posActions from "app/reducers/pos.actions";

export interface PosOrganizationModel {
  id: string;
  name: string;
  importFromPos: boolean;
  apiKey: string;
  orderCreateWebhookUrl: string,
  orderStatusUpdateWebhookUrl: string,
  stopListUpdateWebhookUrl: string
}

export const posFeatureKey = 'pos';

export interface PosState extends EntityState<PosOrganizationModel> {
}

export const adapter = createEntityAdapter<PosOrganizationModel>();

export const initialState: PosState = adapter.getInitialState();

export const posReducer = createReducer<PosState>(
  initialState,
  (builder) => {

    builder.addCase(posActions.loadInfo, (state, action) => {
      return adapter.removeAll(state)
    })

    builder.addCase(posActions.setInfo, (state, action) => {
      return adapter.setAll(action.payload, state)
    })

  }
);

// get the selectors
const {
  selectEntities,
} = adapter.getSelectors();

// select the dictionary of user entities
export const selectPosEntities = selectEntities;
