import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { DetailedOrderInQueue, OrderInQueue } from 'app/models/orderInQueue.model';
import { SetCurrentOrder } from 'app/state-management/actions/orders.actions';
import { OrdersState } from 'app/state-management/states/orders.states';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentOrderResolverService implements Resolve<DetailedOrderInQueue | null> {

  @Select(OrdersState.orders) orders$: Observable<OrderInQueue[]>;

  constructor(
    public store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<DetailedOrderInQueue | null> {
    const orderId: string = route.params.id;
    return this.store.dispatch(new SetCurrentOrder(orderId ?? null))
      .pipe(
        take(1),
        map(setOrderRes => {
          return setOrderRes.orders.currentOrder;
        })
      );
  }
}
