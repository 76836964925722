import {Component, Inject, OnInit} from '@angular/core';
import {Organization} from 'app/models/organization.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {Select} from '@ngxs/store';
import {OrganizationsState} from 'app/state-management/states/organizations.states';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BaseModalComponent} from 'app/shared/base-modal/base-modal.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {MatCheckboxChange} from "@angular/material/checkbox";

export interface FlatOrganizationNode {
  expandable: boolean;
  name: string;
  id: string;
  level: number;
}

export interface SelectOrganizationsModalComponentData {
  ids: string[];
  selectType: string;
  filteredId?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-select-organizations-modal',
  templateUrl: './select-organizations-modal.component.html',
  styleUrls: ['./select-organizations-modal.component.scss']
})
export class SelectOrganizationsModalComponent extends BaseModalComponent implements OnInit {
  private selectTypeRadio = 'radio_button';

  @Select(OrganizationsState.getOrganizations) organizations$: Observable<Organization[]>;
  treeControl = new FlatTreeControl<FlatOrganizationNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new MatTreeFlattener(
    transformToFlatNode,
    node => node.level,
    node => node.expandable,
    node => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  checklistSelection = new SelectionModel<FlatOrganizationNode>(true);

  constructor(
    public dialogRef: MatDialogRef<SelectOrganizationsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectOrganizationsModalComponentData
  ) {
    super(dialogRef);
  }

  getLevel = (node: FlatOrganizationNode) => node.level;

  isExpandable = (node: FlatOrganizationNode) => node.expandable;

  getChildren = (node: Organization): Organization[] => node.children;

  hasChild = (_: number, nodeData: FlatOrganizationNode) => nodeData.expandable;

  hasNoContent = (_: number, nodeData: FlatOrganizationNode) => nodeData.name === '';

  ngOnInit(): void {
    this.organizations$
      .pipe(untilDestroyed(this))
      .subscribe((organizations: Organization[]) => {
        this.dataSource.data = [...organizations];
        if (this.data?.filteredId) {
          this.dataSource.data = organizations.filter(o => o.id === this.data.filteredId || o.parentId === this.data.filteredId);
        }
        if (this.data?.ids.length) {
          this.data.ids.forEach(id => {
            const org = this.treeControl.dataNodes.find(x => x.id === id);
            if (org) {
              this.checklistSelection.select(org);
            }
          });
        }
      });
    this.dialogRef.backdropClick()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.save();
      });
  }

  descendantsAllSelected(node: FlatOrganizationNode): boolean {
    if (this.data.selectType === this.selectTypeRadio) {
      return this.checklistSelection.isSelected(node);
    } else {
      const descendants = this.treeControl.getDescendants(node);
      return descendants.length > 0 &&
        descendants.every(child => {
          return this.checklistSelection.isSelected(child);
        });
    }
  }

  descendantsPartiallySelected(node: FlatOrganizationNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  itemSelectionToggle(node: FlatOrganizationNode): void {
    if (this.data.selectType === this.selectTypeRadio) {
      this.checklistSelection.selected.forEach(sel => this.checklistSelection.deselect(sel));
      this.checklistSelection.select(node);
    } else {
      this.checklistSelection.toggle(node);
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelection.isSelected(node)
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);

      descendants.forEach(child => this.checklistSelection.isSelected(child));
      this.checkAllParentsSelection(node);
    }
  }

  leafItemSelectionToggle(node: FlatOrganizationNode): void {
    if (this.data.selectType === this.selectTypeRadio) {
      this.checklistSelection.selected.forEach(sel => this.checklistSelection.deselect(sel));
      this.checklistSelection.select(node);
    } else {
      this.checklistSelection.toggle(node);
      this.checkAllParentsSelection(node);
    }
  }

  checkAllParentsSelection(node: FlatOrganizationNode): void {
    let parent: FlatOrganizationNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: FlatOrganizationNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: FlatOrganizationNode): FlatOrganizationNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

  save(): void {
    this.dialogRef.close({
      ids: this.checklistSelection.selected.map(x => x.id),
    } as SelectOrganizationsModalComponentData);
  }

  private transformToNested = (organizations: Organization[]) => {
    const parents = organizations.filter(o => o.parentId === null);
    parents.forEach(parent => {
      parent.children = organizations.filter(o => o.parentId === parent.id) ?? [];
    });
    return parents.sort((a, b) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);
  };

  onSelectAllChange($event: MatCheckboxChange) {
    if ($event.checked){
      this.checklistSelection.setSelection(...this.treeControl.dataNodes)
    } else {
      this.checklistSelection.deselect(...this.treeControl.dataNodes)
    }
  }

  isAllSelected() {
    return this.treeControl.dataNodes.every(org => this.checklistSelection.isSelected(org));
  }
}

const transformToFlatNode = (organization: Organization, level: number): FlatOrganizationNode => {
  return {
    expandable: !!organization.children && organization.children.length > 0,
    level,
    id: organization.id,
    name: organization.name,
  };
};
