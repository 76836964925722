import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from 'app/services/api.service';
import { ExceptionCode, IRpcError } from 'app/models/rpcResponse.model';

@Injectable({
  providedIn: 'root'
})
export class RpcErrorInterceptorService implements HttpInterceptor {

  rpcError$: Subject<IRpcError>;

  constructor(private api: APIService) {
    this.rpcError$ = new Subject<IRpcError>();
    this.rpcError$.subscribe(res => {
      if (!(res.code in ExceptionCode)) {
        this.api.rpcError$.next(res);
      } else {
        console.log('RpcError:', res);
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          const body = event.body;

          if (body.hasOwnProperty('jsonrpc') && body.jsonrpc === '2.0') {
            if (body.hasOwnProperty('error') && body.error.code !== -32600) {
              console.log('RPC-ERROR-INTERCEPTOR', JSON.stringify(event));
              this.rpcError$.next(body.error);
            }
          }

        }
        return event;
      })
    );
  }
}
