export const AppHelper = class AppHelperClass
{
  static openList(url: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = url;
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.parentNode.removeChild(downloadLink);
  }
};
