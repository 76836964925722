import { Pipe, PipeTransform } from '@angular/core';
import { IExternalSystem } from 'app/models/externalSystem.model';

@Pipe({
  name: 'externalSystemTypeFilter'
})
export class ExternalSystemTypeFilterPipe implements PipeTransform {
  transform(values: any[], type: string, externalSystems: IExternalSystem[]): any[] {
    return values.filter(value => {
      const findedItem = externalSystems.find(item => item.key === value.externalSystemType);
      return findedItem && findedItem.params?.configurationType.toString() === type;
    });
  }
}
