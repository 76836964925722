import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Store as ngrxStore } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from 'app/models/organization.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailedOrderInQueue } from 'app/models/orderInQueue.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { RoleMembership, selectRole } from "app/reducers/context.selectors";

@UntilDestroy()
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OrderComponent implements OnInit {
  public currentOrder: DetailedOrderInQueue | null = null;
  public roleMembership: RoleMembership;

  id: string;

  currentOrderOrganization: Organization;

  @Select(OrganizationsState.getSelectedOrganization) organization$: Observable<Organization>;

  constructor(
    private store: Store,
    private ngrxStore: ngrxStore,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.ngrxStore.select(selectRole)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(roleMembership => {
        this.roleMembership = roleMembership
      });

    this.activatedRoute.data.pipe(
      untilDestroyed(this)
    ).subscribe(value => {
      this.currentOrder = value.currentOrder;
    });

    this.organization$
      .pipe(untilDestroyed(this))
      .subscribe(organization => this.currentOrderOrganization = organization);
  }

  goBack(): void {
    this.router.navigate(['app']);
  }
}
