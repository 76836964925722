import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OrderInQueue } from 'app/models/orderInQueue.model';
import { OrdersState } from 'app/state-management/states/orders.states';
import { combineLatest, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-order-list-redirect',
  templateUrl: './order-list-redirect.component.html',
  styleUrls: ['./order-list-redirect.component.scss'],
})
export class OrderListRedirectComponent implements OnInit {

  @Select(OrdersState.ordersList) ordersList$: Observable<OrderInQueue[]>;

  constructor(
    public store: Store,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    combineLatest([this.ordersList$, this.activatedRoute.params])
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(([ordersList, params]) => {
        if (ordersList.length) {
          let foundOrder: OrderInQueue;
          if (params.id) {
            foundOrder = ordersList.find(o => o.id === params.id);
          }
          if (!foundOrder) {
            let orderToRedirect: OrderInQueue;
            if (ordersList.length > 0) {
              orderToRedirect = ordersList[0];
            }
            if (orderToRedirect) {
              this.router.navigate([orderToRedirect.id], {
                relativeTo: this.activatedRoute
              });
            }
          }
        } else {
          this.router.navigate(['no-orders'], {
            relativeTo: this.activatedRoute
          });
        }
      });
  }
}
