import {PipeConfiguration} from './pipe.model';

export interface UpdateSchedule {
    id: string;
    intervalMinutes: number;
    pipeConfigurationId: string;
    pipeConfiguration: PipeConfiguration | null;
    lastRunAt: Date | null;
    updatingObjectType: UpdatingObjectType;
    isActive: boolean;
    isDeleted: boolean;
}

export enum UpdatingObjectType {
    Menu = 1,
    StopLists = 2
}

export interface CreateUpdateScheduleRequest {
    intervalMinutes: number;
    pipeConfigurationId: string;
    updatingObjectType: UpdatingObjectType;
    isActive: boolean;
    isDeleted: boolean;
}

export interface EditUpdateScheduleRequest {
    id: string;
    intervalMinutes: number;
    pipeConfigurationId: string;
    updatingObjectType: UpdatingObjectType;
    isActive: boolean;
    isDeleted: boolean;
}
