import { IExternalSystem } from "app/models/externalSystem.model";

export const ExternalSystemHelper = class ExternalSystemHelper
{
  static getExternalSystemTypeByKey(externalSystems: IExternalSystem[], keyExternalSystem: number)
  {
    return ExternalSystemHelper.getExternalSystemByKey(externalSystems, keyExternalSystem).externalSystemType;
  }

  static getExternalSystemByKey(externalSystems: IExternalSystem[], keyExternalSystem: number)
  {
    return externalSystems.find(x => x.key === keyExternalSystem);
  }
};
