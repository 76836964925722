import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { GMapMarker } from 'app/models/map.models';
import { environment } from 'environments/environment';
import { zip } from 'lodash';

declare var google: any;

type typeCoords = { lat: number, lng: number };

type typeMarker = {
  coords: typeCoords,
  imgs: string[],
  id: string | number,
  price: string | number,
};

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss']
})
export class GmapComponent implements AfterViewInit {
  @Output() infoWindowClick = new EventEmitter();
  apiKey = environment.gMapApiKey;
  map: google.maps.Map;

  destMarkers: google.maps.Marker[];
  courierMarkers: google.maps.Marker[];

  deliveryPersonMarker: google.maps.Marker;
  lastDeliveryPosition: google.maps.LatLng | google.maps.LatLngLiteral;

  borderPolyline: google.maps.Polyline;
  borderPolyline2: google.maps.Polyline;
  toDestinationPolyline: google.maps.Polyline;
  toCourierPolyline: google.maps.Polyline;

  directionsRenderer: google.maps.DirectionsRenderer;
  directionsService: google.maps.DirectionsService;

  infoWindowsHTML: Element[];
  @ViewChild('gmap') gmap: ElementRef<HTMLDivElement>;
  @Input() isMapOpen: boolean;
  @Input() currentCourierMarkers: typeMarker[];
  @Input() destinationCoords: typeCoords[];
  @Input() startCourierCoords: typeCoords[];
  @Input() imgsMock: string[];
  private readonly defaultMapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    scrollwheel: true,
    disableDefaultUI: true,
    zoom: 15,
    center: {lat: 55.680063, lng: 37.533446},
  };

  constructor() {
    this.destMarkers = new Array<google.maps.Marker>();
    this.courierMarkers = new Array<google.maps.Marker>();
  }


  ngAfterViewInit(): void {
    this.initMap();

  }

  initMap(): any {
    const loader = new Loader({
      apiKey: this.apiKey,
      version: 'weekly',
    });

    loader.load().then(() => {

      this.map = new google.maps.Map(this.gmap.nativeElement, this.defaultMapOptions);

      zip(this.currentCourierMarkers, this.destinationCoords).forEach(([courier, destination]) => {
        this.createMarker(courier.coords, destination);
      });

      this.paintMarkers();
      this.createPolylines();

    });
  }

  createMarker(courierCoords: typeCoords, destCoords: typeCoords): void {

    this.courierMarkers.push(this.addMarker(courierCoords, this.createMarkerIcon('COURIER')));

    this.destMarkers.push(this.addMarker(destCoords, this.createMarkerIcon('DEST')));

  }

  paintMarkers(): void {
    this.courierMarkers.forEach((marker, i) => {
      marker.setMap(this.map);
      this.openInfoWindow(
        marker,
        this.createImgs(this.currentCourierMarkers[i].imgs),
        this.currentCourierMarkers[i].id,
        this.currentCourierMarkers[i].price
      );
    });
    this.destMarkers.forEach((marker) => {
      marker.setMap(this.map);
    });
    this.afterInfoWindowInit();
  }

  createImgs(imgsUrl: string[]): string {
    let imgs = '';
    for (const imgUrl of imgsUrl) {
      imgs += `
      <div >
            <img
              style="
                width: 24px;
                height: 24px;
                border-radius: 50%;"
              src="${imgUrl}"
              alt="">
      </div>
      `;
    }
    return imgs;
  }

  openInfoWindow(marker: google.maps.Marker, imgsHTML: string, id: number | string, price: number | string): void {
    const content = `
      <div  class="images"
            style="
              display: flex;
              align-items: center;"
      >
        ${imgsHTML}
      </div>
      <div style="
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
      ">
        <div style="
              font-size: 14px;
              color: #000;
              opacity: 0.5;
        ">
          ${id}
        </div>
        <div style="
              font-weight: bold;
              font-size: 14px;
              color: #1d1d1d;
        ">
          ${price}
        </div>
      </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content,
      maxWidth: 140,
      minWidth: 140,
    });
    infowindow.open({
      anchor: marker,
      map: this.map,
      shouldFocus: false,
    });
  }

  afterInfoWindowInit(): void {
    setTimeout(() => {

      const buttons = document.querySelectorAll('.gm-ui-hover-effect');
      buttons.forEach((button) => {
        button.remove();
      });

      const images = document.querySelectorAll('.images');
      images.forEach((imgs: HTMLElement) => {
        const imgsWrappers = Array.from(imgs.children);
        const imgsWrappersLength = imgsWrappers.length;

        imgsWrappers.forEach((imgWrapper: HTMLElement, i) => {
          imgWrapper.style.minWidth = '0';
          imgWrapper.style.width = '24px';
          imgWrapper.style.height = '24px';

          if (i === imgsWrappersLength - 1) {
            imgWrapper.style.minWidth = '24px';
          }
        });
      });

      const infoWindows = document.querySelectorAll('.gm-style-iw.gm-style-iw-c');
      this.infoWindowsHTML = Array.from(infoWindows);

      this.infoWindowsHTML.forEach((infoWindow: HTMLElement, i) => {
        infoWindow.style.cursor = 'pointer';

        infoWindow.addEventListener('click', () => {
          this.infoWindowClick.emit(i);
        });
      });

    }, 500);
  }

  infoWindowToGreen(index: string | number): void {
    this.infoWindowsHTML.forEach((infoWindow: HTMLElement, i) => {
      if (i === index) {
        infoWindow.style.opacity = '1';
        infoWindow.style.borderRadius = '8px';
        infoWindow.style.border = 'solid 4px #1fc549';
      } else {
        infoWindow.style.opacity = '0.5';
        infoWindow.style.border = 'none';
      }
    });
  }

  infoWindowsToDefault(): void {
    this.infoWindowsHTML.forEach((infoWindow: HTMLElement) => {
      infoWindow.style.borderRadius = '8px';
      infoWindow.style.border = 'none';
      infoWindow.style.opacity = '1';
    });
  }

  createPolylines(): void {
    this.borderPolyline = new google.maps.Polyline({
      path: [],
      strokeColor: 'black',
      strokeOpacity: 1.0,
      strokeWeight: 7,
    });

    this.borderPolyline2 = new google.maps.Polyline({
      path: [],
      strokeColor: 'black',
      strokeOpacity: 1.0,
      strokeWeight: 7,
    });

    this.toCourierPolyline = new google.maps.Polyline({
      path: [],
      strokeColor: 'white',
      strokeOpacity: 1.0,
      strokeWeight: 5
    });

    this.toDestinationPolyline = new google.maps.Polyline({
      path: [],
      strokeColor: '#1fc549',
      strokeOpacity: 1.0,
      strokeWeight: 5
    });
  }

  setupRoute(
    index: number | string
  ): void {
    this.borderPolyline.setMap(null);
    this.borderPolyline2.setMap(null);
    this.toCourierPolyline.setMap(null);
    this.toDestinationPolyline.setMap(null);
    this.createPolylines();
    this.renderRoute(
      this.map,
      this.startCourierCoords[index],
      this.currentCourierMarkers[index].coords,
      this.destinationCoords[index],
      this.borderPolyline,
      this.borderPolyline2,
    );

  }

  courierMarkerToGreen(index: number | string): void {
    this.courierMarkers.forEach((marker, i) => {
      if (i === index) {
        marker.setOptions({
          icon: this.createMarkerIcon('COURIER_GREEN')
        });
      } else {
        marker.setOptions({
          icon: this.createMarkerIcon('COURIER')
        });
      }
    });
  }

  renderRoute(
    gmap: google.maps.Map,
    startCourierCoords: google.maps.LatLng | google.maps.LatLngLiteral,
    currentCourierCoords: google.maps.LatLng | google.maps.LatLngLiteral,
    destinationCoords: google.maps.LatLng | google.maps.LatLngLiteral,
    borderPolyline: google.maps.Polyline,
    borderPolyline2: google.maps.Polyline,
    travelMode: google.maps.TravelMode = google.maps.TravelMode.DRIVING,
  ): void {
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      suppressPolylines: true
    });
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer.setMap(this.map);

    for (let i = 0; i < 2; i++) {
      switch (i) {
        case 0:
          this.directionsService.route({
              origin: startCourierCoords,
              destination: currentCourierCoords,
              travelMode
            },
            (response, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                this.directionsRenderer.setDirections(response);
                this.setRoutePolylines(response, borderPolyline, this.toCourierPolyline);
              } else {
                console.log('Directions request failed due to ' + status);
              }
            });
          break;

        case 1:
          this.directionsService.route({
              origin: currentCourierCoords,
              destination: destinationCoords,
              travelMode
            },
            (response, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                this.directionsRenderer.setDirections(response);
                this.setRoutePolylines(response, borderPolyline2, this.toDestinationPolyline);
              } else {
                console.log('Directions request failed due to ' + status);
              }
            });
          break;
      }
    }
    this.borderPolyline.setMap(this.map);
    this.borderPolyline2.setMap(this.map);
    this.toCourierPolyline.setMap(this.map);
    this.toDestinationPolyline.setMap(this.map);
  }

  addMarker(
    position: google.maps.LatLng | google.maps.LatLngLiteral,
    icon: string | google.maps.Symbol,
    gmap?: google.maps.Map): google.maps.Marker {
    return new google.maps.Marker({
      map: gmap ? gmap : null,
      position,
      icon
    });
  }

  createMarkerIcon(IconType: GMapMarker): string | google.maps.Symbol {
    let icon;
    switch (IconType) {
      case 'COURIER':
        icon = '../../../../assets/icons/gmap/markerCourier.svg';
        break;
      case 'COURIER_GREEN':
        icon = '../../../../assets/icons/gmap/markerCourierGreen.svg';
        break;
      case 'DEST':
        icon = '../../../../assets/icons/gmap/markerDestination.svg';
        break;
    }
    return icon;
  }

  setRoutePolylines(
    response: google.maps.DirectionsResult,
    borderPolyline: google.maps.Polyline,
    routePolyline: google.maps.Polyline): void {
    const legs = response.routes[0].legs;

    legs.forEach((leg) => {
      leg.steps.forEach(step => {
        step.path.forEach(nextSegment => {
          borderPolyline.getPath().push(nextSegment);
          routePolyline.getPath().push(nextSegment);
        });
      });
    });
  }

}
