import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Store as ngrxStore } from '@ngrx/store';
import { Organization } from 'app/models/organization.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatMenuTrigger } from '@angular/material/menu';
import { setLanguage, signOutUser } from 'app/reducers/context.actions';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationUser } from 'app/models/user.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleMembership, selectLoginData, selectRole } from "app/reducers/context.selectors";
import {environment} from "environments/environment";

@UntilDestroy()
@Component({
  selector: 'app-internal',
  templateUrl: './internal.component.html',
  styleUrls: ['./internal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '240px'
      })),
      state('closed', style({
        width: '72px'
      })),
      state('openpanel', style({
        width: 'calc(100%-240px)'
      })),
      state('closedpanel', style({
        width: 'calc(100% - 72px)'
      })),
      transition('open => closed', [
        animate('0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)')
      ]),
      transition('closed => open', [
        animate('0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)')
      ]),
      transition('openpanel => closedpanel', [
        animate('0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)')
      ]),
      transition('closedpanel => openpanel', [
        animate('0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)')
      ]),
    ]),
  ]
})
export class InternalComponent implements OnInit {

  user: ApplicationUser | null = null;


  selectedOrganizationId: string;

  isOpen = true;
  public roleMembership: RoleMembership;

  public isWidgetAvailable = false;

  languages = [
    {
      id: 'en',
      title: 'English',
      flag: 'us'
    },
    {
      id: 'ru',
      title: 'Русский',
      flag: 'ru'
    }
  ];
  @Select(OrganizationsState.getActiveBranches) organizations$: Observable<Organization[]>;
  @Select(OrganizationsState.getSelectedOrganization) selectedOrganization$: Observable<Organization>;
  @Select(OrganizationsState.getSelectedOrganizationId) selectedOrganizationId$: Observable<string>;
  @ViewChild('profileTrigger') profileTrigger: MatMenuTrigger;
  organizations: Organization[];
  filteredOrganizations: Organization[];
  filterValue = '';

  constructor(
    public router: Router,
    private store: Store,
    public translateService: TranslateService,
    public route: ActivatedRoute,
    private ngrxStore: ngrxStore,
  ) {
  }

  getFilteredOrganizations(): Organization[] {
    const filtered = this.organizations
      .filter(o => !this.filterValue || o.name && o.name.toLowerCase().includes(this.filterValue.toLowerCase()));
    return this.organizations.filter(o => filtered.some(f => o.id === f.id || o.id === f.parentId));
  }

  ngOnInit(): void {

    // если нет ссылки на API виджета, то значит сервер его не поддерживает
    this.isWidgetAvailable = !!environment.widgetBaseUrl

    this.ngrxStore.select(selectLoginData).pipe(
      untilDestroyed(this)
    ).subscribe(user => {
      this.user = user;
    });

    this.organizations$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(organizations => {
        this.organizations = organizations;
        this.filteredOrganizations = this.getFilteredOrganizations();
      });
    this.selectedOrganizationId$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(organizationId => {
        this.selectedOrganizationId = organizationId;
      });

    this.ngrxStore.select(selectRole)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(roleMembership => {
        this.roleMembership = roleMembership
      });
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  logout(): void {
    this.ngrxStore.dispatch(signOutUser());
  }

  setLanguage(lang): void {
    this.ngrxStore.dispatch(setLanguage(lang.id));
  }

  onOrganizationChange(): void {
    this.router.navigate([this.selectedOrganizationId], {
      relativeTo: this.route
    });
  }

  filterValueChange(): void {
    this.filteredOrganizations = this.getFilteredOrganizations();
  }
}
