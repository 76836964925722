import { Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { OrdersState } from 'app/state-management/states/orders.states';
import { APIService } from 'app/services/api.service';
import { DetailedOrderInQueue } from '../../../../models/orderInQueue.model';
import { OrganizationsState } from '../../../../state-management/states/organizations.states';
import { PipeConfiguration } from '../../../../models/pipe.model';
import { ICalculateDeliveryModel, TravelMethod } from '../../../../models/ICalculateDeliveryModel';
import { PipeHelper } from '../../../../helpers/pipeHelpers';
import { Organization } from '../../../../models/organization.model';
import { ExceptionCode } from 'app/models/rpcResponse.model';
import { SetDeliveringCalculates } from '../../../../state-management/actions/orders.actions';
import { IExternalSystem } from '../../../../models/externalSystem.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-calculate-delivering',
  templateUrl: './calculate-delivering.component.html',
  styleUrls: ['./calculate-delivering.component.scss']
})
export class CalculateDeliveringComponent implements OnInit {
  externalSystems: IExternalSystem[];
  courierPipes: PipeConfiguration[];
  deliveryCalculates: ICalculateDeliveryModel[];
  currentOrder: DetailedOrderInQueue;
  organization: Organization;

  pipesCouriersAvailableForCalculation: PipeConfiguration[];
  pipesCouriersNotSupportedCalculation: PipeConfiguration[];

  isWaiting = false;
  isCalculated = false;

  @Select(OrganizationsState.pipes) pipes$: Observable<PipeConfiguration[]>;
  @Select(OrganizationsState.getSelectedOrganization) organization$: Observable<Organization>;
  @Select(OrdersState.deliveryCalculates) deliveryCalculates$: Observable<ICalculateDeliveryModel[]>;
  @Select(OrdersState.currentOrder) currentOrder$;

  constructor(private store: Store, private apiProvider: APIService) {
  }

  ngOnInit(): void {
    this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);

    this.organization$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(organization => {
        this.organization = organization;
      });

    this.deliveryCalculates$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(deliveryCalculates => {
        this.deliveryCalculates = deliveryCalculates;
      });

    this.currentOrder$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(order => {
        this.currentOrder = order;
        this.isCalculated = false;
      });
  }

  sendCourierOrder(courierExternalSystemType: number, courierType: TravelMethod): void {
    this.apiProvider.sendCourierOrder(
      this.currentOrder.id,
      this.currentOrder.targetOrderId,
      courierExternalSystemType,
      courierType
    )
      .subscribe(res => {
      });
  }

  clickDelivery(): void {
    if (this.currentOrder) {
      this.isWaiting = true;
      this.isCalculated = true;
      this.pipes$
        .pipe(
          untilDestroyed(this)
        )
        .subscribe(pipes => {
          const pipesCouriers = PipeHelper.FilterByAvailableCalculationCourier(pipes, this.currentOrder, this.externalSystems);
          this.pipesCouriersAvailableForCalculation = pipesCouriers.supportedCalculationPipes;
          this.pipesCouriersNotSupportedCalculation = pipesCouriers.notSupportedCalculationPipes;

          const initialDeliveryCalculates = new Array<ICalculateDeliveryModel>();
          pipesCouriers.notSupportedCalculationPipes.forEach(p => initialDeliveryCalculates.push({
            pipeId: p.id, isAvailableCalculation: false,
            courierType: p.targetConfiguration.externalSystemType
          } as ICalculateDeliveryModel));
          pipesCouriers.supportedCalculationPipes.forEach(p => initialDeliveryCalculates.push({
            pipeId: p.id, isAvailableCalculation: true, calculationAttemptMade: false,
            courierType: p.targetConfiguration.externalSystemType
          } as ICalculateDeliveryModel));

          this.store.dispatch(new SetDeliveringCalculates(initialDeliveryCalculates));

          this.pipesCouriersAvailableForCalculation.forEach(p => {
            this.getDeliveryCalculates(this.currentOrder.id, p);
          });

          this.isWaiting = false;
        });
    }
  }

  getDeliveryCalculates = (orderId: string, pipe: PipeConfiguration) => {
    this.apiProvider.getDeliveryCalculates(orderId, pipe.id)
      .pipe(take(1))
      .subscribe(rpcResponse => {
        let addedCalculates = new Array<ICalculateDeliveryModel>();
        if (rpcResponse.error == null && rpcResponse.result != null) {
          addedCalculates = rpcResponse.result;
          addedCalculates.forEach(x => {
            x.isAvailableCalculation = true;
            x.calculationAttemptMade = true;
            if (x.travelETA) {
              x.deliveryTime = new Date(new Date().getTime() + x.travelETA * 60000);
            }
          });
        } else {
          const calculationError = !(rpcResponse.error.code === ExceptionCode.NotFoundObjects);
          addedCalculates.push({
            pipeId: pipe.id,
            calculationAttemptMade: true,
            courierType: pipe.targetConfiguration.externalSystemType,
            isAvailableCalculation: true,
            priceETA: null,
            travelETA: null,
            calculationError,
          } as ICalculateDeliveryModel);
        }

        const oldDeliveryCalculates = this.store.selectSnapshot(OrdersState.deliveryCalculates) ?? new Array<ICalculateDeliveryModel>();
        const newDeliveryCalculates =
          [...oldDeliveryCalculates
            .filter(x => x.pipeId !== pipe.id)
            .map(x => ({...x})),
            ...addedCalculates];
        const minPrice = newDeliveryCalculates
          .filter(x => x.priceETA && x.priceETA > 0).sort((prev, cur) => prev.priceETA - cur.priceETA)[0]?.priceETA;
        const minTime = newDeliveryCalculates
          .filter(x => x.travelETA && x.travelETA > 0).sort((prev, cur) => prev.travelETA - cur.travelETA)[0]?.travelETA;
        newDeliveryCalculates.forEach(x => {
          x.cheapest = x.priceETA && minPrice && x.priceETA === minPrice;
          x.fastest = x.travelETA && minTime && x.travelETA === minTime;
        });

        this.store.dispatch(new SetDeliveringCalculates(newDeliveryCalculates));
      });
  };

  getImage = (calculate: ICalculateDeliveryModel) => {
    const courierExternalSystem = this.externalSystems.find(s => s.key === calculate.courierType);
    let result = true;
    let logo = courierExternalSystem.params?.logo;

    if (logo.indexOf('.') > 0) {
      result = false;
      logo = courierExternalSystem.externalSystemType;
    }

    return {
      result,
      logo
    };
  };
}
