import { createSelector } from '@reduxjs/toolkit';
import { OrganizationsStateModel } from 'app/state-management/states/organizations.states';
import { Organization } from 'app/models/organization.model';

const selectSelf = (state): OrganizationsStateModel => {
  return state['organizations'];
};

export const selectSelectedOrganizationIdsWithChildren = createSelector(
  selectSelf,
  (state): Organization[] => {
    return state.organizations
      .filter(organization => organization.parentId === state.selectedOrganizationId || organization.id === state.selectedOrganizationId);
  }
);

export const selectAllOrganizations = createSelector(
  selectSelf,
  (state): Organization[] => {
    return state.organizations;
  }
);