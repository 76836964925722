import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { APIService } from 'app/services/api.service';
import * as MasterOrderActions from './master-order.actions';
import { firstValueFrom, switchMap, take, withLatestFrom } from 'rxjs';
import { Store as ngsxStore } from '@ngxs/store';
import { selectSelectedOrganizationIdsWithChildren } from 'app/state-management/states/organizations.selector';
import { getDefaultOrderListFilter } from 'app/helpers/ordersListFilterHelper';
import { OrderFilter } from 'app/models/orderFilter.model';
import { convertMasterOrder } from 'app/services/api-converters';
import { OrganizationsState } from 'app/state-management/states/organizations.states';

@Injectable()
export class MasterOrderEffects {
  constructor(
    private actions$: Actions,
    private store: ngsxStore,
    private apiProvider: APIService
  ) {
  }
  fetchMasterOrders = createEffect(() => {
    return this.actions$.pipe(
      ofType(MasterOrderActions.loadMasterOrders),
      switchMap(() => {
        return this.store.select(selectSelectedOrganizationIdsWithChildren)
          .pipe(
            take(1),
            withLatestFrom(this.store.select(OrganizationsState.getExternalSystems)),
            switchMap(async ([organizations, externalSystems]) => {

              const defaultValues = getDefaultOrderListFilter();
              const filter: OrderFilter = {
                organizationIds: organizations.map(organization => organization.id),
                skip: defaultValues.skip,
                take: defaultValues.take,
                from: defaultValues.fromDate,
                to: defaultValues.toDate
              };

              const orders = await firstValueFrom(this.apiProvider.fetchMasterOrders(filter));
              const convertedOrders = orders.data.items.map(value => convertMasterOrder(value, organizations, externalSystems))
                .filter(value => value !== null)
              return MasterOrderActions.loadMasterOrdersSuccess(convertedOrders);
            })
          );
      })
    );
  });

  fetchMasterOrdersWithUpdatedFilter = createEffect(() => {
    return this.actions$.pipe(
      ofType(MasterOrderActions.updateOrderFilter),
      switchMap((action) => {
        return this.store.select(selectSelectedOrganizationIdsWithChildren)
          .pipe(
            take(1),
            withLatestFrom(this.store.select(OrganizationsState.getExternalSystems)),
            switchMap(async ([organizations, externalSystems]) => {
              const defaultValues = getDefaultOrderListFilter();
              const filter: OrderFilter = action.payload;

              const orders = await firstValueFrom(this.apiProvider.fetchMasterOrders(filter));
              const convertedOrders = orders.data.items.map(value => convertMasterOrder(value, organizations, externalSystems))
                .filter(value => value !== null)
              return MasterOrderActions.loadMasterOrdersSuccess(convertedOrders);
            })
          );
      })
    );
  });
}
