import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// import { PushNotificationsService } from 'ng-push-ivy'; TODO https://angular.io/api/service-worker/SwPush
import { SignalrService } from './services/signalr.service';
import { Select, Store } from '@ngxs/store';
import { ActionsSubject, Store as ngrxStore } from '@ngrx/store';
import { Router } from '@angular/router';
import { InitFilters } from './state-management/actions/filters.actions';
import { ExternalSystemPipe } from './shared/pipes/external-system.pipe';
import { OrganizationsState } from './state-management/states/organizations.states';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { APIService } from './services/api.service';
import { SnackbarService } from './services/snackbar.service';
import { SnackbarComponent } from './shared/components/snackbar/snackbar.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from "@ngrx/effects";
import { signOutUser } from "app/reducers/context.actions";
import { environment } from 'environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-root',
  template: `
    <app-preloader> <router-outlet></router-outlet></app-preloader>
   `
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(OrganizationsState.getSelectedOrganizationId) getSelecteOrganizationId$: Observable<string>;

  constructor(
    private router: Router,
    private signalrService: SignalrService,
    // private pushNotificationService: PushNotificationsService,
    private store: Store,
    private ngrxStore: ngrxStore,
    private externalSystemPipe: ExternalSystemPipe,
    private snackBar: MatSnackBar,
    private api: APIService,
    private snackbarService: SnackbarService,
    protected readonly storeActions: ActionsSubject,
    private _ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    console.info(`V: ${environment.lastHashCommit}`);
    this.signalrService.init();

    this.storeActions.pipe(
      ofType(signOutUser)
    ).subscribe(value => {
      this._ngZone.run(() => {
        this.router.navigate(['auth']).then(() => {
        });
      });
    })

    this.getSelecteOrganizationId$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(selectedOrganizationId => {
        if (selectedOrganizationId) {
          this.store.dispatch(new InitFilters());
          // this.store.dispatch(new InitEvent());
          // this.store.dispatch(new InitDishesGroups());
        }
      });

    this.api.rpcError$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => {
        this.snackBar.openFromComponent(ErrorSnackBarComponent, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          data,
          panelClass: 'error-contaner'
        });
      });

    this.snackbarService.message$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((message) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          data: message,
          panelClass: 'snackbar-' + message.type
        });
      });
  }

  async ngOnDestroy(): Promise<void> {
    await this.signalrService.disconnect();
  }
}

@Component({
  selector: 'app-snack-bar-error',
  template: `
    <div class="code">Error code: {{data.code}}</div>
    <div class="message"> Message: {{data.message}}</div>
  `,
  styles: [`
    .code, .message {
      color: maroon;
      min-width: 300px;
    }

    .code {
      font-size: 1.2em;
    }
  `],
})
export class ErrorSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }
}
