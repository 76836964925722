import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Configuration } from 'app/models/configuration.model';
import { Select, Store } from '@ngxs/store';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { Observable } from 'rxjs';
import {IExternalSystem, IIKO_CODE} from 'app/models/externalSystem.model';
import { environment } from 'environments/environment';
import {
  SelectOrganizationsModalComponent,
  SelectOrganizationsModalComponentData
} from '../select-organizations-modal/select-organizations-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Organization } from 'app/models/organization.model';
import { SnackbarService } from 'app/services/snackbar.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import {APIService} from "app/services/api.service";

@Component({
  selector: 'app-system-tag',
  templateUrl: './system-tag.component.html',
  styleUrls: ['./system-tag.component.scss']
})
export class SystemTagComponent implements OnInit {

  @Input() configuration: Configuration;
  @Input() organization: Organization;
  @Output() changeConfiguration: EventEmitter<any> = new EventEmitter();
  @Output() deleteConfiguration: EventEmitter<any> = new EventEmitter();
  @Output() turnOnConfiguration: EventEmitter<any> = new EventEmitter();
  @Output() turnOffConfiguration: EventEmitter<any> = new EventEmitter();
  @Output() restoreConfiguration: EventEmitter<any> = new EventEmitter();
  @Output() moveConfiguration: EventEmitter<any> = new EventEmitter();
  @Output() openDialog: EventEmitter<any> = new EventEmitter();
  @Select(OrganizationsState.showDeletedPipesAndConfigurations) showDeletedPipesAndConfigurations$: Observable<boolean>;

  status: string;
  validationMessage: string;
  externalSystems: IExternalSystem[];
  externalSystem: IExternalSystem;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private snackbar: SnackbarService,
    private clipboard: Clipboard,
    private translate: TranslateService,
    private apiProvider: APIService
  ) {
  }

  ngOnInit(): void {
    this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);
    this.externalSystem = this.externalSystems.find(x => x.key === this.configuration.externalSystemType);
    this.updateStatus();
  }

  supportSwitchStatus(): boolean {
    return this.externalSystem.params.isSupportSwitchStoreStatus;
  }

  titleClick(): void {
    this.openDialog.emit(this.configuration);
  }

  remove(): void {
    if (confirm('all associated pipes will be deleted')) {
      this.deleteConfiguration.emit(this.configuration.id);
      this.configuration.isDeleted = true;
    }
  }

  switchOn(): void {
    if (confirm('Are you sure want to turn on store?')) {
      this.turnOnConfiguration.emit(this.configuration.id);
    }
  }

  switchOff(): void {
    if (confirm('Are you sure want to turn off store?')) {
      this.turnOffConfiguration.emit(this.configuration.id);
    }
  }

  restore(): void {
    if (confirm('are you sure?')) {
      this.restoreConfiguration.emit(this.configuration.id);
      this.configuration.isDeleted = false;
    }
  }

  move(): void {
    const parentId = this.organization.parentId !== null ? this.organization.parentId : this.organization.id;
    const selectOrganizationsDialogRef = this.dialog.open(SelectOrganizationsModalComponent, {
      data: {ids: [this.organization.id], filteredId: parentId, selectType: 'radio_button'} as SelectOrganizationsModalComponentData,
    });

    selectOrganizationsDialogRef.afterClosed()
      .subscribe((data: SelectOrganizationsModalComponentData) => {
        if (data && data.ids && data.ids.length > 0) {
          this.moveConfiguration.emit(data.ids[0]);
        }
      });
  }

  copyId(): void {
    this.clipboard.copy(this.configuration.id);
    this.snackbar.info(this.translate.instant('COMMON.COPIED_ID', {id: this.configuration.id}));
  }

  getTooltipText(configuration: Configuration): string {
    return `${configuration.title}\n${configuration.comment ?? ''}`;
  }

  allowDownloadNomenclature = () =>
    this.externalSystem?.params?.isSupportDownloadNomenclature;

  allowDownloadStopList = () =>
    this.externalSystem?.params?.isSupportDownloadStopList;

  allowUpdateWebhook = () =>
    this.externalSystem.externalSystemType == IIKO_CODE;

  downloadNomenclature = () => {
    let externalId = this.configuration.externalId;
    window.open(`${environment.baseUrl}/api/menu/nomenclature/${this.externalSystem.externalSystemType}/${externalId}`, '_blank');
  };

  downloadStopList = () => {
    window.open(`${environment.baseUrl}/api/menu/stoplist/${this.externalSystem.externalSystemType}/${this.configuration.externalId}`, '_blank');
  };

  updateWebhook = () => {
    this.apiProvider.updateWebhookConfiguration(this.organization.id).pipe().subscribe({
      next: value => {
        this.configuration = value.result;
        this.updateStatus();
      }
    });
  }

  private updateStatus() {
    this.status = 'undefined';
    this.validationMessage = '';
    if (this.configuration.metadata.validationResult) {
      if (this.configuration.metadata.validationResult.isValid) {
        switch (this.configuration.metadata.liveStatus) {
          case 0:
            this.status = 'undefined';
            this.validationMessage = '';
            break;
          case 1:
            this.status = 'online';
            this.validationMessage = 'online';
            break;
          case 2:
            this.status = 'offline';
            this.validationMessage = 'offline';
            break;
        }
      } else {
        this.status = 'invalid';
        this.validationMessage = this.configuration.metadata.validationResult.messages.join('\n');
      }
    }
  }

}
