import { Component, OnInit } from '@angular/core';
import { Organization } from 'app/models/organization.model';
import { MatTableDataSource } from '@angular/material/table';
import { APIService } from 'app/services/api.service';
import { combineLatest, Observable, startWith } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { map } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import {
  GecoPosOrganizationModalComponent
} from 'app/components/geco-pos-organizations/geco-pos-organization-modal/geco-pos-organization-modal.component';
import { Store } from "@ngrx/store";
import { Store as ngsxStore } from "@ngxs/store";
import * as posActions from "app/reducers/pos.actions";
import { selectAllOrganizations } from "app/state-management/states/organizations.selector";
import { PosOrganizationModel } from "app/reducers/pos.reducer";
import { PosOrganizationListItem, selectPosEntities } from "app/reducers/pos.selectors";

@UntilDestroy()
@Component({
  selector: 'app-geco-pos-organizations',
  templateUrl: './geco-pos-organizations.component.html',
  styleUrls: ['./geco-pos-organizations.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GecoPosOrganizationsComponent implements OnInit {
  organizations: PosOrganizationListItem[] = [];

  dataSource = new MatTableDataSource(this.organizations);
  columnsToDisplay = ['name', 'gecoPos.apiKey', 'edit'];
  expandedElement: Organization | null;

  autocompleteControl = new UntypedFormControl();
  autocompleteOptions = [];
  autocompleteItems: Observable<Organization[]>;

  constructor(
    private api: APIService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private store: Store,
    private ngsxStore: ngsxStore
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.organizations);
    this.store.dispatch(posActions.loadInfo());

    combineLatest([
      this.ngsxStore.select(selectAllOrganizations),
      this.store.select(selectPosEntities)
    ]).pipe(
      untilDestroyed(this)
    ).subscribe(([organizations, entities]) => {
      const data = organizations.map((organization): PosOrganizationListItem => {
        return {
          ...organization,
          gecoPosOrganization: entities[organization.id]
        }
      });
      this.dataSource.data = data;
      this.organizations = data;
    })

    this.autocompleteItems = this.autocompleteControl.valueChanges
      .pipe(untilDestroyed(this))
      .pipe(
        startWith(''),
        map(val => typeof val === 'string' ? val : val.name),
        map(name => name ? this.filterByAutocomplete(name) : this.organizations)
      );
    this.autocompleteControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const selectedOrganization = this.autocompleteControl.value;
        if (typeof selectedOrganization === 'object') {
          this.dataSource.data = this.filterOrganizations(selectedOrganization);
        }
      });
  }

  filterByAutocomplete(value: string): PosOrganizationListItem[] {
    const filterValue = value?.toLowerCase();
    let filteredOrganizations = this.organizations.filter(opt => opt.name.toLowerCase().includes(filterValue)).map(o => o.id)
    return [
      ...this.organizations.filter(o => filteredOrganizations.includes(o.id) || filteredOrganizations.includes(o.parentId)),
    ];
  }

  filterOrganizations(organization: PosOrganizationListItem): PosOrganizationListItem[] {
    return [
      ...this.organizations.filter(o => o.id === organization.id || o.parentId === organization.id),
    ];
  }

  displayFn(organization: PosOrganizationListItem): string {
    return organization && organization.name ? organization.name : '';
  }

  getColumnHeader(columnHeaderValue: string): string {
    switch (columnHeaderValue) {
      case 'name':
        return this.translate.instant('ORGANIZATION.NAME');
      case 'gecoPos.apiKey':
        return this.translate.instant('GECO_POS.API_KEY');
      case 'gecoPos.orderCreateWebhookUrl':
        return this.translate.instant('GECO_POS.ORDER_CREATE_WEBHOOK_URL');
      case 'gecoPos.orderStatusUpdateWebhookUrl':
        return this.translate.instant('GECO_POS.ORDER_STATUS_UPDATE_WEBHOOK_URL');
      case 'gecoPos.stopListWebhookUrl':
        return this.translate.instant('GECO_POS.STOP_LIST_WEBHOOK_URL');
      default:
        return '';
    }
  }

  editOrganization(id: string, name: string, gecoPosOrganizationModel: PosOrganizationModel): void {
    let isNew = false;
    if (!gecoPosOrganizationModel?.id) {
      isNew = true;
    }
    let gecoPosOrganization = {...gecoPosOrganizationModel, id: id, name: name};
    const dialogRef = this.dialog.open(GecoPosOrganizationModalComponent, {
      minWidth: '50%',
      maxWidth: '840px',
      data: {isNew: isNew, organization: gecoPosOrganization}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(posActions.loadInfo());
    });
  }
}


