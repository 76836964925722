import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {

  constructor(public translateService: TranslateService) {}

  transform(value: string|Date, format: string, locale?: string): unknown {
    if (!value){
      return '-';
    }

    if (!locale) {
      locale = this.translateService.currentLang;
    }
    return this.formatTime(value, format, locale);
  }

  // https://momentjs.com/docs/#/displaying/
  formatTime(time: string|Date, format: string, locale: string): string{
    moment.locale(locale);
    return moment.utc(time).local().format(format);
  }
}

export const utcToLocalFormat = 'yyyy-MM-DD HH:mm:ss';
