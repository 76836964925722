<li
    [class]="'order-item ' + order.orderStatusInfo.stateName | lowercase" #listItem
    [class.error]="order.orderStatusInfo.isError"
    [class.warn]="!order.orderStatusInfo.isError && order.orderStatusInfo.isWarning"
    [class.active]="selected"
    (click)="orderClick(order)"
    >
    <div>{{ order.orderStatusInfo.stateName | translate}}</div>
    <div class="time">
        <span class="hours">{{ order.discoveredAt | utcToLocal:'HH:mm' }},</span> <span
            class="date">{{ order.discoveredAt | utcToLocal:'MMM DD, y':locale }}</span>
    </div>

    <div style="font-size: 12px; font-weight: 300; font-family: 'Visuelt Pro'">
        <div fxLayout="row" fxLayoutAlign="start center">
            {{ order.sourceSystem | externalSystem : this.externalSystems : 'name' }}
            <mat-icon style="opacity: .5; font-size: 24px;">arrow_right_alt</mat-icon>
            {{ order.targetSystem | externalSystem : this.externalSystems : 'name'  }}
        </div>
    </div>
    <div style="font-size: 12px; font-weight: 300; font-family: 'Visuelt Pro'">
        {{ order.sourceOrderId | slice:0:18 }}
    </div>
</li>

