import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderState, OrderStatus, OrderStatusInfo } from 'app/models/orderStatus.model';
import { APIService } from './api.service';
import { IRpcResponse } from 'app/models/rpcResponse.model';
import { OrderInQueue } from 'app/models/orderInQueue.model';
import { MasterOrderState, ReflectedOrder, MasterOrderResponse } from 'app/models/masterOrder.model';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService {

  constructor(private api: APIService) {
  }

  public errors: any = [];

  private static getState(status: OrderStatus): OrderStatus {
    return status & OrderStatus.FILTER_STATE;
  }

  private static getMasterStateFromStatus(state: OrderStatus): MasterOrderState {
    switch (state) {
      case OrderStatus.NEW:
        return MasterOrderState.NEW;
      case OrderStatus.ACCEPTED:
        return MasterOrderState.ACCEPTED;
      case OrderStatus.COOKING:
        return MasterOrderState.COOKING;
      case OrderStatus.READY_TO_SHIP:
        return MasterOrderState.READY_TO_SHIP;
      case OrderStatus.SHIPPED:
        return MasterOrderState.SHIPPED;
      case OrderStatus.DELIVERED:
        return MasterOrderState.DELIVERED;
      case OrderStatus.CLOSED:
        return MasterOrderState.CLOSED;
      case OrderStatus.CANCELLED:
        return MasterOrderState.CANCELLED;
    }
  }

  private static getNextState(state: OrderStatus): OrderStatus | null {
    return state << 1;
  }

  private static getNextMasterState(state: MasterOrderState): MasterOrderState | null {
    return state < MasterOrderState.CLOSED ? state + 1 : null;
  }

  static getStatusInfo(status: OrderStatus): OrderStatusInfo {
    const state = OrderStatusService.getState(status);
    const masterState = OrderStatusService.getMasterStateFromStatus(state);
    const isFinalState = state.valueOf() === OrderStatus.CLOSED || state.valueOf() === OrderStatus.CANCELLED;
    const nextState = isFinalState ? null : OrderStatusService.getNextState(state);
    const isNewOrder = state.valueOf() === OrderStatus.NEW;
    return {
      state,
      masterState: masterState,
      stateName: this.convertToStatusString(state),
      isFinalState,
      nextState,
      nextStateName: nextState ? this.convertToStatusString(nextState) : '',
      nextMasterState: OrderStatusService.getNextMasterState(masterState),
      isNewOrder,
      isError: isNewOrder && (this.is(status, OrderStatus.TARGET_SYNC_ERROR) || this.is(status, OrderStatus.CONVERSION_ERROR)),
      isWarning: !isNewOrder && (this.is(status, OrderStatus.TARGET_SYNC_ERROR) || this.is(status, OrderStatus.CONVERSION_ERROR)) ||
        isNewOrder && this.is(status, OrderStatus.IGNORED)
    };
  }

  static convertToStatusString(value: number): string {
    switch (value & OrderStatus.FILTER_STATE) {
      case OrderStatus.NEW:
        return 'New';
      case OrderStatus.ACCEPTED:
        return 'Accepted';
      case OrderStatus.COOKING:
        return 'Cooking';
      case OrderStatus.READY_TO_SHIP:
        return 'Ready To Ship';
      case OrderStatus.SHIPPED:
        return 'Shipped';
      case OrderStatus.DELIVERED:
        return 'Delivered';
      case OrderStatus.CLOSED:
        return 'Closed';
      case OrderStatus.CANCELLED:
        return 'Cancelled';
    }
    return 'Unknown';
  }

  static is(status: number, state: number): boolean {
    return !!(status & state);
  }

  /**
   *
   * @param orderId
   * @param state
   * @deprecated
   */
  toState(orderId: string, state: number): Observable<IRpcResponse<OrderInQueue>> {
    return this.api.manualChangeOrderStatus(orderId, state);
  }

  toReflectedState(order: ReflectedOrder, state: MasterOrderState): Observable<IRpcResponse<MasterOrderResponse>> {
    return this.api.manualChangeReflectedOrderStatus(order, state);
  }
}
