import { Component, Input, OnInit } from '@angular/core';
import { DetailedOrderInQueue } from 'app/models/orderInQueue.model';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrdersState } from 'app/state-management/states/orders.states';
import { Observable } from 'rxjs';
import { ConfigurationType, IExternalSystem } from 'app/models/externalSystem.model';
import { Organization } from 'app/models/organization.model';
import { ICalculateDeliveryModelView, TravelMethod } from 'app/models/ICalculateDeliveryModel';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { Currency } from 'app/models/currency.model';

@UntilDestroy()
@Component({
  selector: 'app-order-delivering',
  templateUrl: './order-delivering.component.html',
  styleUrls: ['./order-delivering.component.scss']
})
export class OrderDeliveringComponent implements OnInit {
  externalSystems: IExternalSystem[];
  mapUrl = 'https://www.google.com/maps/place/';
  @Select(OrdersState.currentOrder) currentOrder$: Observable<DetailedOrderInQueue>;
  order: DetailedOrderInQueue;
  @Input() organization: Organization;
  deliveringBy = '';
  columnsToDisplay = ['viewCourier', 'viewPriceETA', 'viewTravelETA'];
  calculateViewInfo: ICalculateDeliveryModelView[];

  constructor(
    private store: Store,
    private translateService: TranslateService) {
  }

  getColumnHeader(columnHeaderValue: string): string {
    switch (columnHeaderValue) {
      case 'viewCourier':
        return this.translateService.instant('ORDERS.COURIER');
      case 'viewPriceETA':
        return this.translateService.instant('MENU.PRICE');
      case 'viewTravelETA':
        return this.translateService.instant('ORDERS.TRAVEL_ETA');
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this.currentOrder$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(currentOrder => {
        this.order = currentOrder;
        if (!this.order || this.order.isLoading) {
          return;
        }

        this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);

        const targetSystem = this.externalSystems.find(s => s.key === this.order.targetSystem);
        const sourceSystem = this.externalSystems.find(s => s.key === this.order.sourceSystem);

        if (this.order && targetSystem.params.configurationType === ConfigurationType.POS) {
          if (this.order.metadata.orderInfoDto.isGoOrder) {
            this.deliveringBy = this.translateService.instant('ORDERS.DELIVERY_BY_AGGREGATOR', {
              aggregatorName: sourceSystem.params.name
            });
          } else {
            this.deliveringBy = this.translateService.instant('ORDERS.DELIVERY_BY_RESTAURANT');
          }
        } else {
          this.deliveringBy = '';
        }

        if (this.organization && this.order.metadata.calculateInfo && this.order.metadata.calculateInfo.length > 0) {
          this.calculateViewInfo = this.order.metadata.calculateInfo.map(x => {
            const courierSystem = this.externalSystems.find(s => s.key === x.courierType);
            const result = {
              viewCourier: courierSystem.externalSystemType + '_' + TravelMethod[x.travelMethod],
              viewPriceETA: Math.round(x.priceETA / 60) + ' ' + Currency[this.organization.currency].toString(),
              viewTravelETA: x.travelETA ? Math.round(x.travelETA / 60) + ' ' + this.translateService.instant('ORDERS.MINUTES') : '',
            } as ICalculateDeliveryModelView;
            return result;
          });
        } else {
          this.calculateViewInfo = null;
        }
      });
  }
}
