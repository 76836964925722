import { OrderFilter } from 'app/models/orderFilter.model';
import { PipeFilter } from 'app/models/pipeFilter.model';

export class InitFilters {
  static readonly type = '[Filters] Init';

  constructor() {}
}

export class StorePipeFilter {
  static readonly type = '[PipeFilter] Store';

  constructor(public pipeFilter: PipeFilter) {}
}

export class StoreOrderFilter {
  static readonly type = '[OrderFilters] Store';

  constructor(public orderFilter: OrderFilter) {}
}
