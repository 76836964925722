import { UploadStatus } from 'app/models/enum';
import * as MasterOrderActions from './master-order.actions';
import * as OrganizationActions from '../state-management/actions/organizations.actions';
import { createReducer } from '@reduxjs/toolkit';
import * as MasterOrdersActions from 'app/reducers/master-order.actions';
import { MasterOrder } from 'app/models/masterOrder.model';
import {OrderFilter} from "app/models/orderFilter.model";

export const masterOrderFeatureKey = 'masterOrder';

export interface State {
  stock: MasterOrder[];
  byId: { [key: string]: MasterOrder };
  uploadStatus: UploadStatus;
  currentOrder: MasterOrder | null;
  list: {
    data: MasterOrder[];
    filter: OrderFilter | null;
    uploadStatus: UploadStatus;
    currentOrder: MasterOrder | null
  };
}

export const initialState: State = {
  stock: [],
  byId: {},
  currentOrder: null,
  uploadStatus: UploadStatus.NO,
  list: {
    data: [],
    filter: null,
    uploadStatus: UploadStatus.NO,
    currentOrder: null
  }
};

export const reducer = createReducer<State>(initialState, (builder) => {

  builder.addCase(MasterOrderActions.loadMasterOrders, (state) => {
    return {
      ...state,
      stock: [],
      currentOrder: null,
      uploadStatus: UploadStatus.LOADING
    };
  });

  builder.addCase(MasterOrderActions.loadMasterOrdersSuccess, (state, action) => {
    const orders = [...action.payload].sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
    const currentOrder = orders.find(order => !order.finalPosOrderInQueue.statusInfo.isFinalState);
    const byId = {};
    orders.forEach(value => byId[value.id] = value);

    return {
      ...state,
      stock: orders,
      byId: byId,
      currentOrder: currentOrder,
      uploadStatus: UploadStatus.LOADED
    };
  });

  builder.addCase(MasterOrderActions.loadMasterOrdersFailure, (state) => {
    return {
      ...state,
      stock: [],
      byId: {},
      currentOrder: null,
      uploadStatus: UploadStatus.ERROR
    };
  });

  builder.addCase(MasterOrdersActions.setCurrentOrder, (state, action) => {
    return {
      ...state,
      currentOrder: action.payload,
    };
  });

  builder.addCase(MasterOrdersActions.updateOrder, (state, action) => {
    const byId = {...state.byId};
    byId[action.payload.id] = action.payload;

    const orders = Object.values(byId);
    orders.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);

    return {
      ...state,
      stock: orders,
      byId,
      currentOrder: state.currentOrder && action.payload.id !== state.currentOrder.id ? state.currentOrder : action.payload,
    };
  });


  /**
   * If organization changed, set initial state
   */
  builder.addCase(OrganizationActions.SetMasterOrganization, () => {
    return {
      ...initialState
    };
  });

  builder.addCase(MasterOrderActions.updateOrderFilter, (state, action) => {
    return {
      ...state,
      list: {
        ...state.list,
        filter: action.payload
      }
    };
  });
});
