export enum TravelMethod
{
    CAR = 0,
    WALK = 1,
}

export interface ICalculateDeliveryModel {
    id: string;
    pipeId: string;
    courierType: number;
    pickUp: Date;
    deliveryTime: Date;
    // travel time in minutes
    travelETA: number;
    // cost in kopecks
    priceETA: number;
    travelMethod: TravelMethod;
    isAvailableCalculation: boolean;
    calculationAttemptMade: boolean;
    calculationError: boolean;
    cheapest: boolean;
    fastest: boolean;
}

export interface ICalculateDeliveryModelView {
  viewCourier: string;
  viewTravelETA: string;
  viewPriceETA: string;
}
