import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetOrganization } from 'app/state-management/actions/organizations.actions';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { combineLatest, Observable, of, switchMap, take } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentOrganizationResolverService implements Resolve<string | null> {

  constructor(
    public store: Store,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<string | null> {
    return combineLatest([
      this.store.select(OrganizationsState.getActiveBranches),
      this.store.select(OrganizationsState.getSelectedOrganizationId)
    ])
      .pipe(
        filter(([organizations]) => {
          return organizations.length > 0;
        }),
        take(1),
        switchMap(([organizations, organizationId]) => {

          // если в роуте нет currentOrganizationId, то работаем с той организацией, что есть
          let foundOrganization = organizations.find(o => o.id === organizationId);
          if (route.params.currentOrganizationId) {
            foundOrganization = organizations.find(o => o.id === route.params.currentOrganizationId);
          }

          if (!foundOrganization) {
            foundOrganization = organizations[0];
          }

          if (!organizationId || organizationId !== foundOrganization.id) {
            return this.store.dispatch(new SetOrganization(foundOrganization.id));
          } else {
            return of(organizationId);
          }
        })
      );
  }
}
