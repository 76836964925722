<section class="logs" *ngIf="currentOrder.statusHistory">
    <h3 class="details-header">{{ 'ORDERS.STATUS_HISTORY' | translate }}</h3>
    <a *ngIf="this.viewElasticSearchLogUrl" href="{{hubElasticSearchLogUrl}}" target="_blank">Hub: {{ 'ORDERS.VIEWING_LOGS' | translate }}</a>
    <a class = "elastic-search-url" *ngIf="this.viewElasticSearchLogUrl" href="{{ltElasticSearchLogUrl}}" target="_blank">Lt: {{ 'ORDERS.VIEWING_LOGS' | translate }}</a>
  <div class="history"
       [ngClass]="{'history-skip': history?.message === 'Skip' }"
       *ngFor="let history of currentOrder.statusHistory">
        <div class="history-time">{{ history.changedAt | utcToLocal:'HH:mm:ss' }}</div>
        <div class="history-time" style="width: 100px;">{{history.initiator}}</div>
        <div>
            <div style="display: flex; justify-content: flex-start; align-items: center; gap: 4px; flex-wrap: wrap;">
                <span class="history-status">{{history?.fromStatus}}</span>
                <mat-icon  *ngIf="history.fromStatus" style="opacity: .5; font-size: 24px;">arrow_right_alt</mat-icon>
                <span class="history-status">{{history?.toStatus}}</span>
            </div>
            <div class="history-status">{{(history?.shortMessage && history.shortMessage !== 'Skip') ? history.shortMessage : history?.message}}</div>
            <div *ngFor="let reason of history.reasonObjects">
                <button mat-flat-button (click)="downloadFile(reason.keyName)">{{ 'ORDERS.DOWNLOAD_LOG_DETAILS' | translate }}</button>
            </div>
        </div>
    </div>
  </section>
