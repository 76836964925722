import { Component, ElementRef, ViewChild } from '@angular/core';
import { GmapComponent } from './gmap/gmap.component';


// types and data for example and test works component
type typeCoords = { lat: number, lng: number };
type typeMarker = {
  coords: typeCoords,
  imgs: string[],
  id: string | number,
  price: string | number,
};

const imgsUrlsMock = ['https://e3.edimdoma.ru/data/recipes/0012/4331/124331-ed4_wide.jpg?1554287606', 'https://e3.edimdoma.ru/data/recipes/0012/4331/124331-ed4_wide.jpg?1554287606', 'https://e3.edimdoma.ru/data/recipes/0012/4331/124331-ed4_wide.jpg?1554287606', 'https://e3.edimdoma.ru/data/recipes/0012/4331/124331-ed4_wide.jpg?1554287606', 'https://e3.edimdoma.ru/data/recipes/0012/4331/124331-ed4_wide.jpg?1554287606', 'https://e3.edimdoma.ru/data/recipes/0012/4331/124331-ed4_wide.jpg?1554287606'];

const destinationCoordsMock: typeCoords[] = [
  {lat: 55.680063, lng: 37.533446},
  {lat: 55.675720, lng: 37.560139},
  {lat: 55.697934, lng: 37.563637},
];

const currentCouirierMarkersMock: typeMarker[] = [
  {
    coords: {lat: 55.640063, lng: 37.523446},
    imgs: imgsUrlsMock,
    id: 'EWHDQUI12',
    price: 423
  },
  {
    coords: {lat: 55.655720, lng: 37.510139},
    imgs: imgsUrlsMock,
    id: 'EWH512I12',
    price: 19
  },
  {
    coords: {lat: 55.667934, lng: 37.593637},
    imgs: imgsUrlsMock,
    id: 'EXQUI12',
    price: 54
  }
];

const startCourierCoordsMock: typeCoords[] = [
  {lat: 55.626966, lng: 37.514091},
  {lat: 55.636966, lng: 37.524091},
  {lat: 55.666966, lng: 37.554091}
];


@Component({
  selector: 'app-livetracking',
  templateUrl: './livetracking.component.html',
  styleUrls: ['./livetracking.component.scss']
})
export class LivetrackingComponent {
  @ViewChild('gmapComponent') gmapComponent: GmapComponent;
  @ViewChild('description') descriptionRef: ElementRef;
  destinationCoords: typeCoords[];
  currentCouirierMarkers: typeMarker[];
  startCourierCoords: typeCoords[];
  imgsUrlsMock: string[];

  constructor() {
    this.destinationCoords = destinationCoordsMock;
    this.currentCouirierMarkers = currentCouirierMarkersMock;
    this.startCourierCoords = startCourierCoordsMock;
    this.imgsUrlsMock = imgsUrlsMock;
  }

  closeDescription(): void {
    const description: HTMLElement = this.descriptionRef.nativeElement;
    description.style.visibility = 'hidden';
  }

  changeSelectedPath(index: number | string): void {
    const description: HTMLElement = this.descriptionRef.nativeElement;
    description.style.visibility = 'visible';
    this.gmapComponent.setupRoute(index);
    this.gmapComponent.courierMarkerToGreen(index);
    this.gmapComponent.infoWindowToGreen(index);
  }
}
