import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { APIService } from 'app/services/api.service';
import { StopListStorage } from 'app/models/stopListStorage.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IBaseResponse } from 'app/models/baseResponse.model';
import { utcToLocalFormat } from "app/shared/pipes/utc-to-local.pipe";
import { AppHelper } from "app/helpers/appHelper";

@UntilDestroy()
@Component({
  selector: 'app-stop-list-history-modal',
  templateUrl: './stop-list-history-modal.component.html',
  styleUrls: ['./stop-list-history-modal.component.scss']
})
export class StopListHistoryModalComponent implements OnInit {
  stopListHistory: StopListStorage[] = [];
  dataSource = new MatTableDataSource(this.stopListHistory);
  columnsToDisplay = ['revision', 'uploadedAt', 'published', 'uploadMessage', 'uploadResult', 'actions'];
  dateFormat: string = utcToLocalFormat;

  constructor(
    private api: APIService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<StopListHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.api.getStopListStorageList(this.data.pipeConfigurationId)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(({result, error}) => {
        if (!error) {
          this.stopListHistory = [];
          this.stopListHistory = [...result];
          this.dataSource = new MatTableDataSource(this.stopListHistory);
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }
  
  getColumnHeader(columnHeaderValue: string): string {
    switch (columnHeaderValue) {
      case 'revision':
        return this.translate.instant('MENU_HISTORY.REVISION');
      case 'uploadedAt':
        return this.translate.instant('MENU_HISTORY.UPLOADED_AT');
      case 'published':
        return this.translate.instant('MENU_HISTORY.PUBLISHED_AT');
      case 'uploadMessage':
        return this.translate.instant('MENU_HISTORY.UPLOAD_MESSAGE');
      case 'uploadResult':
        return this.translate.instant('MENU_HISTORY.UPLOAD_RESULT');
      case 'actions':
        return this.translate.instant('MENU_HISTORY.ACTIONS');
      default:
        return '';
    }
  }

  downloadSourceStopList(stopListId: string): void {
    this.downloadCurrentStopListForPipe(stopListId, true);
  }

  downloadTargetStopList(stopListId: string): void {
    this.downloadCurrentStopListForPipe(stopListId, false);
  }

  private downloadCurrentStopListForPipe(stopListId: string, isSource: boolean): void {
    this.api.getStopListUrlById(stopListId, isSource)
    .subscribe({
      next: (res: IBaseResponse<string>) => {
        if (res) {
          AppHelper.openList(res.data);
        }
      },
      error: (e) => {
        console.log(e);
        alert('Can\'t download file');
      }
    });
  }
}
