import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from 'app/models/currency.model';

@Pipe({
  name: 'currencyOrganization'
})
export class CurrencyPipe implements PipeTransform {
  transform(value: Currency, ...args: unknown[]): string {
    return Currency[value];
  }
}
