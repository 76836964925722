import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { DetailedOrderInQueue, OrderInQueue } from 'app/models/orderInQueue.model';
import { OrderFilter } from 'app/models/orderFilter.model';
import { Select, Store } from '@ngxs/store';
import { Store as ngrxStore } from '@ngrx/store';
import { FiltersState } from 'app/state-management/states/filters.states';
import { RouterHelper } from 'app/services/route-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersState } from 'app/state-management/states/orders.states';
import { OrderStatusService } from 'app/services/order-status.service';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { PipeConfiguration } from 'app/models/pipe.model';
import { Organization } from 'app/models/organization.model';
import { MatTabGroup } from '@angular/material/tabs';
import { ConfigurationType, IExternalSystem } from 'app/models/externalSystem.model';
import { RoleMembership, selectRole } from "app/reducers/context.selectors";

@UntilDestroy()
@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class OrderListComponent implements OnInit {

  externalSystems: IExternalSystem[];
  disableRoute: boolean;
  isHasOrders: boolean;
  selectedIndex: number;
  organization: Organization;
  isLoading = true;

  // roles
  public roleMembership: RoleMembership;

  showDeliveryDetails: boolean;

  orders: OrderInQueue[];
  ordersCount: number;
  selectedOrder: DetailedOrderInQueue | null = null;

  errorOrder: boolean;

  @Select(FiltersState.getOrderFilter) getOrderFilter$: Observable<OrderFilter>;
  @Select(state => state.orders.ordersList) orders$: Observable<OrderInQueue[]>;
  @Select(state => state.orders.ordersListCount) ordersCount$: Observable<number>;
  @Select(OrdersState.currentOrder) currentOrder$: Observable<DetailedOrderInQueue>;
  @Select(OrganizationsState.pipes) pipes$: Observable<PipeConfiguration[]>;
  @Select(OrganizationsState.getBranches) organizations$: Observable<any[]>;
  @Select(OrganizationsState.getSelectedOrganization) organization$: Observable<Organization>;
  @Select(OrganizationsState.getSelectedOrganizationId) getSelectedOrganizationId$: Observable<string>;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    public router: Router,
    public routerHelper: RouterHelper,
    public store: Store,
    public ngrxStore: ngrxStore,
    public orderStatusService: OrderStatusService,
    public activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.currentOrder$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(currentOrder => {
        this.selectedOrder = currentOrder;
        if (currentOrder && currentOrder.details) {
          this.isLoading = false;
          this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);

          this.showDeliveryDetails = currentOrder
            && this.externalSystems.find(x => x.key === currentOrder.targetSystem)?.params.configurationType === ConfigurationType.POS
            && !currentOrder?.details?.deliveryInfo?.isTakeAway
            && !currentOrder?.metadata?.orderInfoDto?.isGoOrder;
        } else {
          this.isLoading = true;
        }
      });

    this.selectedIndex = 0;
    this.organization$.pipe(
      untilDestroyed(this)
    ).subscribe(organization => {
      this.organization = organization;
    });

    this.getOrderFilter$.pipe(
      untilDestroyed(this)
    ).subscribe(_ => this.selectedOrder = null);

    this.ngrxStore.select(selectRole).pipe(
      untilDestroyed(this)
    ).subscribe(roleMembership => {
      this.roleMembership = roleMembership;
    });
  }
}

