<div class="head-block">
    <div class="chip">{{type}}</div>
    {{sourcePipeConfiguration?.externalSystemType |  externalSystem: this.externalSystems :'name'}}
    => {{ targetPipeConfiguration?.externalSystemType | externalSystem: this.externalSystems :'name' }}
</div>
<h4>{{'UPDATE_SCHEDULE.EDIT_UPDATE_SHEDULE_FORM_TITLE'|translate}}</h4>

<div class="container">
    <mat-form-field class="input">
        <mat-label>Interval minutes</mat-label>
        <input matInput [(ngModel)]="intervalMinutes" type="number">
    </mat-form-field>

    <div class="input">
        <mat-slide-toggle [checked]="isActive" [(ngModel)]="isActive">Is Active</mat-slide-toggle>
    </div>

    <div class="input">
        <mat-slide-toggle [checked]="isDeleted" [(ngModel)]="isDeleted">Is Deleted</mat-slide-toggle>
    </div>

    <div class="buttons">
        <button
            mat-raised-button color="primary"
            class="submit-button"
            [disabled]=""
            (click)="save()">{{'COMMON.SAVE'|translate}}
        </button>

        <button
            mat-raised-button color="default"
            (click)="cancel()">{{'COMMON.CANCEL'|translate}}
        </button>
    </div>
</div>