import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PipeConfiguration, PipeSwitchRequest} from 'app/models/pipe.model';
import {Select, Store} from '@ngxs/store';
import {OrganizationsState} from 'app/state-management/states/organizations.states';
import {Observable} from 'rxjs';
import {APIService} from 'app/services/api.service';
import {ConfigurationType, IExternalSystem} from 'app/models/externalSystem.model';
import {Configuration} from 'app/models/configuration.model';
import {EditUpdateScheduleRequest, UpdateSchedule, UpdatingObjectType} from 'app/models/updateSchedule.model';
import {MatDialog} from '@angular/material/dialog';
import {
  AdditionalEditUpdateScheduleFormModalData,
  EditUpdateScheduleComponent
} from 'app/components/update-schedule/edit-update-schedule/edit-update-schedule.component';
import {Organization} from 'app/models/organization.model';
import { MenuHistoryModalComponent } from 'app/pages/location/shared/menu-history-modal/menu-history-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { SelectOrganizationsModalComponent, SelectOrganizationsModalComponentData } from '../select-organizations-modal/select-organizations-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {SnackbarService} from "app/services/snackbar.service";
import {Clipboard} from "@angular/cdk/clipboard";
import {
  StopListHistoryModalComponent
} from "app/pages/location/shared/stop-list-history-modal/stop-list-history-modal.component";

@UntilDestroy()
@Component({
  selector: 'app-pipe',
  templateUrl: './pipe.component.html',
  styleUrls: ['./pipe.component.scss']
})
export class PipeComponent implements OnInit {

  constructor(
    private api: APIService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    private clipboard: Clipboard,
    private store: Store) {
    this.isLoadedMenu = false;
  }

  @Input() pipe: PipeConfiguration;
  @Input() organization: Organization;
  @Output() switchPipe: EventEmitter<any> = new EventEmitter();
  @Output() deletePipe: EventEmitter<any> = new EventEmitter();
  @Output() restorePipe: EventEmitter<any> = new EventEmitter();
  @Output() movePipe: EventEmitter<any> = new EventEmitter();
  @Output() openDialog: EventEmitter<any> = new EventEmitter();
  @Select(OrganizationsState.showDeletedPipesAndConfigurations) showDeletedPipesAndConfigurations$: Observable<boolean>;

  isLoadedMenu: boolean;
  updateScheduleForMenuIsActive: boolean;
  updateScheduleForStopListIsActive: boolean;
  externalSystems: IExternalSystem[];
  sourceSystem: IExternalSystem;
  targetSystem: IExternalSystem;
  organizations: Organization[];
  allowLoadMenu: boolean;
  allowDownloadMenu: boolean;
  allowLoadStopList: boolean;

  ngOnInit(): void {
    this.refreshIndicators();
    this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);

    this.sourceSystem = this.store.selectSnapshot<(key: number) => IExternalSystem>(OrganizationsState.getExternalSystemByKey)
        (this.pipe.sourceConfiguration.externalSystemType);
    this.targetSystem = this.store.selectSnapshot<(key: number) => IExternalSystem>(OrganizationsState.getExternalSystemByKey)
        (this.pipe.targetConfiguration.externalSystemType);

    this.organizations = this.store.selectSnapshot<Organization[]>(OrganizationsState.getBranches);

    this.allowLoadMenu = this.pipe.isActive && !this.pipe.isDeleted
      && this.sourceSystem?.params.configurationType === ConfigurationType.Sales
      && this.targetSystem?.params.isSupportMenuExport;
    this.allowDownloadMenu = this.pipe.isActive && !this.pipe.isDeleted &&
        this.sourceSystem?.params.configurationType === ConfigurationType.Sales
        && this.targetSystem?.params.configurationType === ConfigurationType.POS;

    this.allowLoadStopList = this.pipe.isActive && !this.pipe.isDeleted &&
        (this.sourceSystem?.params.isSupportStopListImport
            && this.targetSystem?.params.isSupportStopListExport);
  }

  togglePipe(): void {
    this.switchPipe.emit({ id: this.pipe.id, flag: !this.pipe.isActive } as PipeSwitchRequest);
    this.pipe.isActive = !this.pipe.isActive;
  }

  remove(): void {
    if (confirm('remove: are you sure?')) {
      this.deletePipe.emit(this.pipe.id);
      this.pipe.isDeleted = true;
      this.pipe.isActive = false;
    }
  }

  move(): void {
    const parentId = this.organization.parentId !== null ? this.organization.parentId : this.organization.id;
    const selectOrganizationsDialogRef = this.dialog.open(SelectOrganizationsModalComponent, {
      data: {ids: [this.organization.id], filteredId: parentId, selectType: 'radio_button'} as SelectOrganizationsModalComponentData,
    });

    selectOrganizationsDialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data: SelectOrganizationsModalComponentData) => {
        if (data && data.ids && data.ids.length > 0) {
          this.movePipe.emit(data.ids[0]);
        }
      });
  }

  copyId(): void {
    this.clipboard.copy(this.pipe.id);
    this.snackbar.info(this.translate.instant('COMMON.COPIED_ID', {id: this.pipe.id}));
  }

  restore(): void {
    if (confirm('restore: are you sure?')) {
      this.restorePipe.emit(this.pipe.id);
      this.pipe.isDeleted = false;
    }
  }

  uploadMenu(): void {
    const confirmMessage = this.translate.instant('PIPES.UPLOAD_MENU_CONFIRM');
    const successMessage = this.translate.instant('PIPES.UPLOAD_MENU_SUCCESS');
    const errorMessage = this.translate.instant('PIPES.UPLOAD_MENU_ERROR');
    if (!this.isLoadedMenu && confirm(confirmMessage)) {
      this.isLoadedMenu = true;
      this.api.uploadMenu(this.pipe.id)
        .subscribe({
          next: result => {
            if (result.error == null) {
              alert(successMessage);
            } else {
              console.log('downloadMenu:error', result.error);
              alert(errorMessage);
            }
          },
          error: error => {
            console.log('downloadMenu:error', error);
            alert(errorMessage);
          },
          complete: () => {
            this.isLoadedMenu = false;
          }
        });
    }
  }

  pipeClick(configuration: Configuration): void {
    this.openDialog.emit(configuration);
  }

  getTooltipText(configuration: Configuration): string {
    const organizationName = !this.isCurrentOrganization(configuration) ?
        `\norganization: ${this.organizations?.find(x => x.id === configuration.organizationId)?.name}` :
        '';
    return `${configuration.title}\n${configuration.comment ?? ''}` + organizationName;
  }

  handleUpdateScheduleClick = (type: 'menu' | 'stop-list') => {
    const updatingObjectType = type === 'menu' ? UpdatingObjectType.Menu : UpdatingObjectType.StopLists;
    let updateSchedule = this.pipe.updateSchedules?.find(us => us.updatingObjectType === updatingObjectType);
    if (!updateSchedule) {
      updateSchedule = {
        intervalMinutes: 60,
        pipeConfigurationId: this.pipe.id,
        pipeConfiguration: this.pipe,
        lastRunAt: new Date(),
        updatingObjectType,
        isActive: true,
        isDeleted: false,
        id: '',
      };
    }

    this.openEditUpdateScheduleModal(updateSchedule);
  }

  private refreshIndicators = () => {
    this.updateScheduleForMenuIsActive = this.pipe
      .updateSchedules?.some(us => us.updatingObjectType === UpdatingObjectType.Menu && us.isActive && !us.isDeleted);
    this.updateScheduleForStopListIsActive = this.pipe
      .updateSchedules?.some(us => us.updatingObjectType === UpdatingObjectType.StopLists && us.isActive && !us.isDeleted);
  }

  private openEditUpdateScheduleModal = (updateSchedule: UpdateSchedule) => {
    const dialogRef = this.dialog.open(EditUpdateScheduleComponent, {
      width: '420px',
      data: {
        ...updateSchedule,
        targetPipeConfiguration: this.pipe.targetConfiguration,
        sourcePipeConfiguration: this.pipe.sourceConfiguration,
        pipeConfigurationId: this.pipe.id
      } as EditUpdateScheduleRequest & AdditionalEditUpdateScheduleFormModalData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.id) {
          this.api.editUpdateSchedule(result).subscribe(res => {
            if (res?.result) {
              const foundIndex = this.pipe.updateSchedules.findIndex(us => us.id === res.result.id);
              const updateSchedules = this.pipe.updateSchedules.slice();
              updateSchedules[foundIndex] = res.result;
              this.pipe.updateSchedules = updateSchedules;
              this.refreshIndicators();
            }
          });
        } else {
          this.api.createUpdateSchedule(result).subscribe(res => {
            if (res?.result) {
              this.pipe.updateSchedules.push(res.result);
              this.refreshIndicators();
            }
          });
        }
      }
      else {
        this.refreshIndicators();
      }
    });
  }

  openMenuHistoryModal = () => {
    const dialogRef = this.dialog.open(MenuHistoryModalComponent, {
      minWidth: '50%',
      maxWidth: '840px',
      data: {
        pipeConfigurationId: this.pipe.id,
      }
    });
  }

  openStopListHistoryModal = () => {
    const dialogRef = this.dialog.open(StopListHistoryModalComponent, {
      minWidth: '50%',
      maxWidth: '840px',
      data: {
        pipeConfigurationId: this.pipe.id,
      }
    });
  }

  isCurrentOrganization(configuration: Configuration): boolean{
    return configuration.organizationId === this.organization?.id;
  }
}
