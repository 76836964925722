import { Injectable } from '@angular/core';
import { Store as ngsxStore } from '@ngxs/store';
import { Store } from '@ngrx/store';
import { UpdateOrder } from 'app/state-management/actions/orders.actions';
import { environment } from 'environments/environment';
import * as signalR from '@microsoft/signalr';
import { MasterOrderResponse } from 'app/models/masterOrder.model';
import * as MasterOrderActions from 'app/reducers/master-order.actions';
import { convertMasterOrder } from 'app/services/api-converters';
import { Organization } from 'app/models/organization.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { IExternalSystem } from 'app/models/externalSystem.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { selectTokenInfo } from "app/reducers/context.selectors";
import { signOutUser } from 'app/reducers/context.actions';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public connectedOrganizations: Organization[] = [];
  connection: signalR.HubConnection;
  audio: HTMLAudioElement
  private isTryConnection: boolean;
  private audioInterval;
  private externalSystems: IExternalSystem[] = [];
  private connectionState = new BehaviorSubject<boolean>(false);

  constructor(
    private ngsxStore: ngsxStore,
    private store: Store,
  ) {

  }

  public init() {
    this.ngsxStore.select(OrganizationsState.getExternalSystems).pipe(
      untilDestroyed(this)
    ).subscribe(externalSystems => {
      this.externalSystems = externalSystems;
    });

    let lastHash = '';
    combineLatest(
      [
        this.store.select(selectTokenInfo),
        this.ngsxStore.select(OrganizationsState.getSelectedOrganizationTree),
      ]
    ).pipe(
      untilDestroyed(this),
    ).subscribe(async ([tokenInfo, selectedOrganizations]): Promise<void> => {
      // сначала подключаемся, а потом проверяем всё остальное!
      if (tokenInfo && tokenInfo.token) {
        await this.connect(tokenInfo.token);
        return;
      } else if (!tokenInfo || !tokenInfo.token) {
        await this.disconnect();
        return;
      }
    });
    combineLatest(
      [
        this.store.select(selectTokenInfo),
        this.ngsxStore.select(OrganizationsState.getSelectedOrganizationTree),
        this.connectionState.pipe()
      ]
    ).pipe(
      untilDestroyed(this),
    ).subscribe(async ([tokenInfo, selectedOrganizations, isConnected]): Promise<void> => {
      // сначала подключаемся, а потом проверяем всё остальное!
      if (!isConnected) {
        return;
      }

      const selectedOrganizationsIds = selectedOrganizations.map((organization) => organization.id);

      if (selectedOrganizationsIds.length && selectedOrganizationsIds.join('|') !== lastHash) {
        lastHash = selectedOrganizationsIds.join('|');
        this.connectedOrganizations = selectedOrganizations;
        await this.connection.invoke('OrganizationSubscribe', selectedOrganizationsIds);
      }
    });
  }

  public async disconnect(): Promise<void> {
    this.connectedOrganizations = [];
    if (this.connection && this.connection.state !== signalR.HubConnectionState.Disconnected) {
      return await this.connection.stop();
    }
    this.connectionState.next(false);
    return;
  }

  playAudio(): void {
    if (!this.audio) {
      this.audio = new Audio();
      this.audio.src = '../assets/audio/alarm.wav';
      this.audio.load();
    }

    if (this.audioInterval) {
      // audio is working already
      return
    } else {
      this.audioInterval = setInterval(() => {
        this.audio.play();
        console.log("play", new Date());
      }, 5000);
    }
  }

  stopAudio(): void {
    clearInterval(this.audioInterval);
  }

  private isDisconnected(): boolean {
    return !this.connection || this.connection.state === signalR.HubConnectionState.Disconnected
  }

  private async connect(token: string): Promise<void> {
    if (!this.connection || this.isDisconnected()) {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.baseUrl}/api/signal`, {accessTokenFactory: () => token})
        .build();
    }

    if (!this.isTryConnection && this.isDisconnected()) {
      this.isTryConnection = true;
      return await this.connection.start()
        .then(() => {
          this.connection.on('NewEvent', (event: string) => {
            console.warn('Unhandled NewEvent:', event);
          });

          this.connection.on('UpdateEvent', (event: string) => {
            console.warn('Unhandled UpdateEvent:', event);
          });

          this.connection.on('UpdateOrder', (orderInQueue: string) => {
            this.ngsxStore.dispatch(new UpdateOrder(JSON.parse(orderInQueue)));
          });

          this.connection.on('UpdateMasterOrder', (data: string) => {
            const masterOrderResponse: MasterOrderResponse = JSON.parse(data) as MasterOrderResponse;
            const order = convertMasterOrder(
              masterOrderResponse,
              this.connectedOrganizations,
              this.externalSystems
            );
            if (order) {
              this.store.dispatch(MasterOrderActions.updateOrder(order));
            }
          });

          this.connectionState.next(true)
        })
        .catch(async err => {
          if (err.message && err.message?.includes('Status code \'401\''))
          {
            await this.disconnect();
            this.store.dispatch(signOutUser());
          }
          else{
            console.log('Error while starting connection: ' + err);
            setTimeout(() => this.connect(token), 5000);
          }
        })
        .finally(() => this.isTryConnection = false);
    }
  }
}
