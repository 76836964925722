import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { PipeFilter } from 'app/models/pipeFilter.model';
import { InitFilters, StoreOrderFilter, StorePipeFilter } from '../actions/filters.actions';
import { OrderFilter } from 'app/models/orderFilter.model';
import { OrganizationsState } from './organizations.states';
import { getDefaultOrderListFilter } from 'app/helpers/ordersListFilterHelper';

export class FiltersStateModel {
  orderFilter: OrderFilter;
  pipeFilter: PipeFilter;
}

@State<FiltersStateModel>({
  name: 'filter',
  defaults: {
    pipeFilter: null,
    orderFilter: null,
  },
})

@Injectable()
export class FiltersState implements NgxsOnInit {
  @Selector()
  static getPipeFilter(state: FiltersStateModel): PipeFilter {
    return state.pipeFilter;
  }

  @Selector()
  static getOrderFilter(state: FiltersStateModel): OrderFilter {
    return state.orderFilter;
  }

  constructor(private store: Store) {
  }

  ngxsOnInit({getState, patchState}: StateContext<FiltersStateModel>): void {
  }

  @Action(InitFilters)
  initFilters({getState, patchState}: StateContext<FiltersStateModel>): void {
    const state = getState();
    const branchesIds = this.store.selectSnapshot<string[]>(
      OrganizationsState.getSelectedOrganizationTreeIds
    );

    const pipeFilter: PipeFilter = {organizationIds: branchesIds, isActiveOnly: false, skip: 0, take: 500};

    const defaultValues = getDefaultOrderListFilter();
    const orderFilter: OrderFilter = {
      organizationIds: branchesIds,
      from: state.orderFilter && state.orderFilter.from ? state.orderFilter.from : defaultValues.fromDate,
      to: state.orderFilter && state.orderFilter.to ? state.orderFilter.to : defaultValues.toDate,
      skip: defaultValues.skip,
      take: defaultValues.take
    };

    patchState({pipeFilter, orderFilter});
  }

  @Action(StorePipeFilter)
  storePipeFilter({patchState}: StateContext<FiltersStateModel>, {pipeFilter}: StorePipeFilter)
    : any {
    return patchState({pipeFilter});
  }

  @Action(StoreOrderFilter)
  storeOrderFilter({patchState}: StateContext<FiltersStateModel>, {orderFilter}: StoreOrderFilter)
    : any {
    return patchState({orderFilter});
  }
}
