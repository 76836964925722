import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditUpdateScheduleRequest, UpdatingObjectType } from 'app/models/updateSchedule.model';
import { Configuration } from 'app/models/configuration.model';
import { Store } from '@ngxs/store';
import { IExternalSystem } from 'app/models/externalSystem.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';

@Component({
  selector: 'app-new-update-schedule',
  templateUrl: './edit-update-schedule.component.html',
  styleUrls: ['./edit-update-schedule.component.scss']
})
export class EditUpdateScheduleComponent implements OnInit {
  externalSystems: IExternalSystem[];
  intervalMinutes: number;
  pipeConfigurationId: string;
  updatingObjectType: UpdatingObjectType;
  isActive: boolean;
  isDeleted: boolean;
  sourcePipeConfiguration: Configuration;
  targetPipeConfiguration: Configuration;
  id: string;
  type: string;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<EditUpdateScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditUpdateScheduleRequest & AdditionalEditUpdateScheduleFormModalData,
  ) {
  }

  ngOnInit(): void {
    this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);
    this.intervalMinutes = this.data.intervalMinutes;
    this.targetPipeConfiguration = this.data.targetPipeConfiguration;
    this.sourcePipeConfiguration = this.data.sourcePipeConfiguration;
    this.isActive = this.data.isActive;
    this.isDeleted = this.data.isDeleted;
    this.updatingObjectType = this.data.updatingObjectType;
    this.id = this.data.id;
    this.pipeConfigurationId = this.data.pipeConfigurationId;
    this.type = this.updatingObjectType === UpdatingObjectType.Menu ? 'Menu' : 'Stop-List';
  }

  save(): void {
    const request: EditUpdateScheduleRequest = {
      intervalMinutes: this.intervalMinutes,
      pipeConfigurationId: this.pipeConfigurationId,
      updatingObjectType: this.updatingObjectType,
      isActive: this.isActive,
      isDeleted: this.isDeleted,
      id: this.id,
    };
    this.dialogRef.close(request);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}

export interface AdditionalEditUpdateScheduleFormModalData {
  sourcePipeConfiguration: Configuration;
  targetPipeConfiguration: Configuration;
}
