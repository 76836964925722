import { PipeConfiguration } from 'app/models/pipe.model';
import { DetailedOrderInQueue } from 'app/models/orderInQueue.model';
import { IExternalSystem } from 'app/models/externalSystem.model';

export interface PipeFilterResult {
  supportedCalculationPipes: PipeConfiguration[];
  notSupportedCalculationPipes: PipeConfiguration[];
}

export const PipeHelper = class PipeHelperClass {
  static FilterByAvailableCalculationCourier(
    pipes: PipeConfiguration[],
    order: DetailedOrderInQueue,
    externalSystems: IExternalSystem[]): PipeFilterResult {
    const supportedCalculationPipes = new Array<PipeConfiguration>();
    const notSupportedCalculationPipes = new Array<PipeConfiguration>();
    pipes.map(p => {
      const courierSystem = externalSystems.find(s => s.key === p.targetConfiguration.externalSystemType);
      if (p.targetConfiguration.organizationId === order.organizationId
        && courierSystem.params?.configurationType === 'Delivery' && p.isActive) {
        if (courierSystem.params?.isAvailableCalculation) {
          supportedCalculationPipes.push(p);
        } else {
          notSupportedCalculationPipes.push(p);
        }
      }
    });
    return {supportedCalculationPipes, notSupportedCalculationPipes} as PipeFilterResult;
  }
};
