import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { KeyValue } from '@angular/common';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { OrderFilter } from 'app/models/orderFilter.model';
import { ORDER_STATUSES, OrderTags } from 'app/models/orderInQueue.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { Organization } from 'app/models/organization.model';
import { Observable } from 'rxjs';
import { debounceTime, map, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OrdersListQueryParams } from 'app/models/ordersListQueryParams.model';
import { OrdersState } from 'app/state-management/states/orders.states';
import { getDefaultOrderListFilter } from 'app/helpers/ordersListFilterHelper';
import { IExternalSystem } from 'app/models/externalSystem.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Nullable } from 'app/models/nullable.model';

@UntilDestroy()
@Component({
  selector: 'app-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderFilterComponent implements OnInit {

  branches: KeyValue<string, string>[];
  externalSystems: KeyValue<number, string>[];
  statuses: KeyValue<number, string>[];
  tags: Nullable<OrderTags>[] = [];
  filterForm: UntypedFormGroup;
  queryParams: Params;
  externalSystemInfos: IExternalSystem[];
  @Output() filtered = new EventEmitter<OrderFilter>();
  @Select(state => state.organizations.organizations) organizations$: Observable<Organization[]>;
  @Select(OrganizationsState.getSelectedOrganizationId) getSelectedOrganizationId$: Observable<string>;
  private defaultBranchId: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.externalSystemInfos = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);
    const emptyKeyValue: any = {key: -1, value: '-- All --', lang: 'STATUS.ALL'};
    this.externalSystems = [emptyKeyValue, ...this.externalSystemInfos.map(s => {
      return {
        key: s.key,
        value: s.params.name
      };
    })];
    this.statuses = [emptyKeyValue, ...ORDER_STATUSES];

    this.tags.push(null);
    for (let tag in OrderTags) {
      this.tags.push(tag as OrderTags);
    }
  }

  get orderNumber(): AbstractControl {
    return this.filterForm.get('orderNumber');
  }

  get orderStatus(): AbstractControl {
    return this.filterForm.get('status');
  }

  get sourceSystem(): AbstractControl {
    return this.filterForm.get('sourceSystem');
  }

  get targetSystem(): AbstractControl {
    return this.filterForm.get('targetSystem');
  }

  get branchId(): AbstractControl {
    return this.filterForm.get('branchId');
  }

  get from(): AbstractControl {
    return this.filterForm.get('from');
  }

  get to(): AbstractControl {
    return this.filterForm.get('to');
  }

  get tag(): AbstractControl {
    return this.filterForm.get('tag');
  }

  ngOnInit(): void {
    // this.activatedRoute.queryParams.forEach(param => this.queryParams = param).catch();
    const defaultValues = getDefaultOrderListFilter();

    this.filterForm = this.formBuilder.group({
      orderNumber: new UntypedFormControl(''),
      status: new UntypedFormControl(''),
      sourceSystem: new UntypedFormControl(''),
      targetSystem: new UntypedFormControl(''),
      branchId: new UntypedFormControl('', Validators.required),
      from: new UntypedFormControl(defaultValues.fromDate),
      to: new UntypedFormControl(defaultValues.toDate),
      tag: new UntypedFormControl(null),
    });

    this.activatedRoute.queryParams
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.getSelectedOrganizationId$)
      )
      .subscribe(([qParams, selectedOrganizationId]) => {
        if (this.filterForm) {
          this.filterForm.setValue({
            orderNumber: qParams.orderNumber ?
              qParams.orderNumber : '',
            status: qParams.orderStatus ?
              parseInt(qParams.orderStatus, 10) : '',
            sourceSystem: qParams.sourceSystemType ?
              parseInt(qParams.sourceSystemType, 10) : '',
            targetSystem: qParams.targetSystemType ?
              parseInt(qParams.targetSystemType, 10) : '',
            branchId: qParams.organizationIds
              ? (typeof qParams.organizationIds === 'object' && qParams.organizationIds.length)
                ? qParams.organizationIds[0]
                : qParams.organizationIds
              : selectedOrganizationId,
            from: qParams.from ?
              new Date(qParams.from).toISOString() : defaultValues.fromDate,
            to: qParams.to ?
              new Date(qParams.to).toISOString() : defaultValues.toDate,
            tag: qParams.tag ? qParams.tag : null,
          });
        }
      });

    this.filterForm.valueChanges
      .pipe(
        debounceTime(400)
      )
      .subscribe(() => this.onFilter());

    // init filter after render
    this.onFilter();
  }

  onFilter(): void {
    if (this.filterForm.valid && this.filterForm.get('to').value) {
      let organizationIds: string[];
      const branches = this.store.selectSnapshot<Organization[]>(
        OrganizationsState.getBranches
      );

      const foundBranches = branches.filter(o => o.parentId === this.branchId.value);
      if (foundBranches && foundBranches.length) {
        organizationIds = [this.branchId.value, ...foundBranches.map(o => o.id)];
      } else {
        organizationIds = [this.branchId.value];
      }
      const orderStatus = this.orderStatus.value !== null && this.orderStatus.value >= 0 ?
        parseInt(this.orderStatus.value, 10) : null;
      const sourceSystemType = this.sourceSystem.value !== null && this.sourceSystem.value >= 0 ?
        parseInt(this.sourceSystem.value, 10) : null;
      const targetSystemType = this.targetSystem.value !== null && this.targetSystem.value >= 0 ?
        parseInt(this.targetSystem.value, 10) : null;
      const tag = this.tag.value;

      const orderFilter: OrderFilter = {
        orderNumber: this.orderNumber.value?.toString().trim(),
        orderStatus,
        sourceSystemType,
        targetSystemType,
        organizationIds,
        // from Moment to Date
        from: new Date(this.from.value.toString()),
        to: new Date(this.to.value.toString()),
        skip: 0,
        take: 500,
        tag: tag,
      };
      this.setQueryParams(orderFilter);
    }
  }

  setQueryParams(newOrderFilter: OrderFilter): void {
    // Create temp object with query params that we have
    const tempQueryParams: OrdersListQueryParams = {
      from: newOrderFilter.from.toISOString(),
      to: newOrderFilter.to.toISOString()
    };
    if (newOrderFilter.orderNumber) {
      tempQueryParams.orderNumber = newOrderFilter.orderNumber;
    }
    if (newOrderFilter.orderStatus) {
      tempQueryParams.orderStatus = newOrderFilter.orderStatus;
    }
    if (newOrderFilter.sourceSystemType) {
      tempQueryParams.sourceSystemType = newOrderFilter.sourceSystemType;
    }
    if (newOrderFilter.targetSystemType) {
      tempQueryParams.targetSystemType = newOrderFilter.targetSystemType;
    }
    if (newOrderFilter.organizationIds) {
      tempQueryParams.organizationIds = newOrderFilter.organizationIds;
    }

    if (newOrderFilter.tag) {
      tempQueryParams.tag = newOrderFilter.tag;
    }

    // Add temp object with query params to url queryParams
    const ordersList = this.store.selectSnapshot(OrdersState.ordersList);
    const paramsOrderId = this.activatedRoute.snapshot.params.id;
    const foundOrder = ordersList.find(o => o.id === paramsOrderId);
    if (foundOrder || !ordersList.length) {
      this.router.navigate([], {
        queryParams: tempQueryParams,
        replaceUrl: true
      }).catch();
    } else {
      this.router.navigate(['./../', ordersList[0].id], {
        relativeTo: this.activatedRoute,
        queryParams: tempQueryParams,
        replaceUrl: true
      }).catch();
    }
  }

  onClear(): void {
    const defaultValues = getDefaultOrderListFilter();
    this.filterForm.reset({
      from: defaultValues.fromDate,
      to: defaultValues.toDate,
    });
    const orderFilter: OrderFilter = {
      organizationIds: [this.defaultBranchId],
      skip: defaultValues.skip,
      take: defaultValues.take,
      from: defaultValues.fromDate,
      to: defaultValues.toDate
    };
    this.filtered.emit(orderFilter);
  }

  getBranchKids$(): Observable<Organization[]> {
    return this.getSelectedOrganizationId$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.organizations$),
        map(([selectedOrganizationId, organizations]) => {
          return organizations.filter(org => org.id === selectedOrganizationId || org.parentId === selectedOrganizationId);
        })
      );
  }
}
