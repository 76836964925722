import { OrderFilter } from 'app/models/orderFilter.model';
import { ICalculateDeliveryModel } from 'app/models/ICalculateDeliveryModel';

export class FetchOrders {
  static readonly type = '[Orders] Fetch';

  constructor(public orderFilter: OrderFilter) {
  }
}

export class FetchOrderHistory {
  static readonly type = '[OrderHistory] Fetch';
}

export class FetchOrderDetails {
  static readonly type = '[OrderDetails] Fetch';
}

export class IncreaseOrderCount {
  static readonly type = '[OrderCount] Increase';
}

export class MarkViewOrders {
  static readonly type = '[Orders] MarkView';
}

export class SetOrders {
  static readonly type = '[Orders] Set orders';

  constructor(public filter: OrderFilter) {
  }
}

export class SetOrdersList {
  static readonly type = '[Orders] Set orders list';

  constructor(public filter: OrderFilter) {
  }
}

export class UpdateOrder {
  static readonly type = '[Orders] Update order';

  constructor(public orderInQueue) {
  }
}

export class SetCurrentOrder {
  static readonly type = '[Orders] Set current';

  constructor(public orderId: string | null) {
  }
}

export class SetDeliveryHistory {
  static readonly type = '[Orders] Set delivery history';

  constructor() {
  }
}

export class SetDeliveringCalculates {
  static readonly type = '[Orders] Set Delivering Calculates';

  constructor(public calculates: ICalculateDeliveryModel[]) {
  }
}
