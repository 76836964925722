<form name="form" [formGroup]="filterForm" class="form">

    <mat-form-field appearance="outline">
        <mat-label [translate]="'ORDERS.ORDER_NUMBER'">Ordery number</mat-label>
        <input matInput name="orderNumber" formControlName="orderNumber">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label [translate]="'ORDERS.ORDER_CREATION_PERIOD'">Choose a period</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="{{ 'ORDERS.START_DATE' | translate }}" formControlName="from">
            <input matEndDate placeholder="{{ 'ORDERS.END_DATE' | translate }}" formControlName="to">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label [translate]="'ORDERS.STATUS'">Status</mat-label>
        <mat-select formControlName="status">
            <mat-option *ngFor="let status of statuses" [value]="status.key">{{ status.lang | translate }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label [translate]="'ORDERS.SOURCE'">Source</mat-label>
        <mat-select formControlName="sourceSystem">
            <mat-option *ngFor="let item of externalSystems" [value]="item.key">{{item.value}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label [translate]="'ORDERS.TARGET'">Target</mat-label>
        <mat-select formControlName="targetSystem">
            <mat-option *ngFor="let item of externalSystems" [value]="item.key">{{item.value}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label [translate]="'ORDERS.BRANCH'">Branch</mat-label>
        <mat-select [value]="getSelectedOrganizationId$ | async" formControlName="branchId">
            <mat-option *ngFor="let branch of getBranchKids$() | async" [value]="branch.id">{{branch.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label [translate]="'ORDERS.ORDER_TAG'">Tag</mat-label>
      <mat-select formControlName="tag">
        <mat-option *ngFor="let tag of tags" [value]="tag">{{ tag }}</mat-option>
      </mat-select>
    </mat-form-field>


</form>
