<div class="container">
    <div class="not-found-content">
    <div class="not-found-text">
      
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <defs>
          <style>
            .cls-3 {
              fill: #db4848
            }
          </style>
        </defs>
          <g id="Group_1166" transform="translate(-30 32)">
              <path d="M88.264-17.05a1.68 1.68 0 0 0-2.315-.464L79.784-13.4a24.471 24.471 0 0 0-2.293-1.109l7.7-5.6a1.67 1.67 0 0 0 .371-2.328 1.668 1.668 0 0 0-2.328-.371l-9.563 6.955a47.92 47.92 0 0 0-6.871-1.421 1.663 1.663 0 0 0-1.872 1.429 1.667 1.667 0 0 0 1.429 1.875c1.355.181 2.624.405 3.813.661L61.55-7.04c-.877-.051-1.765-.085-2.675-.085-6.723 0-12.995 1.448-16.781 3.872a1.663 1.663 0 0 0-.5 2.3 1.67 1.67 0 0 0 2.3.507c2.936-1.883 7.755-3.072 13.125-3.3L53.9-1.474a1.67 1.67 0 0 0-.374 2.328 1.668 1.668 0 0 0 1.352.688 1.659 1.659 0 0 0 .976-.317l6.669-4.851c.763.064 1.485.163 2.2.267L61.95-1.514A1.674 1.674 0 0 0 61.486.8a1.68 1.68 0 0 0 1.392.741 1.681 1.681 0 0 0 .923-.277l5.464-3.645c2.893.859 5.053 2.008 6.135 3.224a46.772 46.772 0 0 1-16.52 2.7c-13.947 0-23.664-4.741-23.667-9 0-3.171 5.6-7.115 16.3-8.525a1.667 1.667 0 0 0 1.432-1.872 1.672 1.672 0 0 0-1.869-1.437c-11.668 1.539-19.201 6.185-19.201 11.833 0 .189.293 19 25.667 19h2.667C80.6 13.542 85-1.069 85.675-3.952c.029-.117.059-.237.08-.357l.035-.171c.005-.035-.008-.069-.005-.1a6.4 6.4 0 0 0 .091-.875 7.906 7.906 0 0 0-3.123-5.915l5.047-3.366a1.674 1.674 0 0 0 .464-2.314zM66.454-6.485l7.805-5.677c.832.285 1.616.579 2.323.893l-7.891 5.261c-.723-.176-1.464-.338-2.237-.477zm-6.246 16.693h-2.666c-10.981 0-16.563-3.76-19.4-7.6 4.875 2.64 12.219 4.267 20.733 4.267 7.736 0 14.512-1.341 19.344-3.565-3.181 3.552-8.64 6.898-18.011 6.898zM82.542-5.458a2.951 2.951 0 0 1-.112.8v.008c-.387 1.392-1.771 2.8-3.936 4.043a12.968 12.968 0 0 0-5.739-4.1l7.075-4.717c1.77 1.307 2.712 2.696 2.712 3.966z" fill="#181849"
                    id="Path_263"
                    transform="translate(3.125 14.792)"/>
              <path class="cls-3"
                    d="M51.375-.125a15.016 15.016 0 0 1-15-15 15.016 15.016 0 0 1 15-15 15.016 15.016 0 0 1 15 15 15.016 15.016 0 0 1-15 15zm0-26.667a11.681 11.681 0 0 0-11.667 11.667A11.681 11.681 0 0 0 51.375-3.458a11.681 11.681 0 0 0 11.667-11.667 11.681 11.681 0 0 0-11.667-11.667z"
                    id="Path_264" transform="translate(10.625 3.125)"/>
              <path class="cls-3"
                    d="M52.792-12.04a1.662 1.662 0 0 1-1.179-.488l-12-12a1.667 1.667 0 0 1 0-2.357 1.663 1.663 0 0 1 2.357 0l12 12a1.667 1.667 0 0 1 0 2.357 1.662 1.662 0 0 1-1.178.488z"
                    id="Path_265"
                    transform="translate(15.208 7.707)"/>
              <path class="cls-3"
                    d="M40.792-12.04a1.662 1.662 0 0 1-1.179-.488 1.667 1.667 0 0 1 0-2.357l12-12a1.663 1.663 0 0 1 2.357 0 1.667 1.667 0 0 1 0 2.357l-12 12a1.662 1.662 0 0 1-1.178.488z"
                    id="Path_266" transform="translate(15.208 7.707)"/>
          </g>
      </svg>

        <div class="not-found-content-code">
          {{ 'ORDERS.ORGANIZATION' | translate }}&nbsp;
          {{ organizationId }} &nbsp;
          {{ 'ORDERS.ORGANIZATION_NOT_FOUND' | translate }}
        </div>
        <h3 class="not-found-content-title">{{ 'ORDERS.CONTACT_SUPPORT' | translate }}</h3>
    </div>
  </div>
</div>
