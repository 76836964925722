import moment from 'moment';

export const getHumanizedPast = (date: Date, currentLang: string) => {
  const nowDate = new Date();
  const nowDateUTC = new Date(nowDate.getTime() + (nowDate.getTimezoneOffset() * 60000));
  const targetDate = new Date(date);
  moment.locale(currentLang);
  const a = moment(nowDateUTC);
  const b = moment(targetDate);

  const years = a.diff(b, 'years');
  if (years > 0) {
    return moment.duration(years, 'years').humanize();
  }

  const months = a.diff(b, 'months');
  if (months > 0) {
    return moment.duration(months, 'months').humanize();
  }

  const days = a.diff(b, 'days');
  if (days > 0) {
    return moment.duration(days, 'days').humanize();
  }

  const hours = a.diff(b, 'hours');
  if (hours > 0) {
    return moment.duration(hours, 'hours').humanize();
  }

  const minutes = a.diff(b, 'minutes');
  if (minutes > 0) {
    return moment.duration(minutes, 'minutes').humanize();
  }

  const seconds = a.diff(b, 'seconds');
  return moment.duration(seconds, 'seconds').humanize();
};

export const getHumanizedPastFull = (date: Date, currentDate: Date, currentLang: string) => {
  moment.locale(currentLang);
  let result = '';

  var duration = moment.duration(currentDate.getTime() - date.getTime());
  var days = Math.floor(duration.asDays());
  if (days > 0) {
    result += `${days} ${getDaysDesc(days, currentLang)} `;
  }

  var hours = Math.floor(duration.asHours()) - days * 24;
  if (hours > 0) {
    result += `${hours} ${getHoursDesc(hours, currentLang)} `;
  }

  var mins = Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
  if (mins > 0) {
    result += `${mins} ${getMinutesDesc(mins, currentLang)} `;
  }

  return result;
};

export const getMinutesDesc = (minutes: number, lang: string) => {
  return getHumanizeDesc(minutes, lang, {
    'en': ['minute', 'minutes'],
    'ru': ['минуту', 'минуты', 'минут'],
  });
};

export const getHoursDesc = (hours: number, lang: string) => {
  return getHumanizeDesc(hours, lang, {
    'en': ['hour', 'hours'],
    'ru': ['час', 'часа', 'часов'],
  });
};

export const getDaysDesc = (days: number, lang: string) => {
  return getHumanizeDesc(days, lang, {
    'en': ['day', 'days'],
    'ru': ['день', 'дня', 'дней'],
  });
};

const getHumanizeDesc = (duration: number, lang: string, desc: object) => {
  if (lang === 'en') {
    if (duration === 1) {
      return desc[lang][0];
    }
    return desc[lang][1];
  } else if (lang === 'ru') {
    const durationEnd = duration % 10;
    if (durationEnd === 1) {
      return desc[lang][0];
    } else if (durationEnd > 1 && durationEnd < 5) {
      return desc[lang][1];
    }
    return desc[lang][2];
  }
  return 'lang-unknown';
};