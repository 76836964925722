<div class="container">
  <div class="head">{{ 'GECO_POS.LIST_TITLE' | translate }}</div>

  <div class="filter">
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{"USERS.SELECT_ORGANIZATION" | translate}}</mat-label>
      <input type="text"
             [placeholder]="'USERS.ORGANIZATION_TITLE' | translate"
             aria-label="Number"
             matInput
             [formControl]="autocompleteControl"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of autocompleteItems | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="table-container mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows>

      <ng-container
        *ngFor="let column of columnsToDisplay"
        matColumnDef="{{ column }}">
        <th mat-header-cell *matHeaderCellDef>{{ getColumnHeader(column) }}</th>
        <td [class.parent-cell]="!element.parentId" [class.child-cell]="element.parentId" class="cell" mat-cell
            *matCellDef="let element">
          <div [ngSwitch]="column">

            <ng-template ngSwitchCase="name">
              <b>{{ element[column] }}</b>
            </ng-template>

            <ng-template ngSwitchCase="gecoPos.apiKey">
              <ng-template [ngIf]="element.gecoPosOrganization">
                <p>
                  <b>apiKey</b>: {{ element.gecoPosOrganization?.apiKey  }}<br>
                  <b>orderCreateWebhookUrl</b>: {{ element.gecoPosOrganization?.orderCreateWebhookUrl }}<br>
                  <b>orderStatusUpdateWebhookUrl</b>: {{ element.gecoPosOrganization?.orderStatusUpdateWebhookUrl }}<br>
                  <b>stopListUpdateWebhookUrl</b>: {{ element.gecoPosOrganization?.stopListUpdateWebhookUrl }}
                </p>
              </ng-template>
            </ng-template>

            <ng-template ngSwitchCase="edit">
              <div class="icons-cell">
                <mat-icon
                  (click)="editOrganization(element['id'], element['name'], element.gecoPosOrganization)"
                  color="{{ element.gecoPosOrganization ? 'accent' : '' }}"
                  matTooltip="{{ (element.gecoPosOrganization ? 'GECO_POS.TOOLTIP_EDIT'  : 'GECO_POS.TOOLTIP_CREATE') | translate }}"
                  matTooltipPosition="above">edit
                </mat-icon>
              </div>
            </ng-template>

            <ng-template ngSwitchDefault>
              {{ element[column] }}
            </ng-template>

          </div>

        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnsToDisplay; sticky: true">
      </tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay">
      </tr>
    </table>
  </div>
</div>
