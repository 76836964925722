import { OrderStatus, OrderStatusInfo } from 'app/models/orderStatus.model';
import { Organization } from 'app/models/organization.model';
import { IExternalSystem } from 'app/models/externalSystem.model';

export enum MasterOrderState {
  NEW = 0,
  ACCEPTED = 1,
  COOKING = 2,
  READY_TO_SHIP = 3,
  SHIPPED = 4,
  DELIVERED = 5,
  CLOSED = 6,
  CANCELLED = 7,
}

export interface ModifierGroupItem {
  name: string;
}

export interface OrderInfoDtoItemModifierGroup {
  items: ModifierGroupItem[];
}

export interface OrderInfoDtoItemGroup {
  name: string;
  items: OrderInfoDtoItem[];
}

export interface OrderInfoDtoItem {
  name: string;
  sizeName: string;
  quantity: number;
  price: number;
  groups: OrderInfoDtoItemGroup[];
  modifierGroups: OrderInfoDtoItemModifierGroup[];
}

export interface OrderDiscount {
  name: string;
  type: string;
  discountValue: number;
}

export interface OrderPayment {
  isPaid: boolean;
  type: string;
}

export class OrderInfoDto {
  items: OrderInfoDtoItem[];
  discounts: OrderDiscount[];
  payments: OrderPayment[];
  comment: string;
  promoCode: string;
  sum: number;
  orderNumber: string;

  isGoOrder: boolean;
  deliveryInfo: DeliveryInfo | null;
  courierInfo: CourierInfo | null;
  clientInfo: ClientInfo | null;
}

export interface CourierInfo {
  courierId: string;
  courierName: string;
}

export enum AddressType {
  NONE,
  UAE,
  RU,
}

export interface DeliveryInfo {
  addressType: AddressType;
  isTakeAway: boolean;
  country?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
  entrance?: string;
  doorphone?: string;
  floor?: string;
  flat?: string;
  area?: string;
  address: string;
  latitude: number;
  longitude: number;
  comment?: string;
  postCode?: string;
}

export interface ClientInfo {
  name: string | null;
  email: string | null;
  phone: string | null;
  comment: string | null;
}

export class ReflectedOrder {
  id: string;
  externalSystem: IExternalSystem;
  externalSystemType: number;
  updatedAt: Date;
  createdAt: Date;
  sourceHumanOrderId: string;
  orderInfoDto: OrderInfoDto;
  status: MasterOrderState;
}

export interface OrderInQueue {
  id: string;
  reflectedSourceOrder: ReflectedOrder;
  reflectedTargetOrder: ReflectedOrder;
  status: OrderStatus;
  statusInfo: OrderStatusInfo;
}

export class MasterOrder {
  id: string;
  reflectedOrders: ReflectedOrder[];
  orderInQueues: OrderInQueue[];
  finalPosOrderInQueue: OrderInQueue;
  posOrder: ReflectedOrder;
  courierOrder?: ReflectedOrder;
  sourceOrder: ReflectedOrder;
  createdAt: Date;
  organizationId: string;
  organization: Organization;
  orderState: number;
  metadata: MetadataResponse;
}

export interface ClientInfoResponse {
  name: string;
  email?: string;
  phone?: string;
  comment?: string;
}

export interface DeliveryInfoResponse {
  addressType: number;
  isTakeAway: boolean;
  country?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
  entrance?: string;
  doorphone?: string;
  floor?: string;
  flat?: string;
  area?: string;
  address: string;
  latitude: number;
  longitude: number;
  comment?: string;
  postCode?: string;
}

export interface PaymentResponse {
  type: string;
  sum: number;
  isPaid: boolean;
}

export interface DiscountResponse {
  name?: string;
  type?: string;
  discountValue: number;
  appliedOn: number;
  maxDiscountValue: number;
  comment?: string;
}

export interface ModifierResponse {
  id: string;
  name: string;
  price: number;
  quantity: number;
}

export interface ModifierGroupResponse {
  id: string;
  name: string;
  modifiers: ModifierResponse[];
}

export interface GroupsResponse {
  "id": string,
  "name": string,
  "items": OrderInfoDtoItemResponse[]
}

export interface OrderInfoDtoItemResponse {
  sizeId?: string;
  sizeName?: string;
  groups?: GroupsResponse[];
  modifierGroups?: ModifierGroupResponse[];
  id: string;
  name: string;
  quantity: number;
  price: number;
  isCombo: boolean;
}

export interface OrderInfoDtoResponse {
  sourceSystem: number;
  restaurantId: string;
  orderType?: string;
  deliveryType: string;
  orderNumber: string;
  clientInfo?: ClientInfoResponse;
  deliveryInfo?: DeliveryInfoResponse;
  creationDateTime: string;
  discoveredAt: string;
  comment: string;
  payments: PaymentResponse[];
  discounts: DiscountResponse[];
  items?: OrderInfoDtoItemResponse[];

  sum: number;
  promoCode?: string;
  deliveryCharge: number;
  sourceHumanOrderId?: string;
  targetHumanOrderId?: string;
  courierInfo?: {
    courierId: string,
    courierName: string
  };
  orderStatusDateTime?: {
    newDateTime?: string | null;
    acceptedDateTime?: string | null;
    cookingDateTime?: string | null;
    readyToShipDateTime?: string | null;
    shippedDateTime?: string | null;
    deliveredDateTime?: string | null;
    closedDateTime?: string | null;
    cancelledDateTime?: string | null;
    unprocessedDateTime?: string | null;
    unconfirmedDateTime?: string | null;
    waitCookingDateTime?: string | null;
    readyForCookingDateTime?: string | null;
    cookingStartedDateTime?: string | null;
    cookingCompletedDateTime?: string | null;
    waitingDateTime?: string | null;
    onWayDateTime?: string | null;
  };
  marketingSource?: string;
  isGoOrder: boolean;
  completeBefore?: string;
}

export interface StatusHistoryResponse {
  dateTime: string;
  status: number;
}

export interface MetadataResponse {
  orderInfoDto?: OrderInfoDtoResponse;
  sourceHumanOrderId?: string;
  targetHumanOrderId?: string;
  menuId?: string;
  lastTargetStatus: number;
  lastSourceStatus: number;
  statusHistory: StatusHistoryResponse[];
  paymentLink?: string;
  calculateInfo?: string;
  tags?: string;
  utcDeliveryTime?: string;
}

export interface ReflectedOrderResponse {
  id: string;
  externalOrderId: string;
  rawOrder: string;
  metadata: MetadataResponse;
  externalSystemType: number;
  orderCreationAt: string;
  updatedAt: string;
  externalSystemConfigurationId: string;
  orderState: MasterOrderState;
}

export interface OrderInQueueResponse {
  id: string;
  reflectedSourceOrderId: string;
  reflectedTargetOrderId: string;
  status: OrderStatus;
}

export interface MasterOrderResponse {
  id: string;
  reflectedOrders: ReflectedOrderResponse[];
  orderInQueues: OrderInQueueResponse[];
  createdAt: string;
  organizationId: string;
  metadata: MetadataResponse;
  orderState: number;
}
