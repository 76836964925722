import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Organization } from 'app/models/organization.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { combineLatest, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-organization-pages',
  templateUrl: './organization-pages.component.html',
  styleUrls: ['./organization-pages.component.scss']
})
export class OrganizationPagesComponent implements OnInit {

  currentOrganization: Organization;
  isLoading = true;

  @Select(OrganizationsState.getActiveBranches) organizations$: Observable<Organization[]>;
  @Select(OrganizationsState.getSelectedOrganization) getSelectedOrganization$: Observable<Organization>;

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {
  }

  ngOnInit(): void {

    combineLatest([
      this.activatedRoute.params,
      this.getSelectedOrganization$,
      this.organizations$,
    ]).pipe(
      untilDestroyed(this)
    ).subscribe(async ([params, organization, organizations]) => {
      if (!params.currentOrganizationId || params.currentOrganizationId.length === 0) {
        if (!organization || organization.isActive === false) {
          organization = organizations[0];
        }

        // current route has empty organizationId, so replace it
        await this.router.navigate(['..', organization.id], {
          relativeTo: this.activatedRoute
        });
      } else if (organization) {
        this.currentOrganization = organization;
        this.isLoading = false;
      }
    });
  }

}
