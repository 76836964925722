import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: Date | moment.Moment, dateFormat: string, useLocal?: boolean): any {
    let result = moment(value);
    if (useLocal) {
      result = result.add(-new Date().getTimezoneOffset(), 'minutes');
    }
    return moment(result).format(dateFormat);
  }
}
