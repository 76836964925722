import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() orderDiscoveredAtUTC: Date;

  private timerSubscribe;
  timeInDate: Date;
  isBlinking = false;
  expiringTimeInMinutes = 4;

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  init() {
    const currTime = new Date();
    this.showTimer(this.orderDiscoveredAtUTC.getTime() - currTime.getTime() + this.expiringTimeInMinutes * 60 * 1000);
  }

  ngOnDestroy(): void {
    if (this.timerSubscribe) {
      this.timerSubscribe.unsubscribe();
    }
  }

  showTimer(timeDelta: number): void {
    if (timeDelta <= 0) {
      this.timeInDate = new Date(0, 0, 0);
      this.isBlinking = true;
    } else {
      let tmpTime = timeDelta;
      const timer$ = timer(1000, 1000);
      this.timerSubscribe = timer$.subscribe(() => {
        this.timeInDate = new Date(tmpTime);
        tmpTime -= 1000;
        if (tmpTime < 60000) {
          this.isBlinking = true;
        }
        if (tmpTime < 0) {
          this.timeInDate = new Date(0, 0, 0);
          this.stopTimer();
        }
      });
    }
  }

  stopTimer(): void {
    this.timerSubscribe.unsubscribe();
  }

}
