<div
  class="system-tag"
  [matTooltip]="getTooltipText(configuration)"
  matTooltipClass="tooltip"
  matTooltipPosition="above"
>
  <div
    [matTooltip]="validationMessage"
    matTooltipPosition="below">
    <span class="btn-red" *ngIf="status === 'invalid'"></span>
    <span class="btn-green" *ngIf="status === 'online'"></span>
    <span class="btn-yellow" *ngIf="status === 'offline'"></span>
    <span class="btn-grey" *ngIf="status === 'undefined'"></span>
  </div>

  <span class="system-tag-label" (click)="titleClick()">{{configuration?.externalSystemType | externalSystem: this.externalSystems: 'name'}}</span>

  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button *ngIf="allowDownloadNomenclature()" (click)="downloadNomenclature()" mat-menu-item>
      <mat-icon class="menu-icon">cloud_download</mat-icon>
      <span>{{ 'MENU.DOWNLOAD_NOMENCLATURE' | translate}}</span>
    </button>
    <button *ngIf="allowDownloadStopList()" (click)="downloadStopList()" mat-menu-item>
      <mat-icon class="menu-icon">cancel_presentation</mat-icon>
      <span>{{ 'MENU.DOWNLOAD_STOP_LIST' | translate}}</span>
    </button>
    <button *ngIf="allowUpdateWebhook()" (click)="updateWebhook()" mat-menu-item>
      <mat-icon class="menu-icon">backup</mat-icon>
      <span>{{ 'MENU.UPDATE_WEBHOOK' | translate}}</span>
    </button>

    <mat-divider></mat-divider>
    <button (click)="copyId()" mat-menu-item>
      <mat-icon class="menu-icon">file_copy</mat-icon>
      <span>{{ 'COMMON.COPY_ID' | translate}}</span>
    </button>
    <button (click)="move()" mat-menu-item>
      <mat-icon class="menu-icon">forward</mat-icon>
      <span>{{ 'COMMON.MOVE' | translate}}</span>
    </button>
    <button *ngIf="!this.configuration.isDeleted" (click)="remove()" mat-menu-item>
      <mat-icon class="menu-icon">delete_outline</mat-icon>
      <span>{{ 'COMMON.DELETE' | translate}}</span>
    </button>
    <button *ngIf="this.configuration.isDeleted" (click)="restore()" mat-menu-item>
      <mat-icon class="menu-icon">restore</mat-icon>
      <span>{{ 'COMMON.RESTORE' | translate}}</span>
    </button>
  </mat-menu>

  <div class="switchOnOff" *ngIf="supportSwitchStatus()">
    <span (click)="switchOn()" class="switchButton">ON</span>/
    <span (click)="switchOff()" class="switchButton">OFF</span>
  </div>
</div>
