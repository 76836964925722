<div class="container">
  <div class="content">
    <div>
      <mat-checkbox
        [checked]="isAllSelected()"
      (change)="onSelectAllChange($event)">
        Select all
      </mat-checkbox>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button disabled></button>
          <mat-checkbox
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="leafItemSelectionToggle(node)">
            {{node.name}}
          </mat-checkbox>
        </mat-tree-node>

        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox
                  [checked]="descendantsAllSelected(node)"
                  (change)="itemSelectionToggle(node)"
          >{{node.name}}</mat-checkbox>

        </mat-tree-node>
      </mat-tree>
    </div>
  </div>
</div>
