import { Component, Input, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-preloader',
  template: `
    <div *ngIf="show">
      <mat-progress-bar mode="indeterminate" color="accent" strokeWidth="4"></mat-progress-bar>
    </div>
    <ng-content *ngIf="!show"></ng-content>
  `,
})
export class PreloaderComponent implements OnInit {
  @Input() show: boolean = true;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.show = false;
      }
    });
  }
}
