import { createReducer } from '@reduxjs/toolkit';
import * as actions from 'app/reducers/context.actions';
import { ApplicationUser } from 'app/models/user.model';


export const AuthFeatureKey = 'auth';

export interface AuthState {
  loginData: ApplicationUser | null;
  language: string;
  tokenInfo: TokenInfo | null;
}

export interface TokenInfo {
  token: string;
  refreshToken: string;
  tokenExpires: Date
}

interface ContextLocalStorage {
  version: number;
  lang: string;
  tokenInfo: TokenInfoLocalStorage | null;
  loginData: ApplicationUserLocalStorage | null;
}

interface TokenInfoLocalStorage {
  token: string;
  refreshToken: string;
  tokenExpires: string;
}

interface ApplicationUserLocalStorage {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  organizationId?: string;
}

const VERSION = 1;
const localState: ContextLocalStorage = localStorage.getItem(AuthFeatureKey) ? JSON.parse(localStorage.getItem(AuthFeatureKey)) : null;
let tokenInfo: TokenInfo | null = null;
let loginData: ApplicationUser | null = null;
let language: string = 'en';
if (localState && localState.version === VERSION) {
  tokenInfo = {
    token: localState.tokenInfo.token,
    refreshToken: localState.tokenInfo.refreshToken,
    tokenExpires: new Date(localState.tokenInfo.tokenExpires),
  }
  loginData = localState.loginData;
  language = localState.lang;
}
export const initialAuthState: AuthState = {
  tokenInfo: tokenInfo,
  loginData: loginData,
  language: language,
};

export const authReducer = createReducer<AuthState>(initialAuthState, (builder) => {

  builder.addCase(actions.signInUser, (state, action) => {
    return {
      ...state,
      loginData: null,
      tokenInfo: null
    };
  });
  builder.addCase(actions.signInBackDoorUser, (state) => {
    return {
      ...state,
      loginData: null,
    };
  });
  builder.addCase(actions.signOutUser, (state) => {
    return {
      ...state,
      loginData: null,
      tokenInfo: null
    };
  });
  builder.addCase(actions.signInSuccess, (state, action) => {
    return {
      ...state,
      loginData: action.payload.userData,
      tokenInfo: action.payload.tokenInfo
    };
  });
  builder.addCase(actions.refreshLoginData, (state, action) => {
    return {
      ...state,
      loginData: action.payload.userData,
      tokenInfo: action.payload.tokenInfo
    };
  });
  builder.addCase(actions.setLanguage, (state, action) => {
    return {
      ...state,
      language: action.payload,
    };
  });
  builder.addMatcher((action) => true, (state) => {
    if (state.tokenInfo) {
      localStorage.setItem(AuthFeatureKey, JSON.stringify({
        version: VERSION,
        loginData: state.loginData,
        tokenInfo: state.tokenInfo
      }));
    } else {
      localStorage.removeItem(AuthFeatureKey)
    }
  });
});
