import { Injectable } from '@angular/core';
import {
  ComboGroup,
  DisplayedProduct,
  DisplayedSize, GecoPosMenu,
  Group,
  ModifierGroup,
  OverridePosition,
  OverridePositionsMap,
  Product,
  Size,
} from 'app/models/menu.model';
import { MenuOverrideService } from 'app/services/menu-override.service';
import { MenuState } from 'app/reducers/menu.reducer';

@Injectable({
  providedIn: 'root'
})
export class MenuConverter {
  constructor(
  ) {
  }

  public convertMenu(
    gecoPosMenu: GecoPosMenu,
    overridePositions: OverridePosition[]): MenuState{
    const originalDictionaryGroups = new Map<string, Group>();
    const dictionaryProducts = new Map<string, DisplayedProduct>();
    const originalDictionaryProducts = new Map<string, Product>();
    const dictionarySizes = new Map<string, DisplayedSize>();
    const originalDictionarySizes = new Map<string, Size>();
    const dictionaryModifierGroups = new Map<string, ModifierGroup>();
    const originalDictionaryModifierGroups = new Map<string, ModifierGroup>();
    const dictionaryComboGroups = new Map<string, ComboGroup>();
    const originalDictionaryComboGroups = new Map<string, ComboGroup>();

    for (let originalGroup of gecoPosMenu.groups) {
      originalDictionaryGroups.set(originalGroup.externalId, originalGroup);
    }

    const dictionaryGroups = MenuOverrideService.overrideMenu(originalDictionaryGroups, overridePositions);

    const overridePositionsMap = new OverridePositionsMap(overridePositions);
    for (const product of gecoPosMenu.products) {
      originalDictionaryProducts.set(product.externalId, product);
      dictionaryProducts.set(product.externalId, MenuOverrideService.overrideProduct(JSON.parse(JSON.stringify(product)), overridePositionsMap));
    }
    for (const size of gecoPosMenu.sizes) {
      originalDictionarySizes.set(size.externalId, size);
      dictionarySizes.set(size.externalId, MenuOverrideService.overrideSize(JSON.parse(JSON.stringify(size)), overridePositions));
    }
    for (const modifierGroup of gecoPosMenu.modifierGroups) {
      originalDictionaryModifierGroups.set(modifierGroup.externalId, modifierGroup);
      dictionaryModifierGroups.set(modifierGroup.externalId, MenuOverrideService.overrideModifierGroup(JSON.parse(JSON.stringify(modifierGroup)), overridePositions));
    }
    for (const comboGroup of gecoPosMenu.comboGroups) {
      originalDictionaryComboGroups.set(comboGroup.externalId, comboGroup);
      dictionaryComboGroups.set(comboGroup.externalId, MenuOverrideService.overrideComboGroup(JSON.parse(JSON.stringify(comboGroup)), overridePositions));
    }
    const resultMenuState = {
      dictionaryGroups: dictionaryGroups,
      originalDictionaryGroups: originalDictionaryGroups,
      dictionaryProducts: dictionaryProducts,
      originalDictionaryProducts: originalDictionaryProducts,
      dictionarySizes: dictionarySizes,
      originalDictionarySizes: originalDictionarySizes,
      dictionaryModifiersGroups: dictionaryModifierGroups,
      originalDictionaryModifiersGroups: originalDictionaryModifierGroups,
      dictionaryComboGroup: dictionaryComboGroups,
      originalDictionaryComboGroup: originalDictionaryComboGroups,
    } as MenuState;

    return resultMenuState;
  }
}
