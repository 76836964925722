<app-gmap
  
  #gmapComponent
  (infoWindowClick)="changeSelectedPath($event)" 
  [destinationCoords]="destinationCoords" 
  [startCourierCoords]="startCourierCoords" [currentCourierMarkers]="currentCouirierMarkers">
</app-gmap>

<div #items class="items">
  <mat-accordion>
    <mat-expansion-panel #matExpansionPanel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="items-header">
            <div class="items-label">
              {{'GMAP.ORDERS_ON_WAY' | translate}}
              <span class="items-count">4</span>
            </div>
            <mat-icon class="items-close">close</mat-icon>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-button-toggle-group class="items-group">
        <mat-button-toggle (click)="changeSelectedPath(0)" class="items-group-item">
          <div class="items-item">

            <div class="items-item-top">
              <div class="items-item-id">AZ04557</div>
              <div class="items-item-price">
                <div class="items-item-price-currency">AED</div>
                439.50
              </div>
            </div>

            <div class="items-item-bot">
              <div class="items-item-service">GoFood</div>
              <div class="items-item-time">12:15</div>
            </div>

          </div>
        </mat-button-toggle>
        <mat-button-toggle (click)="changeSelectedPath(1)" class="items-group-item">
          <div class="items-item">

            <div class="items-item-top">
              <div class="items-item-id">AZ04557</div>
              <div class="items-item-price">
                <div class="items-item-price-currency">AED</div>
                439.50
              </div>
            </div>

            <div class="items-item-bot">
              <div class="items-item-service">GoFood</div>
              <div class="items-item-time">12:15</div>
            </div>

          </div>
        </mat-button-toggle>
        <mat-button-toggle (click)="changeSelectedPath(2)" class="items-group-item">
          <div class="items-item">

            <div class="items-item-top">
              <div class="items-item-id">AZ04557</div>
              <div class="items-item-price">
                <div class="items-item-price-currency">AED</div>
                439.50
              </div>
            </div>

            <div class="items-item-bot">
              <div class="items-item-service">GoFood</div>
              <div class="items-item-time">12:15</div>
            </div>

          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

    </mat-expansion-panel>
  </mat-accordion>

  
  
</div>

<div #description class="description">

  <div class="description-close-wrapper">
    <mat-icon (click)="closeDescription()" class="description-close">close</mat-icon>
  </div>
  
  <div class="details">
    <div class="details-title">{{'GMAP.DETAILS' | translate}}</div>

    <div class="details-order-created">
      <div class="details-order-created-label">{{'GMAP.ORDER_CREATED' | translate}}</div>
      <div class="details-order-created-value">Nov 3, 2021 10:22</div>
    </div>
    
  
    <div class="details-order-id">
      <div class="details-order-id-left">
        <div class="details-order-id-label">{{'GMAP.ORDER_ID' | translate}}</div>
        <div class="details-order-id-value">AB93445</div>
      </div>
      <button mat-button class="details-order-id-button">{{'GMAP.GO_TO_ORDER' | translate}}</button>
    </div>
  
    <div class="details-organization">
      <div class="details-organization-label">{{'GMAP.ORGANIZATION' | translate}}</div>
      <div class="details-organization-value">Black Tap Craft Burgers& Shakes DFC</div>
    </div>
  
  
    <div class="details-source-target">
      <div class="details-source">
        <div class="details-source-label">{{'GMAP.SOURCE' | translate}}</div>
        <div class="details-source-value">Delivery Hero</div>
      </div>
  
      <div class="details-target">
        <div class="details-target-label">{{'GMAP.TARGET' | translate}}</div>
        <div class="details-target-value">Tawseelah</div>
      </div>
    </div>
  </div>
  

  <div class="delivering">
    <div class="delivering-title">
      {{'GMAP.DELIVERING' | translate}}
    </div>

    <div class="delivering-customer">
      <div class="delivering-customer-label">
        {{'GMAP.CUSTOMER' | translate}}
      </div>
      <div class="delivering-customer-name">Mr. John Doe</div>
      <div class="delivering-customer-telephone">+971 52 834 94 55</div>
      <div class="delivering-customer-address">Room 556, 308th Rd,
        TradeCentre 1, Dubai, UAE</div>
    </div>

    <div class="delivering-service">
      <div class="delivering-service-label">{{'GMAP.DELIVERY_SERVICE' | translate}}</div>
      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Ikea_logo.svg/1280px-Ikea_logo.svg.png" class="delivering-service-logo"/>
    </div>

    <div class="delivering-courier">
      <div class="delivering-courier-label">{{'GMAP.COURIER' | translate}}</div>
      <div class="delivering-courier-value">Bhatt Chabra</div>
      <div class="delivering-courier-status">{{'GMAP.WAITING_FOR_COOKING' | translate}}</div>
    </div>

    <div class="delivering-payment">
      <div class="delivering-payment-label">{{'GMAP.PAYMENT' | translate}}</div>
      <div class="delivering-payment-value">
        <mat-icon class="delivering-payment-value-icon">credit_card</mat-icon>{{'GMAP.CREDIT_CARD_ON_DELIVERY' | translate}}</div>
    </div>

  </div>
</div>

<!-- <h1 style="position: absolute; right: 0; top: 0;" (click)="changeSelectedPath(0)">CHANGE 1</h1>
<h1 style="position: absolute; right: 0; top: 50;" (click)="changeSelectedPath(1)">CHANGE 2</h1>
<h1 style="position: absolute; right: 0; top: 100;" (click)="changeSelectedPath(2)">CHANGE 3</h1> -->