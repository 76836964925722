import { Injectable } from '@angular/core';
import {
  ComboGroup,
  ComboGroupOverride,
  ComboProductStructureOverride, DisplayedGroup,
  DisplayedProduct,
  DisplayedSize,
  emptyOverride,
  Group,
  GroupOverride,
  GroupStructureOverride,
  JsonObjectTypeEnum,
  MenuOverride,
  ModifierGroup,
  ModifierGroupOverride,
  ModifierGroupStructure,
  ModifierProductStructureOverride, NameTranslationType,
  OverrideInstruction,
  OverrideInstructionEnum,
  OverridePosition,
  OverridePositionRequest, OverridePositionsMap,
  PositionTypeEnum,
  Product,
  ProductOverride,
  ProductStructureOverride,
  Size,
  SizeOverride,
  SizeStructureOverride
} from 'app/models/menu.model';
import { menuHelper } from 'app/helpers/menuHelper';

@Injectable({
  providedIn: 'root'
})
export class MenuOverrideService {

  constructor() {
  }

  static createOrUpdateProductIdsInGroupStructure(newProductIds: string[] | null, positionId: string, menuSectionId: string, overridePositions: OverridePosition[]): OverridePositionRequest {
    let overridePositionRequest: OverridePositionRequest;
    const overrideGroupStructure = overridePositions.find(override => override.positionType === PositionTypeEnum.GroupStructure && override.positionId === positionId);

    if (overrideGroupStructure) {
      const groupStructureOverride: GroupStructureOverride = JSON.parse(overrideGroupStructure.override);
      overridePositionRequest = {
        ...overrideGroupStructure,
        override: JSON.stringify(groupStructureOverride),
        instructions: [{
          key: 'productIds',
          value: newProductIds? JSON.stringify(newProductIds) : null,
          type: JsonObjectTypeEnum.ArrayType,
          instruction: newProductIds? OverrideInstructionEnum.Change : OverrideInstructionEnum.Delete,
        }]
      };
    } else {
      const groupStructureOverride: GroupStructureOverride = {
        productIds: newProductIds,
      };
      overridePositionRequest = {
        positionType: PositionTypeEnum.GroupStructure,
        positionId: positionId,
        menuSectionId: menuSectionId,
        override: JSON.stringify(groupStructureOverride),
        instructions: [{
          key: 'productIds',
          value: JSON.stringify(newProductIds),
          type: JsonObjectTypeEnum.ArrayType,
          instruction: OverrideInstructionEnum.Add
        }]
      };
    }

    return overridePositionRequest;
  }

  static saveGroupIdsInMenu(newGroupIds: string[] | null, menuId: string, menuSectionId: string, overridePositions: OverridePosition[]): OverridePositionRequest {
    let overridePositionRequest: OverridePositionRequest;
    const positionId = menuId;
    const menuOverridePosition = overridePositions.find(override => override.positionType === PositionTypeEnum.Menu && override.positionId === positionId);
    if (menuOverridePosition) {
      const menuOverride: MenuOverride = JSON.parse(menuOverridePosition.override);
      const newMenuOverride = {...menuOverride, groupIds: newGroupIds};
      overridePositionRequest = {
        ...menuOverridePosition,
        override: JSON.stringify(newMenuOverride),
        instructions: [{
          key: 'groupIds',
          value: newGroupIds !== null ? JSON.stringify(newGroupIds) : null,
          type: JsonObjectTypeEnum.ArrayType,
          instruction: newGroupIds !== null? OverrideInstructionEnum.Change : OverrideInstructionEnum.Delete,
        }]
      };
    } else {
      const groupStructureOverride: MenuOverride = {
        groupIds: newGroupIds,
      };
      overridePositionRequest = {
        positionType: PositionTypeEnum.Menu,
        positionId: positionId,
        menuSectionId: menuSectionId,
        override: JSON.stringify(groupStructureOverride),
        instructions: [{
          key: 'groupIds',
          value: newGroupIds !== null ? JSON.stringify(newGroupIds) : null,
          type: JsonObjectTypeEnum.ArrayType,
          instruction: OverrideInstructionEnum.Add
        }]
      };
    }

    return overridePositionRequest;
  }

  static overrideMenu(originalGroupsMap: Map<string, Group>, overridePositions: OverridePosition[]): Map<string, DisplayedGroup> {
    const result = new Map<string, DisplayedGroup>();

    const overridePosition = overridePositions.find(
      override => override.positionType === PositionTypeEnum.Menu);
    if (overridePosition) {
      let order = 0;
      const menuOverride: MenuOverride = JSON.parse(overridePosition.override);
      if (menuOverride.groupIds) {
        for (let groupId of menuOverride.groupIds) {
          const group = originalGroupsMap.get(groupId);
          // Если группа не найдена, значит она скорее всего уже была удалена в момент последней выгрузки из внешней pos в gecoPos,
          if (group != null){
            result.set(group.externalId, MenuOverrideService.overrideGroup(group, order++, overridePositions));
          }
        }
      }
    }
    else{
      let order = 0;
      for (let [externalId, group] of originalGroupsMap) {
        result.set(externalId, MenuOverrideService.overrideGroup(group, order++, overridePositions));
      }
    }

    return result;
  }

  static overrideGroup(originalGroup: Group, order: number, overridePositions: OverridePosition[]): DisplayedGroup {
    let group: DisplayedGroup = {
      ...originalGroup,
      order: order,
      groupStructure: {...originalGroup.groupStructure},
      hasReOrderingProductInGroup: false,
      assortmentHasChanged: false,
      isChangedName: false,
      isFiltered: false};

    const overrideGroupPosition = overridePositions.find(override => override.positionType === PositionTypeEnum.Group && override.positionId === group.externalId);
    const overrideGroupStructurePosition = overridePositions.find(override => override.positionType === PositionTypeEnum.GroupStructure && override.positionId === group.externalId);
    if (overrideGroupPosition) {
      const overrideGroup = JSON.parse(overrideGroupPosition.override) as GroupOverride;
      if (overrideGroup.name != null) {
        group.isChangedName = true;
        group.name = overrideGroup.name;
      }
      if (overrideGroup.tags != null) {
        group.tags = overrideGroup.tags;
      }
      if (overrideGroup.externalPictureUrl != null) {
        group.externalPictureUrl = overrideGroup.externalPictureUrl;
      }
      if (overrideGroup.description != null) {
        group.description = overrideGroup.description;
      }
      if (overrideGroup.nameTranslations != null) {
        group.nameTranslations = overrideGroup.nameTranslations;
      }
      if (overrideGroup.descriptionTranslations != null) {
        group.descriptionTranslations = overrideGroup.descriptionTranslations;
      }
    }

    if (overrideGroupStructurePosition) {
      const overrideGroupStructure = JSON.parse(overrideGroupStructurePosition.override) as GroupStructureOverride;

      if (overrideGroupStructure.productIds != null && overrideGroupStructure.productIds.length) {
        let sortIndex = 0;
        group.groupStructure.productsInGroup = overrideGroupStructure.productIds.map(id => ({externalProductId: id, order: sortIndex++}));
      }
      if(overrideGroupStructure.parentId != null){
        group.groupStructure.parentId = overrideGroupStructure.parentId;
      }
    }

    menuHelper.CheckAssortmentAndChangeGroup(group, originalGroup);

    return group;
  }

  static overrideProduct(product: Product, overridePositions: OverridePositionsMap): DisplayedProduct {
    let displayedProduct: DisplayedProduct = product as DisplayedProduct;

    displayedProduct.isAnyChanged = overridePositions.models.some(
      override => override.positionId.includes(displayedProduct.externalId)
        && override.positionType != PositionTypeEnum.ProductInGroup && override.override != emptyOverride);

    const overrideProductPosition = overridePositions.getOverride(PositionTypeEnum.Product, displayedProduct.externalId)

    if (overrideProductPosition) {
      const overrideProduct = JSON.parse(overrideProductPosition.override) as ProductOverride;
      if (overrideProduct.nameTranslations || (overrideProduct.name && product.name != overrideProduct.name)){
        displayedProduct.isChangedName = true;
      }
      if (overrideProduct.name != null) {
        displayedProduct.name = overrideProduct.name;
      }
      if (overrideProduct.externalPictureUrl != null) {
        displayedProduct.externalPictureUrl = overrideProduct.externalPictureUrl;
      }
      if (overrideProduct.tags != null) {
        displayedProduct.tags = overrideProduct.tags;
      }
      if (overrideProduct.weight != null) {
        displayedProduct.weight = overrideProduct.weight;
      }
      if (overrideProduct.description != null) {
        displayedProduct.description = overrideProduct.description;
      }
      if (overrideProduct.nameTranslations != null) {
        displayedProduct.nameTranslations = overrideProduct.nameTranslations;
      }
      if (overrideProduct.descriptionTranslations != null) {
        displayedProduct.descriptionTranslations = overrideProduct.descriptionTranslations;
      }
      if (overrideProduct.hideSizesImages != null) {
        displayedProduct.hideSizesImages = overrideProduct.hideSizesImages;
      }
    }

    if (displayedProduct.productStructure) {
      const overrideProductStructurePosition = overridePositions.getOverride(PositionTypeEnum.ProductStructure, displayedProduct.externalId)

      if (overrideProductStructurePosition) {
        const overrideProductStructure = JSON.parse(overrideProductStructurePosition.override) as ProductStructureOverride;
        if (overrideProductStructure.price != null) {
          displayedProduct.isChangedPrice = true;
          displayedProduct.productStructure.isChangedPrice = true;
          displayedProduct.productStructure.price = overrideProductStructure.price;
        }

        if (overrideProductStructure.sizeScaleId != null) {
          displayedProduct.productStructure.isChangedSizeScaleId = true;
          displayedProduct.productStructure.sizeScaleId = overrideProductStructure.sizeScaleId;
        }
      }

      if (displayedProduct.productStructure.sizes) {
        for (const sizeStructure of displayedProduct.productStructure.sizes) {
          const overrideSizeStructurePosition = overridePositions.getOverride(PositionTypeEnum.SizeStructure, `${displayedProduct.externalId}_${sizeStructure.sizeId}`)
          if (overrideSizeStructurePosition) {
            const overrideSizeStructure = JSON.parse(overrideSizeStructurePosition.override) as SizeStructureOverride;
            if (overrideSizeStructure.price != null) {
              sizeStructure.isChangedPrice = true;
              sizeStructure.price = overrideSizeStructure.price;
            }
            if (overrideSizeStructure.isHidden != null) {
              sizeStructure.isHidden = overrideSizeStructure.isHidden;
            }
            if (overrideSizeStructure.order != null) {
              sizeStructure.order = overrideSizeStructure.order;
            }
            if (overrideSizeStructure.externalPictureUrl != null){
              sizeStructure.externalPictureUrl = overrideSizeStructure.externalPictureUrl;
            }
          }
        }
      }

      for (const modifierGroupStructure of displayedProduct.productStructure.modifierGroups) {
        const overrideModifierGroupStructurePosition = overridePositions.getOverride(PositionTypeEnum.ModifierGroupStructure, `${displayedProduct.externalId}_${modifierGroupStructure.modifierGroupId}`)
        if (overrideModifierGroupStructurePosition) {
          const overrideModifierGroupStructure = JSON.parse(overrideModifierGroupStructurePosition.override) as ModifierGroupStructure;
          if (overrideModifierGroupStructure.required != null) {
            modifierGroupStructure.required = overrideModifierGroupStructure.required;
          }
          if (overrideModifierGroupStructure.min != null) {
            modifierGroupStructure.min = overrideModifierGroupStructure.min;
          }
          if (overrideModifierGroupStructure.max != null) {
            modifierGroupStructure.max = overrideModifierGroupStructure.max;
          }
          if (overrideModifierGroupStructure.isHidden != null) {
            modifierGroupStructure.isHidden = overrideModifierGroupStructure.isHidden;
          }
          if (overrideModifierGroupStructure.order != null) {
            modifierGroupStructure.order = overrideModifierGroupStructure.order;
          }
          if (overrideModifierGroupStructure.childModifiersHaveMinMaxRestrictions != null) {
            modifierGroupStructure.childModifiersHaveMinMaxRestrictions = overrideModifierGroupStructure.childModifiersHaveMinMaxRestrictions;
          }
          if (overrideModifierGroupStructure.hideImages != null) {
            modifierGroupStructure.hideImages = overrideModifierGroupStructure.hideImages;
          }
        }

        for (const modifierProductStructure of modifierGroupStructure.modifierProducts) {
          const overrideModifierProductStructurePosition = overridePositions.getOverride(PositionTypeEnum.ModifierProductStructure, `${displayedProduct.externalId}_${modifierProductStructure.modifierId}`)
          if (overrideModifierProductStructurePosition) {
            const overrideModifierProductStructure = JSON.parse(overrideModifierProductStructurePosition.override) as ModifierProductStructureOverride;
            if (overrideModifierProductStructure.min != null) {
              modifierProductStructure.min = overrideModifierProductStructure.min;
            }
            if (overrideModifierProductStructure.max != null) {
              modifierProductStructure.max = overrideModifierProductStructure.max;
            }
            if (overrideModifierProductStructure.defaultAmount != null) {
              modifierProductStructure.defaultAmount = overrideModifierProductStructure.defaultAmount;
            }
            if (overrideModifierProductStructure.isHidden != null) {
              modifierProductStructure.isHidden = overrideModifierProductStructure.isHidden;
            }
            if (overrideModifierProductStructure.order != null) {
              modifierProductStructure.order = overrideModifierProductStructure.order;
            }
          }
        }
      }
    }

    if (displayedProduct.comboStructure) {
      const overrideComboStructurePosition = overridePositions.getOverride(PositionTypeEnum.ComboStructure, displayedProduct.externalId)
      if (overrideComboStructurePosition) {
        const overrideComboProductStructure = JSON.parse(overrideComboStructurePosition.override) as ProductStructureOverride;
        if (overrideComboProductStructure.price != null) {
          displayedProduct.isChangedPrice = true;
          displayedProduct.comboStructure.isChangedPrice = true;
          displayedProduct.comboStructure.price = overrideComboProductStructure.price;
        }
      }

      for (const comboGroup of displayedProduct.comboStructure.groups) {
        for (const comboProduct of comboGroup.products) {
          const overrideComboStructurePosition = overridePositions.getOverride(PositionTypeEnum.ComboProductStructure, getComboStructurePositionId(displayedProduct.externalId, comboProduct.externalProductId, comboProduct.sizeId))
          if (overrideComboStructurePosition) {
            const overrideComboProductStructure = JSON.parse(overrideComboStructurePosition.override) as ComboProductStructureOverride;
            if (overrideComboProductStructure.price != null) {
              comboProduct.price = overrideComboProductStructure.price;
              comboProduct.isChangedPrice = true;
            }
            if (overrideComboProductStructure.isHidden != null) {
              comboProduct.isHidden = overrideComboProductStructure.isHidden;
              comboProduct.isChangedVisibility = true;
            }
          }
        }
      }
    }

    return displayedProduct;
  }

  static overrideSize(size: Size, overridePositions: OverridePosition[]): DisplayedSize {
    let result: DisplayedSize = {...size, isChangedSize: false};
    const overrideSizePosition = overridePositions.find(override => override.positionType === PositionTypeEnum.Size && override.positionId === size.externalId);
    if (overrideSizePosition && overrideSizePosition.override != emptyOverride) {

      const overrideProduct = JSON.parse(overrideSizePosition.override) as SizeOverride;

      if (overrideProduct.name != null) {
        result.name = overrideProduct.name;
      }
      if(overrideProduct.nameTranslations){
        result.nameTranslations = overrideProduct.nameTranslations;
      }
      result.isChangedSize = true;
    }

    return result;
  }

  static overrideModifierGroup(modifierGroup: ModifierGroup, overridePositions: OverridePosition[]): ModifierGroup {
    const overrideModifierGroupPosition = overridePositions.find(override => override.positionType === PositionTypeEnum.ModifierGroup && override.positionId === modifierGroup.externalId);
    if (overrideModifierGroupPosition) {
      const overrideModiferGroup = JSON.parse(overrideModifierGroupPosition.override) as ModifierGroupOverride;
      if (overrideModiferGroup.name != null) {
        modifierGroup.name = overrideModiferGroup.name;
      }

      if (overrideModiferGroup.nameTranslations != null) {
        modifierGroup.nameTranslations = overrideModiferGroup.nameTranslations;
      }
    }

    return modifierGroup;
  }

  static overrideComboGroup(comboGroup: ComboGroup, overridePositions: OverridePosition[]): ComboGroup {
    const overrideComboGroupPosition = overridePositions.find(override => override.positionType === PositionTypeEnum.ComboGroup && override.positionId === comboGroup.externalId);
    if (overrideComboGroupPosition) {
      const overrideComboGroup = JSON.parse(overrideComboGroupPosition.override) as ComboGroupOverride;
      if (overrideComboGroup.name != null) {
        comboGroup.name = overrideComboGroup.name;
      }
      if (overrideComboGroup.nameTranslations != null) {
        comboGroup.nameTranslations = overrideComboGroup.nameTranslations;
      }
    }

    return comboGroup;
  }

  static changeProductOrders(group: DisplayedGroup, products: Product[], overridePositions: OverridePosition[], menuSectionId: string): OverridePositionRequest  {
    let result: OverridePositionRequest;
    let currentOverridePosition = overridePositions.find(override => override.positionType === PositionTypeEnum.GroupStructure && override.positionId === group.externalId);

    const newProductIds = products.map(p => p.externalId);

    let overrideGroupStructure: GroupStructureOverride = {
      productIds: newProductIds,
    };

    if (currentOverridePosition){
      result = {
        ...currentOverridePosition,
        override: JSON.stringify(overrideGroupStructure),
        instructions: [
          {
            key: 'productIds',
            value: JSON.stringify(newProductIds),
            type: JsonObjectTypeEnum.ArrayType,
            instruction: OverrideInstructionEnum.Change
          }
        ]
      }
    }
    else {
      result = {
        positionId: group.externalId,
        positionType: PositionTypeEnum.GroupStructure,
        menuSectionId: menuSectionId,
        override: JSON.stringify(overrideGroupStructure),
        instructions: [
        {
          key: 'productIds',
          value: JSON.stringify(newProductIds),
          type: JsonObjectTypeEnum.ArrayType,
          instruction: OverrideInstructionEnum.Add
        }]
      }
    }

    return result;
  }

  static overrideTextFiled(updatedObject: any,
                           originalObject: any,
                           propertyKey: string,
                           formValue: string,
                           isOverrideExists: boolean,
                           overrideInstructions: OverrideInstruction[],
                           nullable = false): void {
    if (nullable || formValue) {
      if (isOverrideExists) {
        if (nullable && !formValue ||
          (originalObject.hasOwnProperty(propertyKey) && originalObject[propertyKey] && originalObject[propertyKey].trim() === formValue.trim()
          || !originalObject[propertyKey] && formValue.trim().length === 0)) {
          overrideInstructions.push({
            key: propertyKey,
            value: formValue,
            type: JsonObjectTypeEnum.StringType,
            instruction: OverrideInstructionEnum.Delete
          });
        } else {
          overrideInstructions.push({
            key: propertyKey,
            value: formValue,
            type: JsonObjectTypeEnum.StringType,
            instruction: OverrideInstructionEnum.Change
          });
        }
      } else if (!updatedObject[propertyKey] && formValue.trim().length > 0 ||
        updatedObject[propertyKey] && formValue.trim() !== updatedObject[propertyKey].trim()) {
        overrideInstructions.push({
          key: propertyKey,
          value: formValue,
          type: JsonObjectTypeEnum.StringType,
          instruction: OverrideInstructionEnum.Add
        });
      }
    }
  }

  static overrideArrayFiled(updatedObject: any,
                               originalObject: any,
                               propertyKey: string,
                               formValue: string[],
                               isOverrideExists: boolean,
                               overrideInstructions: OverrideInstruction[]) {
    if (formValue !== null && formValue !== undefined) {
      if (isOverrideExists) {
        if (originalObject.hasOwnProperty(propertyKey) && originalObject[propertyKey] && [...originalObject[propertyKey]].sort().toString() === [...formValue].sort().toString()
          || !originalObject[propertyKey] && formValue.length === 0) {
          overrideInstructions.push({
            key: propertyKey,
            value: JSON.stringify(formValue),
            type: JsonObjectTypeEnum.ArrayType,
            instruction: OverrideInstructionEnum.Delete
          });
        } else {
          overrideInstructions.push({
            key: propertyKey,
            value: JSON.stringify(formValue),
            type: JsonObjectTypeEnum.ArrayType,
            instruction: OverrideInstructionEnum.Change
          });
        }
      } else if ([...updatedObject[propertyKey]].sort().toString() !== [...formValue].sort().toString()) {
        overrideInstructions.push({
          key: propertyKey,
          value: JSON.stringify(formValue),
          type: JsonObjectTypeEnum.ArrayType,
          instruction: OverrideInstructionEnum.Change
        });
      }
    }
  }

  static overrideNumberFiled(
    updatedObject: any,
    originalObject: any,
    propertyKey: string,
    formValue: number,
    isOverrideExists: boolean,
    overrideInstructions: OverrideInstruction[])
    : void
  {
    if (formValue !== null && formValue !== undefined) {
      if (isOverrideExists) {
        if (originalObject.hasOwnProperty(propertyKey) && originalObject[propertyKey] === formValue) {
          overrideInstructions.push({
            key: propertyKey,
            value: '' + formValue,
            type: JsonObjectTypeEnum.NumberType,
            instruction: OverrideInstructionEnum.Delete
          });
        } else {
          overrideInstructions.push({
            key: propertyKey,
            value: '' + formValue,
            type: JsonObjectTypeEnum.NumberType,
            instruction: OverrideInstructionEnum.Change
          });
        }
      } else if (formValue !== updatedObject[propertyKey]) {
        overrideInstructions.push({
          key: propertyKey,
          value: '' + formValue,
          type: JsonObjectTypeEnum.NumberType,
          instruction: OverrideInstructionEnum.Add
        });
      }
    }
  }

  static overrideBooleanFiled(updatedObject: any,
                                 originalObject: any,
                                 propertyKey: string,
                                 formValue: boolean,
                                 isOverrideExists: boolean,
                                 overrideInstructions: OverrideInstruction[]) {
    if (formValue !== null && formValue !== undefined) {
      if (isOverrideExists) {
        if (originalObject.hasOwnProperty(propertyKey) && originalObject[propertyKey] === formValue) {
          overrideInstructions.push({
            key: propertyKey,
            value: '' + formValue,
            type: JsonObjectTypeEnum.BooleanType,
            instruction: OverrideInstructionEnum.Delete
          });
        } else {
          overrideInstructions.push({
            key: propertyKey,
            value: '' + formValue,
            type: JsonObjectTypeEnum.BooleanType,
            instruction: OverrideInstructionEnum.Change
          });
        }
      } else if (formValue !== updatedObject[propertyKey]) {
        overrideInstructions.push({
          key: propertyKey,
          value: '' + formValue,
          type: JsonObjectTypeEnum.BooleanType,
          instruction: OverrideInstructionEnum.Add
        });
      }
    }
  }

  static overrideTranslationFiled(updatedObject: any,
                                     originalObject: any,
                                     propertyKey: string,
                                     translationKey: string,
                                     formValue: string,
                                     isOverrideExists: boolean,
                                     override: any,
                                     overrideInstructions: OverrideInstruction[]) {
    if (formValue == null || formValue == undefined) {
      return;
    }
    if (isOverrideExists) {
      let translationObj = {...override[propertyKey]};
      // TODO: потенциальное место для ошибок-----------------------------
      //--------Проблема может заключаться в том, что в какой-то момент еще может появиться изменение с типом JsonObjectTypeEnum.ObjectType,
      //--------но на данный момент других инструкций с таким типом нет
      let alreadyExistingTranslateOverrideInstructionIndex = overrideInstructions.findIndex(oi => oi.type === JsonObjectTypeEnum.ObjectType);
      if (alreadyExistingTranslateOverrideInstructionIndex > -1) {
        translationObj = JSON.parse(overrideInstructions[alreadyExistingTranslateOverrideInstructionIndex].value);
      }
      //-------------------------------------------------------------------
      if (formValue.trim().length > 0){
        translationObj[translationKey] = formValue;
      }
      else{
        delete translationObj[translationKey];
      }
      const instruction = {
        key: propertyKey,
        type: JsonObjectTypeEnum.ObjectType,
        instruction: OverrideInstructionEnum.Change
      } as OverrideInstruction;

      if (this.isEqualTranslationObjects(translationObj, originalObject[propertyKey])) {
        // instruction.instruction = OverrideInstructionEnum.Delete; // https://t.me/c/1392392238/65071
        instruction.instruction = OverrideInstructionEnum.Delete;
        instruction.value = emptyOverride;
      }
      else{
        instruction.value = JSON.stringify(translationObj);
      }

      if (alreadyExistingTranslateOverrideInstructionIndex > -1) {
        overrideInstructions[alreadyExistingTranslateOverrideInstructionIndex] = instruction;
      } else {
        overrideInstructions.push(instruction);
      }
    } else if (!updatedObject[propertyKey][translationKey] && formValue.trim().length > 0 ||
      updatedObject[propertyKey][translationKey] && formValue.trim() !== updatedObject[propertyKey][translationKey].trim()) {
      const translationObj = {...updatedObject[propertyKey]};
      translationObj[translationKey] = formValue;
      overrideInstructions.push({
        key: propertyKey,
        value: JSON.stringify(translationObj),
        type: JsonObjectTypeEnum.ObjectType,
        instruction: OverrideInstructionEnum.Change
      });
    }
  }

  static isEqualTranslationObjects(translation: object, originalTranslation: object): boolean{
    let object1 = this.removeEmptyFields({...translation});
    let object2 = this.removeEmptyFields({...originalTranslation});

    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  static removeEmptyFields(obj: object): object{
    for (let key in obj) {
      if (!obj[key] || !obj[key].trim()){
        delete obj[key];
      }
    }
    return obj;
  }
}

export function getComboStructurePositionId(comboProductId: string, productId: string, productSizeId: string){
  return `${comboProductId}_${productId}_${productSizeId}`;
}

export function buildNameTranslations(
  nameTranslations: NameTranslationType,
  additionalLanguages: string[])
  : NameTranslationType
{
  let resultNameTranslations: NameTranslationType = {};

  for (let lang of additionalLanguages) {
    resultNameTranslations[lang] = nameTranslations[lang] ?? '';
  }

  return resultNameTranslations;
}
