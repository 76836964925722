import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { OrderFilter } from 'app/models/orderFilter.model';
import { OrderInQueue } from 'app/models/orderInQueue.model';
import { Organization } from 'app/models/organization.model';
import { SetOrdersList } from 'app/state-management/actions/orders.actions';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { map, Observable, of, take } from 'rxjs';
import { getDefaultOrderListFilter } from 'app/helpers/ordersListFilterHelper';

@Injectable({
  providedIn: 'root'
})
export class OrdersListResolverService implements Resolve<Observable<{ orders: OrderInQueue[], ordersCount: number }> | null> {

  constructor(
    public store: Store,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<{ orders: OrderInQueue[], ordersCount: number }> {
    const organizations = this.store.selectSnapshot<Organization[]>(OrganizationsState.getBranches);
    const currentOrganization = this.store.selectSnapshot<string>(OrganizationsState.getSelectedOrganizationId);
    if (currentOrganization) {
      const organizationsChildren = organizations.filter(o => o.parentId === currentOrganization);
      const tmpOrganizationIds: string[] = organizationsChildren.length ?
        [currentOrganization, ...organizationsChildren.map(o => o.id)] :
        [currentOrganization];
      const defaultValues = getDefaultOrderListFilter();
      const filter: OrderFilter = {
        orderNumber: route.queryParams.orderNumber ?? defaultValues.orderNumber,
        orderStatus: parseInt(route.queryParams.orderStatus, 10) ?? null,
        sourceSystemType: parseInt(route.queryParams.sourceSystemType, 10) ?? null,
        targetSystemType: parseInt(route.queryParams.targetSystemType, 10) ?? null,
        organizationIds: route.queryParams.organizationIds
          ? (Array.isArray(route.queryParams.organizationIds)
            ? [...route.queryParams.organizationIds]
            : [route.queryParams.organizationIds])
          : tmpOrganizationIds,
        skip: route.queryParams.skip ?? defaultValues.skip,
        take: route.queryParams.take ?? defaultValues.take,
        from: route.queryParams.from ? new Date(route.queryParams.from) : defaultValues.fromDate,
        to: route.queryParams.to ? new Date(route.queryParams.to) : defaultValues.toDate,
        tag: route.queryParams.tag,
      };
      return this.store.dispatch(new SetOrdersList(filter))
        .pipe(
          take(1),
          map(res => {
            return {
              orders: res.orders.ordersList,
              ordersCount: res.orders.ordersListCount
            };
          })
        );
    } else {
      return of({orders: [], ordersCount: 0});
    }
  }
}
