import { serverUrl } from "app/helpers/elastic-search/elastic-search-constants";
import { ElasticSearchScopeFields } from "app/helpers/elastic-search/elastic-search-scope-fields";

interface ScopeField{
  key: ElasticSearchScopeFields,
  value: string
}

export const getElasticSearchLogUrl = (indexId: string, dateFrom: any, dateTo: any, scopeFields: ScopeField[]) =>
  `${serverUrl}/app/discover#/?_g=(filters:!(${buildScopeFieldsQuery(indexId, scopeFields)}),refreshInterval:(pause:!t,value:0),time:(from:'${dateFrom}',to:'${dateTo}'))&_a=(columns:!(_source),filters:!(),index:${indexId},interval:auto,query:(language:kuery,query:''),sort:!())`;

const buildScopeFieldsQuery = (indexId: string, scopeFields: ScopeField[]) =>
  scopeFields.reduce(
    (accumulator, currentValue) => accumulator + ',' + buildScopeFilter(indexId, currentValue),
    '').substring(1);

const buildScopeFilter = (indexId: string, scopeField: ScopeField) =>
  `('$state':(store:appState),meta:(alias:!n,disabled:!f,index:${indexId},key:fields.${scopeField.key},negate:!f,params:(query:'${scopeField.value}'),type:phrase),query:(match_phrase:(fields.${scopeField.key}:'${scopeField.value}')))`;