<section *ngIf="order">
    <h3 class="details-header" mat-header>{{ 'ORDERS.DETAILS' | translate }}</h3>
    <div class="details-content">
        <div class="details-info">
            <div class="details-key">{{ 'ORDERS.CREATED_AT' | translate }}</div>
            <div class="details-value">{{ order.discoveredAt | utcToLocal:'DD.MM.YYYY HH:mm:ss' }}</div>
        </div>

        <div class="details-info" *ngIf="order.details">
            <div class="details-key">{{ 'ORDERS.ORDER_NUMBER' | translate }}</div>
            <div class="details-value" *ngIf="!order.details.targetHumanOrderId && !order.details.sourceHumanOrderId">{{order.details?.orderNumber}}</div>
            <ng-container *ngIf="order.metadata.sourceHumanOrderId">
                <div>{{order.sourceSystem | externalSystem : this.externalSystems}} Order ID: {{order.metadata.sourceHumanOrderId}}</div>
            </ng-container>
            <ng-container *ngIf="order.metadata.targetHumanOrderId">
                <div>{{order.targetSystem | externalSystem : this.externalSystems}} Order ID: {{order.metadata.targetHumanOrderId}}</div>
            </ng-container>
        </div>

        <div class="details-info">
            <div class="details-key">{{ 'ORDERS.BRANCH' | translate }}</div>
            <div class="details-value">{{getOrganizationBranchName$() | async}}</div>
        </div>

        <div class="details-info">
            <div class="details-key">{{ 'ORDERS.SOURCE' | translate }}</div>
            <div class="details-value">
                {{order.sourceSystem | externalSystem : this.externalSystems}}

                <button *ngIf="order.metadata?.menuId" (click)="downloadMenu(order.metadata.menuId, false)" mat-icon-button matTooltip="{{ 'MENU.DOWNLOAD_TARGET' | translate}}" matTooltipPosition="above">
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </div>
            <div class="details-value" *ngIf="roleMembership.isSupport">
                ID: {{order.sourceOrderId}}
            </div>
        </div>

        <div class="details-info">
            <div class="details-key">{{ 'ORDERS.TARGET' | translate }}</div>
            <div class="details-value">
                {{order.targetSystem | externalSystem : this.externalSystems}}

                <button *ngIf="order.metadata?.menuId" (click)="downloadMenu(order.metadata.menuId, true)" mat-icon-button matTooltip="{{ 'MENU.DOWNLOAD_SOURCE' | translate}}" matTooltipPosition="above">
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </div>
            <div class="details-value" *ngIf="roleMembership.isSupport">
                ID: {{order.targetOrderId}}
            </div>
        </div>
    </div>
</section>
