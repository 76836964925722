import { createFeatureSelector } from '@ngrx/store';
import { createSelector } from "@reduxjs/toolkit";
import * as fromPos from "app/reducers/pos.reducer";
import { PosOrganizationModel } from "app/reducers/pos.reducer";
import { selectAllOrganizations } from "app/state-management/states/organizations.selector";
import { Organization } from "app/models/organization.model";

export interface PosOrganizationListItem extends Organization {
  gecoPosOrganization: PosOrganizationModel | undefined;
}

const selectSelf = createFeatureSelector<fromPos.PosState>(fromPos.posFeatureKey);

export const selectPosEntities = createSelector(
  selectSelf,
  fromPos.selectPosEntities
);

export const selectPosOrganizationById = (organizationId: string) => createSelector(
  selectSelf,
  state => {
    return state.entities[organizationId];
  }
);