<div *ngIf="currentOrder; else elseBlock" class="detail-container">
  <ng-container *ngIf="!currentOrder.isLoadError">
    <div class="detail-header">
      <div class="detail-header-title">{{id}}</div>
      <a [routerLink]="['..','..','new', currentOrder.id]" class="operator-mode" color="primary" mat-flat-button>Go to
        Operator Mode</a>
    </div>
    <div class="details">
      <mat-tab-group color="accent">
        <mat-tab [label]="'ORDERS.SUMMARY' | translate">

          <div class="summary">

            <div *ngIf="currentOrder.details">
              <h3 class="details-header" mat-header>{{ 'ORDERS.ORDERED' | translate }}</h3>
              <div *ngFor="let item of (currentOrder.details.items); let i = index"
                   style="display: flex; justify-content: flex-start; align-items: stretch;">
                <div class="product-number">{{(+i) + 1}}.</div>
                <div style="flex-grow: 1;">
                  <div class="product">
                    <div class="product-name bold" mat-line>{{item.name | join:item.sizeName}}</div>
                    <div class="product-details" mat-line>
                      <span class="quantity">{{item.quantity}}&nbsp;</span>
                      <mat-icon class="clear">clear</mat-icon>
                      <span class="price">&nbsp;{{item.price | number:'.2'}}</span>
                    </div>
                  </div>

                  <ng-container *ngFor="let group of item.groups">
                    <div class="product">
                      <div class="product-name" mat-line>{{group.name}}</div>
                      <div class="product-details" mat-line></div>
                    </div>
                  </ng-container>

                  <ng-container *ngFor="let mod of item.modifierGroups">
                    <div *ngFor="let modifier of mod.modifiers" class="product">
                      <div class="product-name" mat-line>{{modifier.name}}</div>
                      <div *ngIf="modifier.price" class="product-details" mat-line>
                        <span class="quantity">{{modifier?.quantity}}&nbsp;</span>
                        <mat-icon *ngIf="modifier.quantity && modifier.price" class="clear">clear</mat-icon>
                        <span class="price">&nbsp;{{modifier?.price | number:'.2'}}</span>
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>

              <div *ngIf="currentOrder.details.deliveryCharge">
                <div class="product-number">&nbsp;</div>
                <div style="flex-grow: 1;">
                  <div class="product">
                    <div class="product-name bold" mat-line>{{'ORDERS.DELIVERY_CHARGE' | translate}}</div>
                    <div class="product-details" mat-line>
                      <span class="price">&nbsp;{{currentOrder.details.deliveryCharge | number:'.2'}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="currentOrder.details.discounts && currentOrder.details.discounts.length">
                <h4 class="details-header" mat-header>{{ 'ORDERS.DISCOUNTS' | translate }}</h4>
                <div *ngFor="let item of (currentOrder.details.discounts); let i = index"
                     style="display: flex; justify-content: flex-start; align-items: stretch;">
                  <div style="flex-grow: 1;">
                    <div class="product">
                      <div class="product-name bold"
                           mat-line>{{item.name || item.type || ('ORDERS.DISCOUNTS' | translate)}}</div>
                      <div *ngIf="item.discountValue" class="product-details" mat-line>
                        <span class="price">&nbsp;{{item.discountValue | number:'.2'}} {{currentOrderOrganization?.currency}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div *ngIf="currentOrder.details?.promoCode" style="flex-grow: 1;">
                  <div class="product">
                      <div mat-line class="product-name bold">{{'ORDERS.PROMO_CODE' | translate}}</div>
                      <div mat-line class="product-details">
                          <span class="total-cost">{{currentOrder.details?.promoCode}}</span>
                      </div>
                  </div>
              </div>
              <div class="total">
                {{ 'ORDERS.TOTAL' | translate }}
                <span class="total-cost">{{currentOrder.details.sum | number:'.2'}} {{currentOrderOrganization?.currency}}</span>
              </div>
            </div>

            <div>
              <app-order-delivering></app-order-delivering>
              <app-order-details [orderOrganization]="currentOrderOrganization"></app-order-details>
            </div>

            <div>
              <app-order-status-history [statusHistory]="currentOrder?.statusHistory"></app-order-status-history>
            </div>
          </div>
        </mat-tab>

        <mat-tab *ngIf="roleMembership.isSupport" [label]="'ORDERS.SYSTEM_LOGS' | translate">
          <app-order-system-logs [currentOrder]="currentOrder"></app-order-system-logs>
        </mat-tab>

        <mat-tab *ngIf="roleMembership.isSupport" [label]="'ORDERS.MORE' | translate">{{ 'ORDERS.MORE' | translate }}</mat-tab>

      </mat-tab-group>

    </div>
  </ng-container>
</div>
<ng-template #elseBlock>
  <div class="details">
    Order not found
  </div>
</ng-template>