import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeZone } from 'app/models/organization.model';
import { time } from 'console';

@Injectable({
    providedIn: 'root'
  })
  export class TimeZoneService {
    timeZones: TimeZone[];

    constructor(
      private translate: TranslateService
    ){}

    public getTimeZones(): TimeZone[] {
      return [
        {
          value: -660,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-11'),
          label: 'UTC-11',
          default: false
        },
        {
          value: -600,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-10'),
          label: 'UTC-10',
          default: false
        },
        {
          value: -570,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-9:30'),
          label: 'UTC-9:30',
          default: false
        },
        {
          value: -540,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-9'),
          label: 'UTC-9',
          default: false
        },
        {
          value: -480,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-8'),
          label: 'UTC-8',
          default: false
        },
        {
          value: -420,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-7'),
          label: 'UTC-7',
          default: false
        },
        {
          value: -360,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-6'),
          label: 'UTC-6',
          default: false
        },
        {
          value: -300,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-5'),
          label: 'UTC-5',
          default: false
        },
        {
          value: -240,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-4'),
          label: 'UTC-4',
          default: false
        },
        {
          value: -210,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-3:30'),
          label: 'UTC-3:30',
          default: false
        },
        {
          value: -180,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-3'),
          label: 'UTC-3',
          default: false
        },
        {
          value: -60,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC-1'),
          label: 'UTC-1',
          default: false
        },
        {
          value: 0,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+0'),
          label: 'UTC+0',
          default: true
        },
        {
          value: 60,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+1'),
          label: 'UTC+1',
          default: false
        },
        {
          value: 120,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+2'),
          label: 'UTC+2',
          default: false,
          msk: 'МСК-1'
        },
        {
          value: 180,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+3'),
          label: 'UTC+3',
          default: false,
          msk: 'МСК+0'
        },
        {
          value: 210,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+3:30'),
          label: 'UTC+3:30',
          default: false
        },
        {
          value: 240,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+4'),
          label: 'UTC+4',
          default: false,
          msk: 'МСК+1'
        },
        {
          value: 270,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+4:30'),
          label: 'UTC+4:30',
          default: false
        },
        {
          value: 300,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+5'),
          label: 'UTC+5',
          default: false,
          msk: 'МСК+2'
        },
        {
          value: 330,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+5:30'),
          label: 'UTC+5:30',
          default: false
        },
        {
          value: 345,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+5:45'),
          label: 'UTC+5:45',
          default: false
        },
        {
          value: 360,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+6'),
          label: 'UTC+6',
          default: false,
          msk: 'МСК+3'
        },
        {
          value: 390,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+6:30'),
          label: 'UTC+6:30',
          default: false
        },
        {
          value: 420,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+7'),
          label: 'UTC+7',
          default: false,
          msk: 'МСК+4'
        },
        {
          value: 480,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+8'),
          label: 'UTC+8',
          default: false,
          msk: 'МСК+5'
        },
        {
          value: 525,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+8:45'),
          label: 'UTC+8:45',
          default: false
        },
        {
          value: 540,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+9'),
          label: 'UTC+9',
          default: false,
          msk: 'МСК+6'
        },
        {
          value: 570,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+9:30'),
          label: 'UTC+9:30',
          default: false
        },
        {
          value: 600,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+10'),
          label: 'UTC+10',
          default: false,
          msk: 'МСК+7'
        },
        {
          value: 630,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+10:30'),
          label: 'UTC+10:30',
          default: false
        },
        {
          value: 660,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+11'),
          label: 'UTC+11',
          default: false,
          msk: 'МСК+8'
        },
        {
          value: 720,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+12'),
          label: 'UTC+12',
          default: false,
          msk: 'МСК+9'
        },
        {
          value: 765,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+12:45'),
          label: 'UTC+12:45',
          default: false
        },
        {
          value: 780,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+13'),
          label: 'UTC+13',
          default: false
        },
        {
          value: 840,
          countries: this.translate.instant('ORGANIZATION.TIMEZONES.UTC+14'),
          label: 'UTC+14',
          default: false
        },
      ];
    }

    public getTimeZoneLabel(value: number | null): string {
      if (value) {
        const timeZone = this.getTimeZones().find(tz => tz.value === value);
        return timeZone ? `${timeZone.label} ${this.translate.currentLang === 'ru' && timeZone.msk ? '(' + timeZone.msk + ')' : ''}` : '';
      } else {
        return '';
      }
    }
  }
